<template>
    <div class="page-wrapper">
        <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
            <!--breadcrumb-->
            <!-- <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Dashboard</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Reporting</li>
                        </ol>
                    </nav>
                </div>
            </div> -->
            <!--end breadcrumb-->
            <h6 class="mb-0 text-uppercase">Reporting</h6>
            <br>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4 reporting-card">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Custom Employee Attendance Report</h5>
                            <p class="card-text">Download Single Employee's attendance report based on employee info and date.</p> <a href="javascript:;" class="btn btn-danger"
                                data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Generate Report</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Today's Employees Attendance Report</h5>
                            <p class="card-text">Download Today's attendance report of all employees.</p> <a href="javascript:;" @click="generateTodayAttendance()" class="btn btn-danger">Generate
                                Report</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Today's Late Attendance Report</h5>
                            <p class="card-text">Download Today's Late attendance report of all employees.</p> <a href="javascript:;" @click="generateLateAttendance()" class="btn btn-danger">Generate
                                Report</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Today's Early Attendance Report</h5>
                            <p class="card-text">Download Early attendance report of all employees.</p> <a href="javascript:;" @click="generateEarlyAttendance()" class="btn btn-danger">Generate
                                Report</a>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Extra Worktime Report</h5>
                            <p class="card-text">Download Extra Worktime report of all employees.</p> <a href="javascript:;" @click="generateOvertime()" class="btn btn-danger">Generate
                                Report</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleLargeModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title">Employee Creation</h5> -->
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card border-top border-0 border-4 border-primary">
                            <div class="card-body p-5">
                                <div class="card-title d-flex align-items-center">
                                    <div>
                                        <i class="bx bxs-user me-1 font-22 text-primary"></i>
                                    </div>
                                    <h5 class="mb-0 text-primary">Employee Attendance</h5>
                                </div>
                                <hr />
                                <form class="row g-3">
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">Employee Code<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <select v-model="employee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_code }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">Employee Name<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <select v-model="employee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail" class="form-label">Start Date<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <input type="date" v-model="start_date" class="form-control"
                                            id="inputEmail" />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputEmail" class="form-label">End Date<span
                                                style="color: red; font-size: 18px">*</span></label>
                                        <input type="date" v-model="end_date" class="form-control"
                                            id="inputEmail" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        
                        <button type="button" v-on:click="generateEmployeeAttendance()" data-bs-dismiss="modal" class="btn btn-danger">
                            Generate
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style scoped>
.reporting-card,.card p{
    min-height: 62px;
}
</style>
<script src="../js/reporting.js"></script>