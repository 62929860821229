import LeaveManagement from '../../Group Component/leave_management/template/LeaveManagement.vue';
export default {
    components: {
        LeaveManagement
    },
    data() {
        return {
            permissions: localStorage.getItem('permission_pages'),
        }
    },

    async created() {
        this.backend_url = this.$backend;
        if(this.permissions)
            await this.getCurrentUserInfo();
        
    },
    methods: {
        logout: function() {
            localStorage.clear();
            this.$router.push("/login")
        }
    }
}