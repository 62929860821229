import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            menu_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            roles: [],
            role: {},
            employee: {},
            employees: [],
            user_role_details: {},
        }
    },
    mounted() {
        this.setURL = this.$config.leave.userRolesAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let user_roles_url = this.$api + this.$config.role.userRolesAPIEndPoint + params;
        this.getDataList(user_roles_url);
        let role_url = this.$api + this.$config.role.roleAPIEndPoint;
        let configRole = {
            method: "GET",
            url: role_url,
            headers: authHeader()
        }
        this.$axios(configRole).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.roles.push({
                        id: response.data[key].id,
                        name: response.data[key].title,
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let employee_url = this.$api + this.$config.employees.employeeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=";
        let configEmp = {
            method: "GET",
            url: employee_url,
            headers: authHeader()
        }
        this.$axios(configEmp).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.employees.push({
                        id: response.data.data[key].id,
                        emp_code: response.data.data[key].emp_code,
                        emp_name: response.data.data[key].first_name + " " + response.data.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let user_roles_url = this.$api + this.$config.role.userRolesAPIEndPoint + params;
            await this.getDataList(user_roles_url);
        },
        deleteInfo: function(id) {
            this.menu_id = id;
        },
        deleteUserRoles: async function() {
            this.isLoading = true
            let user_roles_details_url = this.$api + this.$config.role.userRolesDetails + this.menu_id;
            await this.$axios.delete(user_roles_details_url, {

            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let user_roles_url = this.$api + this.$config.role.userRolesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(user_roles_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateUserRoles: async function() {
            this.isLoading = true
            let user_role_details_url = this.$api + this.$config.role.userRolesDetails + this.menu_id;
            let role_data = {
                employee_id: this.user_role_details.employee.id,
                employee_name: this.user_role_details.employee.emp_name,
                employee_code: this.user_role_details.employee.emp_code,
                role_id: this.user_role_details.role.id,
                role_name: this.user_role_details.role.name
            }
            let config = {
                method: "PUT",
                data: role_data,
                url: user_role_details_url
            }
           await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let user_roles_url = this.$api + this.$config.role.userRolesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(user_roles_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        editForm: function(id) {
            this.menu_id = id;
            let user_roles_details_url = this.$api + this.$config.role.userRolesDetails + this.menu_id;
            this.$axios.get(user_roles_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.user_role_details.role = {
                        id: response.data.role_id,
                        name: response.data.role_name
                    }
                    this.user_role_details.employee = {
                        id: response.data.employee_id,
                        emp_code: response.data.employee_code,
                        emp_name: response.data.employee_name
                    }

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createUserRoles: async function() {
            //console.log("User Role Creation Method");
                    this.isLoading = true
            let url = this.$api + this.$config.role.userRolesAPIEndPoint
            let role_data = {
                employee_id: this.employee.id,
                employee_name: this.employee.emp_name,
                employee_code: this.employee.emp_code,
                role_id: this.role.id,
                role_name: this.role.name
            }

            let config = {
                method: "POST",
                data: role_data,
                url: url
            }
            await this.postDataToBackend(config);
            this.isLoading = false
            let user_roles_url = this.$api + this.$config.role.userRolesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
           await this.getDataList(user_roles_url);
        },

    }
}