import Loading from "vue-loading-overlay";
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            old_password: '',
            password: '',
            password_confirmation: '',
        }
    },
   async created() {
        await this.getCurrentUserInfo();
    },
    methods: {
        formValidation(){
            if(!this.old_password){
                this.$toast.error("Please enter your old password!");
                return false
            } else if(!this.password){
                this.$toast.error("Please enter your new password!");
                return false
            } else if(!this.password_confirmation){
                this.$toast.error("Please enter your confirmation password!");
                return false
            } else if(this.password != this.password_confirmation){
                this.$toast.error("Password and confirmation password does not match!");
                return false
            }
            console.log("Please enter your confirmation password")
            return true;
        },
        async updatePassword(){
            if(this.formValidation()){
                this.isLoading = true;
                let data = {
                    emp_code: this.userInfo.emp_code,
                    old_password: this.old_password,
                    new_password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$api + this.$config.auth.changePassword+'/',
                    data: data
                };

                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$toast.success(response.data.message);
                    } 
                    this.isLoading = false
                }).catch(error => {
                    console.log(error.response)
                    this.isLoading = false
                    this.$toast.error(error.response.data.message);
                });
            }
        }
    }
}