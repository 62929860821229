import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            bonus_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            salary_grades: [],
            search: '',
            id: null,
            bonus_code: '',
            festival_bonus: '',
            profit_bonus: '',
            other_bonus: '',
            salary_grade: {},
            bonus_details: {
                bonus_code: '',
                festival_bonus: '',
                profit_bonus: '',
                other_bonus: '',
                salary_grade: {
                    id: null,
                    salary_ode: ''
                }
            }


        }
    },
    mounted() {
        // this.setURL = this.$config.payroll.BonusAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let bonus_url = this.$api + this.$config.payroll.bonusAPIEndPoint + params;
        this.getDataList(bonus_url);
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        this.$axios.get(salary_grade_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.salary_grades.push({
                        id: response.data.data[key].id,
                        salary_code: response.data.data[key].salary_code,
                    });
                }
            } else {
                //console.log("In else");
                this.isLoading = false

            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let bonus_url = this.$api + this.$config.payroll.bonusAPIEndPoint + params;
            await this.getDataList(bonus_url);
        },
        deleteInfo: function(id) {
            this.bonus_id = id;
        },
        deleteBonus: async function() {
            let bonus_details_url = this.$api + this.$config.payroll.bonusDetails + this.bonus_id;
            await this.$axios.delete(bonus_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let bonus_url = this.$api + this.$config.payroll.bonusAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(bonus_url);
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateBonus: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.BonusDetails);
            let bonus_details_url = this.$api + this.$config.payroll.bonusDetails + this.bonus_id;
            let data = {
                bonus_code: this.bonus_details.bonus_code,
                festival_bonus: this.bonus_details.festival_bonus,
                profit_bonus: this.bonus_details.profit_bonus,
                other_bonus: this.bonus_details.other_bonus,
                salary_grade_id: this.bonus_details.salary_grade.id,
                salary_code: this.bonus_details.salary_grade.salary_code
            }
            let config = {
                method: "PUT",
                url: bonus_details_url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let bonus_url = this.$api + this.$config.payroll.bonusAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(bonus_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        editForm: async function(id) {
            this.bonus_id = id;
            let bonus_details_url = this.$api + this.$config.payroll.bonusDetails + this.bonus_id;
            //console.log(bonus_details_url);
           await this.$axios.get(bonus_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.bonus_details.bonus_code = response.data.bonus_code,
                        this.bonus_details.profit_bonus = response.data.profit_bonus,
                        this.bonus_details.festival_bonus = response.data.festival_bonus,
                        this.bonus_details.other_bonus = response.data.other_bonus,
                        this.bonus_details.salary_grade = {
                            id: response.data.salary_grade_id,
                            salary_code: response.data.salary_code
                        }

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createBonus: async function() {
            let url = this.$api + this.$config.payroll.bonusAPIEndPoint
            let data = {
                bonus_code: this.bonus_code,
                festival_bonus: this.festival_bonus,
                profit_bonus: this.profit_bonus,
                other_bonus: this.other_bonus,
                salary_grade_id: this.salary_grade.id,
                salary_code: this.salary_grade.salary_code
            }
            let config = {
                method: "POST",
                url: url,
                data: data
            }

            await this.postDataToBackend(config);
            this.bonus_code = '';
            this.festival_bonus = '';
            this.profit_bonus = '';
            this.other_bonus = '';
            this.salary_grade_id = '';
            this.salary_grade = '';
            let bonus_url = this.$api + this.$config.payroll.bonusAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(bonus_url);
        },

    }
}