<template>
    <div class="page-wrapper">
			<div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Salary</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Payslip</li>
							</ol>
						</nav>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Create Role</button> -->
							<!-- <button type="button" class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
								<a class="dropdown-item" href="javascript:;">Another action</a>
								<a class="dropdown-item" href="javascript:;">Something else here</a>
								<div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
							</div> -->
						</div>
					</div>
				</div>
				<!--end breadcrumb-->
				<h6 class="mb-0 text-uppercase">Generate Payslip of an Employee</h6>
				<hr/>
				<div class="card">
					<div class="card-body">
						<div class="row mb-3">
						<form class="row g-3">
									<div class="col-md-4 ms-3">
										<label for="role" class="form-label">Select Employee<span style="color:red;font-size:18px">*</span></label>
										<select v-model="employee" id="role" class="form-select single-select">
											<option v-for="(option, key) in employees" :key="key" :value="option">{{option.emp_code}}</option>
										</select>
									</div>
                                    <div class="col-md-4 ms-3">
										<label for="role" class="form-label">Select Employee<span style="color:red;font-size:18px">*</span></label>
										<select v-model="employee" id="role" class="form-select single-select">
											<option v-for="(option, key) in employees" :key="key" :value="option">{{option.emp_name}}</option>
										</select>
									</div>
                                    <div class="col-md-4 ms-3">
										<label for="role" class="form-label">Select Month<span style="color:red;font-size:18px">*</span></label>
										<select v-model="month" id="role" class="form-select single-select">
											<option v-for="(option, key) in months" :key="key" :value="option">{{option.name}}</option>
										</select>
									</div>
                                    <div class="col-md-4 ms-3">
										<label for="yearpicker" class="form-label">Select Year<span style="color:red;font-size:18px">*</span></label>
										<!-- <input
                                            type="text"
                                            v-model="year"
                                            class="form-control datepicker"
                                            id="inputEmployeeCode"
                                            /> -->
                                            <!-- <input
                                            type="month"
                                            class="form-control"
                                            /> -->
											<month-picker @change="showDate" :year-only="true"></month-picker>
									</div>
                                
									
								</form>
								</div>
								<div class="col-md-10 ms-3 mt-5">
									<button type="button" v-on:click="generatePayslip()" class="btn btn-primary px-2">Generate</button>
								</div>
							
						<!-- <div class="row" v-if="allDataList">
                                <pagination v-if="allDataList.last_page > 1"
                                                    :pagination="allDataList"
                                                    :offset="5"
                                                    @paginate="getEmployeeData(setURL + '?page=' + allDataList.current_page + '&limit=' + limit + '&search=' + search)"
                                ></pagination>
                            </div> -->
						<!-- <nav aria-label="Page navigation example" class="float-end mt-2">
							<ul class="pagination round-pagination right">
								<li class="page-item"><a class="page-link" href="javascript:;">Previous</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;javascript:;">1</a>
								</li>
								<li class="page-item active"><a class="page-link" href="javascript:;">2</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">3</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">Next</a>
								</li>
							</ul>
						</nav> -->
					</div>
				</div>
			</div>
            <!-- Modal -->
            <div class="modal fade" id="exampleLargeModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- <h5 class="modal-title">Employee Creation</h5> -->
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-primary">
							<div class="card-body p-5">
								<div class="card-title d-flex align-items-center">
									<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
									</div>
									<h5 class="mb-0 text-primary">Role Creation</h5>
								</div>
								<hr>
								<form class="row g-3">
									<div class="col-md-6">
										<label for="inputroleName" class="form-label">Role Name</label>
										<!-- <input type="text" v-model="role_name" class="form-control" id="inputroleName" placeholder="Enter Role Name"> -->
									</div>
									
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="createRole()" data-bs-dismiss="modal" class="btn btn-primary">Create Role</button>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Update Modal -->
			<div class="modal fade" id="exampleLargeModal1" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- <h5 class="modal-title">Employee Creation</h5> -->
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-warning">
							<div class="card-body p-5">
								<div class="card-title d-flex align-items-center">
									<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
									</div>
									<h5 class="mb-0 text-primary">Update Role</h5>
								</div>
								<hr>
								<form class="row g-3">
									<div class="col-md-6">
										<label for="inputroleName" class="form-label">Role Name</label>
										<!-- <input type="text" v-model="role_details.role_name" class="form-control" id="inputroleName" placeholder=""> -->
									</div>
									
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="updateRole()" data-bs-dismiss="modal" class="btn btn-primary">Update Role</button>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Delete Modal -->
			<div class="modal fade" id="exampleVerticallycenteredModal" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header bg-primary">
							<h5 class="modal-title">Are You Sure?</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" v-on:click="deleteRole()" data-bs-dismiss="modal" class="btn btn-danger">Delete</button>
						</div>
					</div>
				</div>
			</div>
</div>
        
</template>
<script src="../js/payslip.js"></script>