<template>
	<div class="wrapper">
		<!-- Start Sidebar -->
		<div class="sidebar-wrapper" data-simplebar="true">
			<div class="sidebar-header">
				<div>
					<img src="../../../../public/core/assets/images/hrconnect.jpg" class="logo-icon" alt="logo icon">
				</div>
				<div>
					<h4 class="logo-text">HrConnect</h4>
				</div>
				<div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i></div>
			</div>
			<ul class="metismenu" id="menu">
				<li v-if="permissions?.includes('Dashboard')" class="mm-active">
					<router-link to="/dashboard">
						<div class="parent-icon"><i class='bx bx-home-circle'></i></div>
						<div class="menu-title">Dashboard</div>
					</router-link>
				</li>
				<li v-if="permissions?.includes('Employee Info')">
					<router-link to="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-users'></i></div>
						<div class="menu-title">Employee Info</div>
					</router-link>
					<ul>
						<li v-if="permissions?.includes('Employees')">
							<router-link to="/employee"><i class="bx bx-right-arrow-alt"></i>Employees</router-link>
						</li>
						<li v-if="permissions?.includes('Bulk Employees')">
							<router-link to="/bulk-employee"><i class="bx bx-right-arrow-alt"></i>Bulk Employee List</router-link>
						</li>
						<li v-if="permissions?.includes('Stuck Off Employees')">
							<router-link to="/stuck-off-employee"><i class="bx bx-right-arrow-alt"></i>Stuck Off Employees</router-link>
						</li>
					</ul>
				</li>

				<li v-if="permissions?.includes('Leave Management')">
					<router-link to="#" class="has-arrow">
						<div class="parent-icon"><i class='fadeIn animated bx bx-detail'></i></div>
						<div class="menu-title">Leave Management</div>
					</router-link>
					<ul>
						<li v-if="permissions?.includes('Leave Application')">
							<router-link to="/leave-application"><i class="bx bx-right-arrow-alt"></i>Leave Application
							</router-link>
						</li>
						<li v-if="permissions?.includes('Leave Approval Request')">
							<router-link to="/leave-request"><i class="bx bx-right-arrow-alt"></i>Leave Approval Request
							</router-link>
						</li>
						<li v-if="permissions?.includes('Leave Pending List')">
							<router-link to="/leave-pending-list"><i class="bx bx-right-arrow-alt"></i>Leave All Pending List
							</router-link>
						</li>
					</ul>
				</li>
				<li v-if="permissions?.includes('Payroll Management')">
					<router-link to="#" class="has-arrow">
						<div class="parent-icon"><i class='lni lni-coin'></i></div>
						<div class="menu-title">Payroll Management</div>
					</router-link>
					<ul>
						<li v-if="permissions?.includes('Salary')">
							<router-link to="/salary"><i class="bx bx-right-arrow-alt"></i>Salary</router-link>
						</li>
						<li v-if="permissions?.includes('Salary Log')">
							<router-link to="/salary-log"><i class="bx bx-right-arrow-alt"></i>Salary Log</router-link>
						</li>
						<li v-if="permissions?.includes('Account Details')">
							<router-link to="/account-details"><i class="bx bx-right-arrow-alt"></i>Account Details
							</router-link>
						</li>
						<li v-if="permissions?.includes('Payslip')">
							<router-link to="/payslip"><i class="bx bx-right-arrow-alt"></i>Payslip</router-link>
						</li>
						<li v-if="permissions?.includes('Salary Grade')">
							<router-link to="/salary-grade"><i class="bx bx-right-arrow-alt"></i>Salary Grade
							</router-link>
						</li>
						<li v-if="permissions?.includes('Payroll Info')">
							<router-link to="/pay-roll-info"><i class="bx bx-right-arrow-alt"></i>Payroll Info
							</router-link>
						</li>
						<li v-if="permissions?.includes('Bonus')">
							<router-link to="/bonus"><i class="bx bx-right-arrow-alt"></i>Bonus</router-link>
						</li>
						<li v-if="permissions?.includes('Income Tax')">
							<router-link to="/income-tax"><i class="bx bx-right-arrow-alt"></i>Income Tax</router-link>
						</li>
						<!-- <li><router-link to="/pay-rate"><i class="bx bx-right-arrow-alt"></i>Pay Rate</router-link></li> -->
						<li v-if="permissions?.includes('Miscellaneous Cost')">
							<router-link to="/miscellaneous-cost"><i class="bx bx-right-arrow-alt"></i>Miscellaneous Cost</router-link>
						</li>
						<!-- <li><router-link to="/employee-work-log"><i class="bx bx-right-arrow-alt"></i>Employee Work Log</router-link></li> -->
					</ul>
				</li>
				<li v-if="permissions?.includes('Configurations')">
					<router-link to="#" class="has-arrow">
						<div class="parent-icon"><i class='bx bx-cog'></i></div>
						<div class="menu-title">Configurations</div>
					</router-link>
					<!-- <ul>
                        <li><a href="/settings"><i class="bx bx-right-arrow-alt"></i>Settings</a></li>
                    </ul> -->

					<ul>
						<li v-if="permissions?.includes('Employee Settings')">
							<router-link to="#" class="has-arrow">
								<div class="parent-icon"><i class='fadeIn animated bx bx-user'></i></div>
								<div class="menu-title">Employee Settings</div>
							</router-link>
							<ul>
								<li v-if="permissions?.includes('Company')">
									<!-- <a href="/company"><i class="bx bx-right-arrow-alt"></i>Company</a> -->
									<router-link to="/company"><i class="bx bx-right-arrow-alt"></i>Company
									</router-link>
								</li>
								<li v-if="permissions?.includes('Branch')">
									<!-- <a href="/branch"><i class="bx bx-right-arrow-alt"></i>Branch</a> -->
									<router-link to="/branch"><i class="bx bx-right-arrow-alt"></i>Branch</router-link>
								</li>
								<li v-if="permissions?.includes('Department')">
									<router-link to="/department"><i class="bx bx-right-arrow-alt"></i>Department
									</router-link>
								</li>
								<li v-if="permissions?.includes('Designation')">
									<router-link to="/designation"><i class="bx bx-right-arrow-alt"></i>Designation
									</router-link>
								</li>
								<li v-if="permissions?.includes('Section')">
									<router-link to="/section"><i class="bx bx-right-arrow-alt"></i>Section
									</router-link>
								</li>
								<li v-if="permissions?.includes('Certificate')">
									<router-link to="/certificate"><i class="bx bx-right-arrow-alt"></i>Certificate
									</router-link>
								</li>
								<li v-if="permissions?.includes('Verification')">
									<router-link to="/verification"><i class="bx bx-right-arrow-alt"></i>Verification
									</router-link>
								</li>
								<li v-if="permissions?.includes('Country')">
									<router-link to="/country"><i class="bx bx-right-arrow-alt"></i>Country
									</router-link>
								</li>
								<li v-if="permissions?.includes('City')">
									<router-link to="/city"><i class="bx bx-right-arrow-alt"></i>City</router-link>
								</li>

							</ul>
						</li>
					</ul>
					<ul>
						<li v-if="permissions?.includes('Leave Management Settings')">
							<router-link to="#" class="has-arrow">
								<div class="parent-icon"><i class='fadeIn animated bx bx-paste'></i></div>
								<div class="menu-title">Leave Management Settings</div>
							</router-link>
							<ul>
								<li v-if="permissions?.includes('Leave Policy')"><router-link to="/leave-policy"><i class="bx bx-right-arrow-alt"></i>Leave Policy</router-link></li>
								<li v-if="permissions?.includes('Employee Leave Balance')"><router-link to="/employee-leave-balance"><i class="bx bx-right-arrow-alt"></i>Employee Leave Balance</router-link></li>
								<li v-if="permissions?.includes('Leave Mail Format')"><router-link to="/leave-mail-format"><i class="bx bx-right-arrow-alt"></i>Leave Mail Format</router-link></li>
								<li v-if="permissions?.includes('Leave Approval Chain Steps')"><router-link to="/leave-approval-steps"><i class="bx bx-right-arrow-alt"></i>Leave Approval Chain Steps</router-link></li>
								
							</ul>
						</li>
					</ul>
					<ul>
						<li v-if="permissions?.includes('Role Management Settings')">
							<router-link to="#" class="has-arrow">
								<div class="parent-icon"><i class='fadeIn animated bx bx-user-x'></i></div>
								<div class="menu-title">Role Management Settings</div>
							</router-link>
							<ul>
								<li v-if="permissions?.includes('Role')">
									<router-link to="/role"><i class="bx bx-right-arrow-alt"></i>Role</router-link>
								</li>
								<li v-if="permissions?.includes('Menu Pages')">
									<router-link to="/menu-pages"><i class="bx bx-right-arrow-alt"></i>Menu Pages
									</router-link>
								</li>
								<li v-if="permissions?.includes('User Role')">
									<router-link to="/user-role"><i class="bx bx-right-arrow-alt"></i>User Role
									</router-link>
								</li>
								<li v-if="permissions?.includes('Role Pages')">
									<router-link to="/role-pages"><i class="bx bx-right-arrow-alt"></i>Role Pages
									</router-link>
								</li>
							</ul>
						</li>
					</ul>

					<ul>
						<li v-if="permissions?.includes('Payroll Management Settings')">
							<router-link to="#" class="has-arrow">
								<div class="parent-icon"><i class='fadeIn animated bx bx-user-x'></i></div>
								<div class="menu-title">Payroll Management Settings</div>
							</router-link>
							<ul>
								<li v-if="permissions?.includes('Salary')">
									<router-link to="/salary"><i class="bx bx-right-arrow-alt"></i>Salary</router-link>
								</li>
								<li v-if="permissions?.includes('Salary Log')">
									<router-link to="/salary-log"><i class="bx bx-right-arrow-alt"></i>Salary Log
									</router-link>
								</li>
								<li v-if="permissions?.includes('Account Details')">
									<router-link to="/account-details"><i class="bx bx-right-arrow-alt"></i>Account Details</router-link>
								</li>
								<li v-if="permissions?.includes('Payslip')">
									<router-link to="/payslip"><i class="bx bx-right-arrow-alt"></i>Payslip
									</router-link>
								</li>
								<li v-if="permissions?.includes('Salary Grade')">
									<router-link to="/salary-grade"><i class="bx bx-right-arrow-alt"></i>Salary Grade
									</router-link>
								</li>
								<li v-if="permissions?.includes('Payroll Info')">
									<router-link to="/pay-roll-info"><i class="bx bx-right-arrow-alt"></i>Payroll Info
									</router-link>
								</li>
								<li v-if="permissions?.includes('Bonus')">
									<router-link to="/bonus"><i class="bx bx-right-arrow-alt"></i>Bonus</router-link>
								</li>
								<li v-if="permissions?.includes('Income Tax')">
									<router-link to="/income-tax"><i class="bx bx-right-arrow-alt"></i>Income Tax
									</router-link>
								</li>
								<!-- <li><router-link to="/pay-rate"><i class="bx bx-right-arrow-alt"></i>Pay Rate</router-link></li> -->
								<li v-if="permissions?.includes('Miscellaneous Cost')">
									<router-link to="/miscellaneous-cost"><i
											class="bx bx-right-arrow-alt"></i>Miscellaneous Cost</router-link>
								</li>
								<!-- <li><router-link to="/employee-work-log"><i class="bx bx-right-arrow-alt"></i>Employee Work Log</router-link></li> -->
							</ul>

						</li>
					</ul>
					<ul>
						<li v-if="permissions?.includes('Attendance Management Settings')">
							<router-link to="#" class="has-arrow">
								<div class="parent-icon"><i class='fadeIn animated bx bx-paste'></i></div>
								<div class="menu-title">Attendance Management Settings</div>
							</router-link>
							<ul>
								<li v-if="permissions?.includes('Shift Policy')">
									<router-link to="/shift-policy"><i class="bx bx-right-arrow-alt"></i>Shift Policy</router-link>
								</li>
								<li v-if="permissions?.includes('Employee Shift Policy')">
									<router-link to="/employee-shift-policy"><i
											class="bx bx-right-arrow-alt"></i>Employee Shift Policy
									</router-link>
								</li>
								<li v-if="permissions?.includes('Employee Attendance')">
									<router-link to="/employee-attendance-log"><i
											class="bx bx-right-arrow-alt"></i>Employee Attendance
									</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</li>
				<li v-if="permissions?.includes('Reports')">
					<router-link to="/reporting"><i class="fadeIn animated bx bx-paste"></i>Reports</router-link>
				</li>
				<li v-if="permissions?.includes('Company Calender')">
					<router-link to="/company-calender"><i class="fadeIn animated bx bx-calendar-event"></i>Company Calender</router-link>
				</li>


			</ul>
		</div>
		<!-- End Sidebar -->
		<!-- Start Header -->
		<header>
			<div class="topbar d-flex align-items-center">
				<nav class="navbar navbar-expand">
					<div class="mobile-toggle-menu"><i class='bx bx-menu'></i></div>
					<!-- <div class="search-bar flex-grow-1">
						<div class="position-relative search-bar-box">
							<input type="text" class="form-control search-control" placeholder="Type to search..."> <span class="position-absolute top-50 search-show translate-middle-y"><i class='bx bx-search'></i></span>
							<span class="position-absolute top-50 search-close translate-middle-y"><i class='bx bx-x'></i></span>
						</div>
					</div> -->
					<div class="top-menu ms-auto">
						<ul class="navbar-nav align-items-center">
							<!-- <li class="nav-item mobile-search-icon">
								<a class="nav-link" href="#"><i class='bx bx-search'></i></a>
							</li> -->
							<!-- <li class="nav-item dropdown dropdown-large"> -->
							<!-- <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-bell'></i>
								</a> -->
							<div class="dropdown-menu dropdown-menu-end">

								<div class="header-notifications-list">

								</div>

							</div>
							<!-- </li> -->
							<li class="nav-item dropdown dropdown-large">
								<!-- <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">1</span>
									<i class='bx bx-comment'></i>
								</a> -->
								<div class="dropdown-menu dropdown-menu-end">
									<!-- <a href="#">
										<div class="msg-header">
											<p class="msg-header-title">Messages</p>
											<p class="msg-header-clear ms-auto">Marks all as read</p>
										</div>
									</a> -->
									<div class="header-message-list">
										<div class="d-flex align-items-center">
											<div class="user-online">
											</div>
											<div class="flex-grow-1">
											</div>
										</div>
										<!-- </a> -->
									</div>
									<!-- <a href="#">
										<div class="text-center msg-footer">View All Messages</div>
									</a> -->
								</div>
							</li>
						</ul>
					</div>

					<div class="user-box dropdown">
						<a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#"
							role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<img :src="backend_url + userInfo.image" class="user-img" v-if="userInfo.image">
							<img src="/user.png" class="user-img" v-else>
							<div class="user-info ps-3">
								<p class="user-name mb-0">{{userInfo?.first_name + " " + userInfo?.last_name}}</p>
								<p class="designattion mb-0">{{userInfo?.designation_name}}</p>
							</div>
						</a>
						<ul class="dropdown-menu dropdown-menu-end">
							<li>
								<router-link to="/user-info" class="dropdown-item"><i class="bx bx-user"></i>Profile</router-link>
							</li>
							<li>
								<router-link to="/change-password" class="dropdown-item"><i class="bx bx-key"></i>Change Password</router-link>
							</li>

							<li>
								<div class="dropdown-divider mb-0"></div>
							</li>
							<li><a href="#" class="dropdown-item" @click="logout"><i
										class='bx bx-log-out-circle'></i><span>Logout</span></a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
		<!-- End Header -->
		<!-- Start Footer -->
		<footer class="page-footer">
			<p class="mb-0">Copyright Divergent Technologies Ltd. © {{ new Date().getFullYear() }}. All right reserved.</p>
		</footer>
		<!-- End Footer -->
	</div>
</template>

<script src="../js/home.js"></script>