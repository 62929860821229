<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee Leave Policy
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create Leave Policy
            </button>
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee Leave Policy List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
          <div class="row mb-3 ms-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  @click="searchData"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Leave Title</th>
                  <!-- <th>Short Code</th> -->
                  <th>Total Days</th>
                  <!-- <th>Applicable For All</th> -->
                  <th>Available After Month</th>
                  <!-- <th>Weekend Holiday Count</th> -->
                  <th>Carry Forward</th>
                  <!-- <th>Maximum Carry Forward</th> -->
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.leave_title }}</td>
                  <!-- <td>{{li.short_code}}</td> -->
                  <td>{{ li.total_days }}</td>
                  <!-- <td>{{li.applicable_to_all? "Yes": "No"}}</td> -->
                  <td>{{ li.available_after_month }}</td>
                  <!-- <td>{{li.weekend_holiday_count? "Yes": "No"}}</td> -->
                  <td>{{ li.is_carry_forward ? "Yes" : "No" }}</td>
                  <!-- <td>{{li.max_carry_forward}}</td> -->
                  <td>{{ li.status ? "No" : "Yes" }}</td>

                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-success btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal3"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Employee Creation</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <div class="card-title d-flex align-items-center">
                  <div>
                    <i class="bx bxs-user me-1 font-22 text-primary"></i>
                  </div>
                  <h5 class="mb-0 text-primary">Leave Policy Creation</h5>
                </div>
                <hr />
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Leave Title<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="leave_title"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Leave Title"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputPhone" class="form-label"
                      >Short Code<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="short_code"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Short Code"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Total Days<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="total_days"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Total Days"
                    />
                  </div>
                  <div class="col-md-6 mt-5">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="applicable_to_all"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Applicable To All</label
                      >
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Available After Month</label
                    >
                    <input
                      type="text"
                      v-model="available_after_month"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Available After Month"
                    />
                  </div>
                  <div class="col-md-6 mt-5">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="weekend_holiday_count"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Weekend Holiday Count</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mt-5">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="is_carry_forward"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Carry Forward</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >Maximum Carry Forward</label
                    >
                    <input
                      type="text"
                      v-model="max_carry_forward"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Maximum Carry Forward"
                    />
                  </div>
                  <div class="col-md-6 mt-5">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="status"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Active/Inactive</label
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createLeavePolicy()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create Leave Policy
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Employee Creation</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <div class="card-title d-flex align-items-center">
                  <div>
                    <i class="bx bxs-user me-1 font-22 text-primary"></i>
                  </div>
                  <h5 class="mb-0 text-primary">Update Leave Policy</h5>
                </div>
                <hr />
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Leave Title</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.leave_title"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Leave Title"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputPhone" class="form-label"
                      >Short Code</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.short_code"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Short Code"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Total Days</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.total_days"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Total Days"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.applicable_to_all"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Applicable For All</label
                      >
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Available After Month</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.available_after_month"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Available After Month"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.weekend_holiday_count"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Weekend Holiday Count</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.is_carry_forward"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Carry Forward</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >Maximum Carry Forward</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.max_carry_forward"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Maximum Carry Forward"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.status"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Status</label
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateLeavePolicy()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update Leave Policy
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal3"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
                  <h5 class="mb-0 text-primary">Leave Policy Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Leave Title</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.leave_title"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Leave Title"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputPhone" class="form-label"
                      >Short Code</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.short_code"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Short Code"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Total Days</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.total_days"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Total Days"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.applicable_to_all"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Applicable For All</label
                      >
                    </div>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Available After Month</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.available_after_month"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Available After Month"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.weekend_holiday_count"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Weekend Holiday Count</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.is_carry_forward"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Carry Forward</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >Maximum Carry Forward</label
                    >
                    <input
                      type="text"
                      v-model="policy_details.max_carry_forward"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Maximum Carry Forward"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        v-model="policy_details.status"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                        >Status</label
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateLeavePolicy()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update Leave Policy
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteLeavePolicy()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/leave_policy.js"></script>