import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            verification_id: null,
            page: 1,
            limit: 10,
            total_pages: null,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            verification_name: '',
            employees: [],
            employee: {},
            selectedFiles: undefined,
            verification_details: {

            }
        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        this.setURL = this.$config.configurations.verificationAPIEndpoint;
        let verification_url = this.$api + this.$config.configurations.verificationAPIEndPoint + params;
        this.getVerificationList(verification_url);
        let employee_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        //console.log("Employee Url is: ", emp_url)
        let config = {
            method: "GET",
            url: employee_url,
            headers: authHeader()
        }
        this.$axios(config).then((response) => {
            if (response.status == 200) {
                // console.log("Data is", response.data);
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            // console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let verification_url = this.$api + this.$config.configurations.verificationAPIEndPoint + params;
            await this.getVerificationList(verification_url);
            
        },
        getVerificationList: async function(url) {
            let configVerification = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            await this.$axios(configVerification).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data.data)) {
                        this.total_pages = response.data.last_page;
                        this.dataList.push({
                            id: response.data.data[key].id,
                            emp_code: response.data.data[key].emp_id,
                            emp_name: response.data.data[key].emp_name,
                            file: response.data.data[key].file,
                            filename: response.data.data[key].file.split('/')[5]
                        });
                    }
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        downloadWithAxios(url, title) {
            this.$axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then(async (response) => {
                    await this.forceFileDownload(response, title)
                })
                .catch(() => console.log('Failed to download'))
        },
        getdownloadFile: async function(url) {
            //console.log("Url is: ", this.$backend + url)
            let new_url = this.$backend + url;
            let new_label = url.split('/')[5].split(".")[0];
            // let data_type = url.split('/')[5].split(".")[1];
            //console.log("data type: ", data_type);
            await this.$axios.get(new_url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        deleteInfo: function(id) {
            this.verification_id = id;
        },
        uploadFiles: function(event) {
            this.selectedFiles = event.target.files;
            //console.log("Selected Files: ", this.selectedFiles);
        },
        deleteVerification: async function() {
            let verification_details_url = this.$api + this.$config.configurations.verificationDetails + this.verification_id;
            await this.$axios.delete(verification_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let verification_url = this.$api + this.$config.configurations.verificationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getVerificationList(verification_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateVerification: async function() {
            //console.log("verification Details is: ", this.verification_details);
            let verification_details_url = this.$api + this.$config.configurations.verificationDetails + this.verification_id;
            const formData = new FormData();
            let siteConfig = {};
            //console.log("Employee Data: ", this.employee);
            formData.append("emp_id", this.verification_details.employee.emp_id);
            formData.append("emp_code", this.verification_details.employee.emp_code);
            formData.append("emp_name", this.verification_details.employee.emp_name);
            for (let i = 0; i < this.verification_details.selectedFiles.length; i++) {
                formData.append("file", this.selectedFiles[i]);
            }

            //console.log("URL is", verification_details_url);

            siteConfig = {
                method: "PUT",
                url: verification_details_url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.$axios(siteConfig).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let verification_url = this.$api + this.$config.configurations.verificationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getVerificationList(verification_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getVerificationData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
           await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.verification_id = id;
            let verification_details_url = this.$api + this.$config.configurations.verificationDetails + this.verification_id;
            //console.log(verification_details_url);
            await this.$axios.get(verification_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.verification_details.employee = {
                            emp_id: response.data.emp_id,
                            emp_code: response.data.emp_code,
                            emp_name: response.data.emp_name
                        },
                        this.verification_details.selectedFiles = response.data.file

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            //console.log("Employee verification: ", this.verification_details);

        },
        createVerification: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.verificationAPIEndPoint
            const formData = new FormData();
            let siteConfig = {};
            //console.log("Employee Data: ", this.employee);
            formData.append("emp_id", this.employee.emp_id);
            formData.append("emp_code", this.employee.emp_code);
            formData.append("emp_name", this.employee.emp_name);
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append("file", this.selectedFiles[i]);
            }

            //console.log("URL is", url);

            siteConfig = {
                method: "POST",
                url: url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.$axios(siteConfig

            ).then(async(response) => {
                if (response.status == 201) {
                    //console.log("verification Created Successfully");
                    this.$toast.success(response.data.message);
                    let verification_url = this.$api + this.$config.configurations.verificationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getVerificationList(verification_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();

        },

    }
}