import { createRouter, createWebHashHistory } from "vue-router";
import guard from "../guard";
// import Home from "../components/home/template/Home";
import Login from "../components/auth/template/Login";
import ForgotPassword from "../components/auth/template/ForgotPassword";
import ResetPassword from "../components/auth/template/ResetPassword";
import Signup from "../components/auth/template/Signup";
import FAQCategory from "../components/faq/template/FAQCategory";
import FAQContent from "../components/faq/template/FAQContent";
import FAQPageCMS from "../components/faq/template/FAQPageCMS";
import RoleList from "../components/role/template/RoleList";
import AddRole from "../components/role/template/AddRole";
import Dashboard from "../components/dashboard/template/Dashboard";
import Employee from "../components/Employee Management/employee/template/Employee";
import BulkEmployee from "../components/Employee Management/employee/template/BulkEmployee";
import StuckOff from "../components/Employee Management/Stuck Off/template/StuckOff";
// import Configurations from "../components/configurations/template/Configurations";
import Company from "../components/Configurations/company/template/Company";
import Branch from "../components/Configurations/branch/template/Branch";
import Department from "../components/Configurations/department/template/Department";
import Section from "../components/Configurations/section/template/Section";
import Role from "../components/Role Management/role/template/Role";
import City from "../components/Configurations/city/template/City";
import Country from "../components/Configurations/country/template/Country";
import Designation from "../components/Configurations/designation/template/Designation";
import Certificate from "../components/Configurations/certificate/template/Certificate";
import Verification from "../components/Configurations/verification/template/Verification";
import LeaveBalance from "../components/Leave Management/Employee Leave Balance/template/LeaveBalance";
import LeavePolicy from "../components/Leave Management/Leave Policy/template/LeavePolicy";
import LeaveMailFormat from "../components/Leave Management/Leave Mail Format/template/LeaveMailFormat";
import LeaveApprovalHistory from "../components/Leave Management/Leave Approval History/template/LeaveApprovalHistory";
import LeaveApprovalSteps from "../components/Leave Management/Leave Approval Chain Steps/template/LeaveApprovalSteps";
import LeaveApplication from "../components/Leave Management/Leave Application/template/LeaveApplication";
import LeaveRequest from "../components/Leave Management/Leave Request/template/LeaveRequest";
import LeaveRequestMail from "../components/Leave Management/Leave Request/template/LeaveRequestMail";
import LeaveRequestMailReject from "../components/Leave Management/Leave Request/template/LeaveRequestMailReject";
import CompanyCalender from "../components/Leave Management/Company Calender/template/CompanyCalender";
import MenuPages from "../components/Role Management/menu_pages/template/MenuPages";
import UserRole from "../components/Role Management/user_role/template/UserRole";
import RolePages from "../components/Role Management/role_pages/template/RolePages";
import LeaveManagement from "../components/Group Component/leave_management/template/LeaveManagement";
import Salary from "../components/Payroll Management/Salary/template/Salary";
import AccountDetails from "../components/Payroll Management/Employee Account Details/template/AccountDetails";
import SalaryGrade from "../components/Payroll Management/Salary Grade/template/SalaryGrade";
import Bonus from "../components/Payroll Management/bonus/template/Bonus";
import IncomeTax from "../components/Payroll Management/Income Tax/template/IncomeTax";
import PayrollInfo from "../components/Payroll Management/Payroll Info/template/PayrollInfo";
import Payslip from "../components/Payroll Management/Payslip/template/Payslip";
import SalaryLog from "../components/Payroll Management/Salary Log/template/SalaryLog";
import EmployeeWorkLog from "../components/Payroll Management/Employee Work Log/template/EmployeeWorkLog";
import MiscellaneousCost from "../components/Payroll Management/Miscellaneous Cost/template/MiscellaneousCost";
import ShiftPolicy from "../components/Attendance Management/Shift Policy/template/ShiftPolicy";
import EmployeeShiftPolicy from "../components/Attendance Management/Employee Shift Policy/template/EmployeeShiftPolicy";
import EmployeeAttendance from "../components/Attendance Management/Employee Attendance Log/template/EmployeeAttendance";
import Reporting from "../components/Reporting/template/Reporting";
import Profile from "../components/profile/template/profile";
import ChangePassword from "../components/profile/template/changePassword";
import LeavePending from "../components/Leave Management/LeavePendingList/template/LeavePending";
// import Zkteco from "../components/Attendance Management/zkteco/template/index";

const routes = [
    /* Auth Routes */
    { path: "/login", name: "Login", component: Login },
    { path: "/signup", name: "Signup", component: Signup },
    { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword },
    { path: "/reset-password", name: "ResetPassword", component: ResetPassword },

    // { path: "/sdk", name: "Zkteco", component: Zkteco },

    /* Home Route */
    // { path: "/", name: "Home", component: Home, beforeEnter: guard },
    { path: "/", name: "Dashboard", component: Dashboard, beforeEnter: guard },
    { path: "/dashboard", name: "Dashboards", component: Dashboard, beforeEnter: guard },
    { path: "/change-password", name: "ChangePassword", component: ChangePassword, beforeEnter: guard },
    { path: "/user-info", name: "Profile", component: Profile, beforeEnter: guard },

    /* Dashboard */
    // { path: "/dashboard", name: "Dashboard", component: Dashboard, beforeEnter: guard},

    /* Employee */
    { path: "/employee", name: "Employee", component: Employee, beforeEnter: guard },
    { path: "/stuck-off-employee", name: "StuckOff", component: StuckOff, beforeEnter: guard },
    { path: "/bulk-employee", name: "BulkEmployee", component: BulkEmployee, beforeEnter: guard },
    // { path: "/settings", name: "Configurations", component: Configurations},
    { path: "/company", name: "Company", component: Company, beforeEnter: guard },
    { path: "/branch", name: "Branch", component: Branch, beforeEnter: guard },
    { path: "/department", name: "Department", component: Department },
    { path: "/section", name: "Section", component: Section, beforeEnter: guard },
    { path: "/country", name: "Country", component: Country, beforeEnter: guard },
    { path: "/city", name: "City", component: City, beforeEnter: guard },
    { path: "/designation", name: "Designation", component: Designation, beforeEnter: guard },
    { path: "/certificate", name: "Certificate", component: Certificate, beforeEnter: guard },
    { path: "/verification", name: "Verification", component: Verification, beforeEnter: guard },

    /* Leave Management */
    { path: "/leave-pending-list", name: "LeavePending", component: LeavePending, beforeEnter: guard },
    { path: "/leave-management-settings", name: "LeaveManagement", component: LeaveManagement, beforeEnter: guard },
    { path: "/employee-leave-balance", name: "LeaveBalance", component: LeaveBalance, beforeEnter: guard },
    { path: "/leave-policy", name: "LeavePolicy", component: LeavePolicy, beforeEnter: guard },
    { path: "/leave-mail-format", name: "LeaveMailFormat", component: LeaveMailFormat, beforeEnter: guard },
    { path: "/leave-approval-history", name: "LeaveApprovalHistory", component: LeaveApprovalHistory, beforeEnter: guard },
    { path: "/leave-approval-steps", name: "LeaveApprovalSteps", component: LeaveApprovalSteps },
    { path: "/leave-application", name: "LeaveApplication", component: LeaveApplication, beforeEnter: guard },
    { path: "/leave-request", name: "LeaveRequest", component: LeaveRequest, beforeEnter: guard },

    { path: "/approved/:id/:did", name: "LeaveRequestMail", component: LeaveRequestMail },
    { path: "/reject/:id/:did", name: "LeaveRequestMailReject", component: LeaveRequestMailReject },

    { path: "/company-calender", name: "CompanyCalender", component: CompanyCalender, beforeEnter: guard },

    /* Role Management */
    { path: "/role", name: "Role", component: Role, beforeEnter: guard },
    { path: "/menu-pages", name: "MenuPages", component: MenuPages, beforeEnter: guard },
    { path: "/user-role", name: "UserRole", component: UserRole, beforeEnter: guard },
    { path: "/role-pages", name: "RolePages", component: RolePages, beforeEnter: guard },

    /* Payroll Management */
    { path: "/salary", name: "Salary", component: Salary, beforeEnter: guard },
    { path: "/salary-grade", name: "SalaryGrade", component: SalaryGrade, beforeEnter: guard },
    { path: "/account-details", name: "AccountDetails", component: AccountDetails, beforeEnter: guard },
    { path: "/bonus", name: "Bonus", component: Bonus, beforeEnter: guard },
    { path: "/income-tax", name: "IncomeTax", component: IncomeTax, beforeEnter: guard },
    // { path: "/pay-rate", name: "PayRate", component: PayRate, beforeEnter: guard },
    { path: "/pay-roll-info", name: "PayrollInfo", component: PayrollInfo, beforeEnter: guard },
    { path: "/payslip", name: "Payslip", component: Payslip, beforeEnter: guard },
    { path: "/salary-log", name: "SalaryLog", component: SalaryLog, beforeEnter: guard },
    { path: "/miscellaneous-cost", name: "MiscellaneousCost", component: MiscellaneousCost, beforeEnter: guard },
    { path: "/employee-work-log", name: "EmployeeWorkLog", component: EmployeeWorkLog, beforeEnter: guard },

    /* Attendance Management */
    { path: "/shift-policy", name: "ShiftPolicy", component: ShiftPolicy, beforeEnter: guard },
    { path: "/employee-shift-policy", name: "EmployeeShiftPolicy", component: EmployeeShiftPolicy, beforeEnter: guard },
    { path: "/employee-attendance-log", name: "EmployeeAttendance", component: EmployeeAttendance, beforeEnter: guard },

    /* Reporting */
    { path: "/reporting", name: "Reporting", component: Reporting,},

    /* FAQ Routes */
    { path: "/faq-category", name: "FAQCategory", component: FAQCategory },
    { path: "/faq-content", name: "FAQContent", component: FAQContent },
    { path: "/faq-page-cms", name: "FAQPageCMS", component: FAQPageCMS },

    /* Role Route */
    { path: "/role-list", name: "RoleList", component: RoleList, beforeEnter: guard },
    { path: "/add-role", name: "AddRole", component: AddRole, beforeEnter: guard },

    /* Product Routes */
];

const router = createRouter({
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes,
  });
export default router;