import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            payroll_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            employees: [],
            salary_issue_mediums: [],
            salary_grades: [],
            employee: {},
            salary_grade: {},
            salary_issue_medium: {},
            payroll_info_details: {
                employee: {},
                salary_grade: {},
                salary_issue_medium: {}
            },
            selected_salary_grade: false

        }
    },
    mounted() {
        this.salary_issue_mediums = [{
                id: 0,
                name: "Cash"
            },
            {
                id: 1,
                name: "Cheque"
            },
            {
                id: 2,
                name: "Bank Account"
            }
        ];
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let payroll_data_url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint + params;
        this.getDataList(payroll_data_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint;
        this.$axios.get(salary_grade_url, {}).then((response) => {
            if (response.status == 200) {
                this.salary_grades = response.data
                // for (const key in Object.keys(response.data)) {
                //     this.salary_grades.push({
                //         id: response.data[key].id,
                //         salary_code: response.data[key].salary_code
                //     });
                // }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    watch: {
        salary_grade: function() {
            this.selected_salary_grade = true;
        }
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let payroll_data_url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint + params;
        await this.getDataList(payroll_data_url);
        },
        deleteInfo: function(id) {
            this.payroll_id = id;
        },
        deletePayrollInfo: async function() {
            let payroll_info_details_url = this.$api + this.$config.payroll.payrollInfoDetails + this.payroll_id;
            await this.$axios.delete(payroll_info_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let payroll_data_url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=";
                    await this.getDataList(payroll_data_url);
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updatePayrollInfo: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.PayrollInfoDetails);
            let payroll_info_details_url = this.$api + this.$config.payroll.payrollInfoDetails + this.payroll_id;
            await this.$axios.put(payroll_info_details_url, {
                emp_id: this.payroll_info_details.employee.emp_id,
                emp_code: this.payroll_info_details.employee.emp_code,
                emp_name: this.payroll_info_details.employee.emp_name,
                salary_grade_id: this.payroll_info_details.salary_grade.id,
                salary_code: this.payroll_info_details.salary_grade.salary_code,
                salary_issue_medium: this.payroll_info_details.salary_issue_medium.id

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let payroll_data_url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(payroll_data_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getPayrollInfoData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                    //console.log("All Data is", this.allDataList);
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.payroll_id = id;
            let leave_payroll_info_details_url = this.$api + this.$config.payroll.payrollInfoDetails + this.payroll_id;
            //console.log(leave_payroll_info_details_url);
            await this.$axios.get(leave_payroll_info_details_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("response== ",response);
                    this.payroll_info_details.employee = {
                        emp_id: response.data.emp_id,
                        emp_code: response.data.emp_code,
                        emp_name: response.data.emp_name
                    };
                    this.payroll_info_details.salary_grade = {
                        id: response.data.salary_grade_id,
                        salary_code: response.data.salary_code
                    };
                    if (response.data.salary_issue_medium == 0) {
                        this.payroll_info_details.salary_issue_medium = {
                            id: 0,
                            name: "Cash"
                        };
                    } else if (response.data.salary_issue_medium == 1) {
                        this.payroll_info_details.salary_issue_medium = {
                            id: 1,
                            name: "Cheque"
                        };
                    } else if (response.data.salary_issue_medium == 2) {
                        this.payroll_info_details.salary_issue_medium = {
                            id: 2,
                            name: "Bank Account"
                        };
                    }


                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createPayrollInfo: async function() {

            let url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint;
            let data = {
                emp_id: this.employee.emp_id,
                emp_code: this.employee.emp_code,
                emp_name: this.employee.emp_name,
                salary_grade_id: this.salary_grade.id,
                salary_code: this.salary_grade.salary_code,
                salary_issue_medium: this.salary_issue_medium.id

            }
            let config = {
                method: "POST",
                data: data,
                url: url
            }
            await this.postDataToBackend(config);
            let payroll_data_url = this.$api + this.$config.payroll.PayrollInfoAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            this.employee = ''
            this.salary_grade = ''
            this.salary_issue_medium = ''
            await this.getDataList(payroll_data_url);

        },

    }
}