//import { authHeader } from './auth';
import moment from 'moment';
import VueJwtDecode from 'vue-jwt-decode';
import { authHeader } from "./auth";

export default {
    data() {
        return {
            token_info: {},
            userInfo: {},
            allDataList: [],
            total_page: null,
            new_employees: [],
            employees: []
        }
    },
    methods: {
        getCurrentUserInfo: async function() {
            this.isLoading = true

            this.token_info = VueJwtDecode.decode(localStorage.getItem("token"));
            // console.log("User Id: ", this.token_info.user_id)
            let user_info_url = this.$api + this.$config.auth.userInfoAPIEndPoint + this.token_info.user_id;
            let config = {
                method: "GET",
                url: user_info_url,
                headers: authHeader()
            }
            //console.log("Url is: ", user_info_url)
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    this.userInfo = response.data;
                    // console.log("User Info data: ", this.userInfo)
                    var date_off_stuck_off = response.data.date_off_stuck_off
                    var is_active = response.data.is_active
                    if(is_active==false) {
                        localStorage.clear("token");
                        this.$router.push("/login")
                        this.$toast.error("You are not allowed to access!");
                    }
                    var now = moment(new Date()); //todays date
                    var end = moment(date_off_stuck_off); // another date
                    var duration = moment.duration(now.diff(end));
                    var days = parseInt(duration.asDays());
                    if(days < 1 ){
                        localStorage.clear("token");
                        this.$router.push("/login")
                        this.$toast.error("You are not allowed to access at this time.");
                    } 


                } else {
                    this.isLoading = false
                }
            }).catch(error => {
               // console.log(error)
                this.isLoading = false
                if (error.response.status == 401) {
                    this.redirectToLoginPage();
                } else{
                    this.$toast.error(error.response.data.message);
                }
            });
        },
        getPermissionPages: async function() {
            this.isLoading = true
            await this.getCurrentUserInfo();
            // console.log("Userinfo is: ", this.userInfo);
            let url = this.$api + this.$config.role.rolePagesListAPIEndPoint + '?role_id=' + this.userInfo.role_id;
            let config = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    let permission_pages = [];
                    for (const key in Object.keys(response.data)) {
                        permission_pages.push(response.data[key].page_name);
                    }
                    localStorage.setItem('permission_pages', permission_pages)
                    // console.log("Permission Pages are: ", permission_pages);

                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        redirectToLoginPage() {
            const message = 'Session Expired or Invalid Access!<br>Please Login to Continue.';
            this.$toast.error(message);
                  localStorage.clear();
                  this.$router.push("/");
                  window.location.reload();
        },
        getDataList: async function(url) {
            let config = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            // console.log("Url is: ", url)
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    // console.log("Data is", response.data);
                    this.allDataList = response.data.data;
                    // console.log("Getting All Data");
                    // console.log("All Data getting: ", this.allDataList);
                    this.total_page = response.data.last_page;
                     
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
                
            });
        },
        getAllDataList: async function(url) {
            let config = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            // console.log("Url is: ", url)
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    // console.log("Data is", response.data);
                    this.allDataList = response.data;
                    // console.log("Getting All Data");
                    // console.log("All Data getting: ", this.allDataList);
                     
                } 
            }).catch(error => {
                console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
                
            });
        },
        postDataToBackend: async function(config) {
            this.isLoading = true
            // config +={
            //     headers: authHeader()
            // }
            // console.log("config",config)
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 201) {
                    this.$toast.success(response.data.message);
                }
            }).catch(error => {
                console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getEmployees: async function() {
            this.isLoading = false
            let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
            let config = {
                method: "GET",
                url: emp_url,
                headers: authHeader()
            }
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    // console.log("Data is", response.data);
                    for (const key in Object.keys(response.data)) {
                        this.employees.push({
                            emp_code: response.data[key].emp_code,
                            emp_name: response.data[key].first_name + " " + response.data[key].last_name
                        });
                    }
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
                
            });
        }
    }
}