<template>
    <body class="bg-login">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="wrapper">
            <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                <div class="container-fluid">
                    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div class="col mx-auto">
                            <div class="mb-4 text-center">
                                <img src="../../../../public/core/assets/images/hrconnect.jpg" width="180" alt=""/>
                                <h1>HRConnect</h1>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                        <!-- <div class="text-center">
                                            <h3 class="">Sign in</h3>
                                            <p>Don't have an account yet? <router-link to="/signup">Sign up here</router-link></p>
                                        </div>
                                        <div class="d-grid">
                                            <a class="btn my-4 shadow-sm btn-white" href="javascript:;"> 
                                                <span class="d-flex justify-content-center align-items-center">
                                                    <img class="me-2" src="../../../../public/core/assets/images/icons/search.svg" width="16" alt="Image Description">
                                                    <span>Sign in with Google</span>
                                                </span>
                                            </a> 
                                            <a href="javascript:;" class="btn btn-facebook"><i class="bx bxl-facebook"></i>Sign in with Facebook</a>
                                        </div> -->
                                        <div class="login-separater text-center mb-4"><span>SIGN IN WITH Employee ID</span>
                                            <hr/>
                                        </div>
                                        <div class="form-body">
                                            <form class="row g-3" @submit.prevent="login">
                                                <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Employee ID</label>
                                                    <input type="text" v-model="username" class="form-control" id="inputEmailAddress" placeholder="Enter Employee ID">
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input :type="inputType" v-model="password" class="form-control border-end-0" id="inputChoosePassword" placeholder="Enter Password"><a @click="changeInputType()" href="javascript:void(0)" class="input-group-text bg-transparent"><i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <!-- <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                                        <label class="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                                                    </div> -->
                                                </div>
                                                <div class="col-md-6 text-end">
                                                    <router-link to="/forgot-password">Forgot Password?</router-link>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="btn btn-primary"><i class="bx bxs-lock-open"></i>Log in</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script src="../js/login.js"></script>
