import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            cmp_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            region: '',
            phone: null,
            company_name: '',
            address: '',
            email: '',
            cmp_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.configurations.companyAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let company_url = this.$api + this.$config.configurations.companyAPIEndPoint + params;
        this.getDataList(company_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let company_url = this.$api + this.$config.configurations.companyAPIEndPoint + params;
            await this.getDataList(company_url);
        },
        deleteInfo: async function(id) {
            this.cmp_id = id;
        },
        deleteCompany: async function() {
            let company_details_url = this.$api + this.$config.configurations.companyDetails + this.cmp_id;
            this.$axios.delete(company_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let company_url = this.$api + this.$config.configurations.companyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(company_url);

                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateCompany: async function() {
            //console.log("cmployee Details is: ", this.cmp_details);
            let cmp_details_url = this.$api + this.$config.configurations.companyDetails + this.cmp_id;
            this.$axios.put(cmp_details_url, {
                company_name: this.cmp_details.company_name,
                address: this.cmp_details.address,
                email: this.cmp_details.email,
                phone: this.cmp_details.phone

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let company_url = this.$api + this.$config.configurations.companyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(company_url);

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getCompanyData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            this.$axios.get(emp_url, {}).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.cmp_id = id;
            let cmp_details_url = this.$api + this.$config.configurations.companyDetails + this.cmp_id;
            //console.log(cmp_details_url);
            this.$axios.get(cmp_details_url, {}).then(async(response) => {
                if (response.status == 200) {
                    this.cmp_details.company_name = response.data.company_name;
                    this.cmp_details.address = response.data.address;
                    this.cmp_details.email = response.data.email;
                    this.cmp_details.phone = response.data.phone;

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createCompany: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.companyAPIEndPoint
            this.$axios.post(url, {
                company_name: this.company_name,
                address: this.address,
                email: this.email,
                phone: this.phone
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("Company Created Successfully");
                    this.$toast.success(response.data.message);
                    let company_url = this.$api + this.$config.configurations.companyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(company_url);

                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}