<template>
    <div class="page-wrapper">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="page-content">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Employees</p>
                                    <h4 class="my-1">{{dashboardData.total_employees}}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <i class='bx bxs-group'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body">
                            <router-link to="/leave-application">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Leave Application</p>
                                    <h4 class="my-1">{{dashboardData.total_leave_application}}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <i class='bx bx-envelope-open'></i>
                                </div>
                            </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Late Day</p>
                                    <h4 class="my-1">{{dashboardData.total_late_days}}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <i class='bx bx-spreadsheet'></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="text-center">
                                <div class="rounded-circle border border-2 border-primary mx-auto" style="width: 100px;height: 100px;">
                                    <img :src="backend_url + dataList.image" class="rounded-circle w-100 h-100"  v-if="dataList.image">
                                    <img src="https://www.nicepng.com/png/detail/136-1366211_group-of-10-guys-login-user-icon-png.png" class="rounded-circle w-100 h-100" v-else>
                                </div>
                                <div class="mt-3">
                                    <h4> {{ dataList.first_name + ' ' + dataList.last_name }} </h4>
                                    <p class="text-secondary mb-1"> {{ dataList.designation_name }} </p>
                                    <p class="text-muted font-size-sm"> {{ dataList.company_name }} </p>
                                </div>
                            </div>
                            <hr class="my-4" />
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0">Department</h6>
                                    <span class="text-secondary"> {{ dataList.department_name }} </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0">Branch</h6>
                                    <span class="text-secondary"> {{ dataList.branch_name }} </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0">Email</h6>
                                    <span class="text-secondary"> {{ dataList.email }} </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 class="mb-0">Phone</h6>
                                    <span class="text-secondary"> {{ dataList.phone }} </span>
                                </li>
                            </ul>		
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-8 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <!-- <div id='calendar'></div> -->
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-0">Leave Summary</h5>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                            <hr/>
                            <div class="table-responsive" v-if="Object.keys(leave_application_list).length > 0">
                                <table class="table align-middle mb-0">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Sl</th>
                                            <th>Leave Title</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Total Leave Days</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(row, index) in leave_application_list" :key="index">
                                        <tr>
                                            <td>{{ index+1 }}</td>
                                            <td>{{ row.leave_policy_name }}</td>
                                            <td>{{ row.leave_start_date }}</td>
                                            <td>{{ row.leave_end_date }}</td>
                                            <td>{{ row.total_leave_day }}</td>
                                            <td>{{ row.leave_status == 0 ? "Draft" : row.leave_status == 1 ? "Pending" : row.leave_status == 2 ? "In Progress" : row.leave_status == 3 ? "Approved" : row.leave_status == 4 ? "Rejected" : row.leave_status == 5 ? "Cancelled" : "Draft"}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card" v-else>
                                <h3 class="text-info text-center">No Leave Application</h3>
                            </div>
                        </div>
                        <!-- <div class="product-list p-3 mb-3">
                           <div class="card">
                                <div class="card-body">
                                    <div id='calendar'></div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-xl-8 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-1">Transaction History</h5>
                                    <p class="mb-0 font-13 text-secondary"><i class='bx bxs-calendar'></i>in last 30 days revenue</p>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                            <div class="table-responsive mt-4">
                                <table class="table align-middle mb-0 table-hover" id="Transaction-History">
                                    <thead class="table-light">
                                        <tr>
                                            <th>Payment Name</th>
                                            <th>Date & Time</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-1.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Michle Jhon</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #8547846</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 10, 2021</td>
                                            <td>+256.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-2.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Pauline Bird</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #9653248</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 12, 2021</td>
                                            <td>+566.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-info text-dark w-100">In Progress</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-3.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Ralph Alva</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #7689524</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 14, 2021</td>
                                            <td>+636.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-danger w-100">Declined</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-4.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from John Roman</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #8335884</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 15, 2021</td>
                                            <td>+246.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-7.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from David Buckley</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #7865986</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 16, 2021</td>
                                            <td>+876.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-info text-dark w-100">In Progress</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-8.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Lewis Cruz</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #8576420</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 18, 2021</td>
                                            <td>+536.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-9.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from James Caviness</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #3775420</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 18, 2021</td>
                                            <td>+536.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-10.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Peter Costanzo</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #3768920</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 19, 2021</td>
                                            <td>+536.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-11.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Johnny Seitz</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #9673520</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 20, 2021</td>
                                            <td>+86.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-danger w-100">Declined</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-12.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Lewis Cruz</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #8576420</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 18, 2021</td>
                                            <td>+536.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-13.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from David Buckley</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #8576420</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 22, 2021</td>
                                            <td>+854.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-info text-dark w-100">In Progress</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <div class="">
                                                        <img src="../../../../public/core/assets/images/avatars/avatar-14.png" class="rounded-circle" width="46" height="46" alt="" />
                                                    </div>
                                                    <div class="ms-2">
                                                        <h6 class="mb-1 font-14">Payment from Thomas Wheeler</h6>
                                                        <p class="mb-0 font-13 text-secondary">Refrence Id #4278620</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Jan 18, 2021</td>
                                            <td>+536.00</td>
                                            <td>
                                                <div class="badge rounded-pill bg-success w-100">Completed</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="card radius-10 overflow-hidden">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="">
                                    <p class="mb-1 text-secondary">Bounce Rate</p>
                                    <h4 class="mb-0">48.32%</h4>
                                </div>
                                <div class="ms-auto">
                                    <p class="mb-0 font-13 text-success">+12.34 Increase</p>
                                    <p class="mb-0 font-13 text-secondary">From Last Week</p>
                                </div>
                            </div>
                        </div>
                        <div id="chart12"></div>
                    </div>
                    <div class="card radius-10 overflow-hidden">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="">
                                    <p class="mb-1 text-secondary">Pageviews</p>
                                    <h4 class="mb-0">52.64%</h4>
                                </div>
                                <div class="ms-auto">
                                    <p class="mb-0 font-13 text-success">+21.34 Increase</p>
                                    <p class="mb-0 font-13 text-secondary">From Last Week</p>
                                </div>
                            </div>
                        </div>
                        <div id="chart13"></div>
                    </div>
                    <div class="card radius-10 overflow-hidden">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="">
                                    <p class="mb-1 text-secondary">New Sessions</p>
                                    <h4 class="mb-0">68.23%</h4>
                                </div>
                                <div class="ms-auto">
                                    <p class="mb-0 font-13 text-success">+18.42 Increase</p>
                                    <p class="mb-0 font-13 text-secondary">From Last Week</p>
                                </div>
                            </div>
                        </div>
                        <div id="chart14"></div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="row row-cols-1 row-cols-lg-3">
                <div class="col d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-0">Top Categories</h5>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                            <div class="mt-5" id="chart15"></div>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex bg-transparent justify-content-between align-items-center">Kids <span class="badge bg-success rounded-pill">25</span>
                            </li>
                            <li class="list-group-item d-flex bg-transparent justify-content-between align-items-center">Women <span class="badge bg-danger rounded-pill">10</span>
                            </li>
                            <li class="list-group-item d-flex bg-transparent justify-content-between align-items-center">Men <span class="badge bg-primary rounded-pill">65</span>
                            </li>
                            <li class="list-group-item d-flex bg-transparent justify-content-between align-items-center">Furniture <span class="badge bg-warning text-dark rounded-pill">14</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <p class="font-weight-bold mb-1 text-secondary">Visitors</p>
                            <div class="d-flex align-items-center">
                                <div>
                                    <h4 class="mb-0">43,540</h4>
                                </div>
                                <div class="">
                                    <p class="mb-0 align-self-center font-weight-bold text-success ms-2">4.4 <i class='bx bxs-up-arrow-alt mr-2'></i>
                                    </p>
                                </div>
                            </div>
                            <div id="chart21"></div>
                        </div>
                    </div>
                </div>
                <div class="col d-flex">
                    <div class="card radius-10 w-100 overflow-hidden">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-0">Sales Overiew</h5>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                            <div class="mt-5" id="chart20"></div>
                        </div>
                        <div class="card-footer bg-transparent border-top-0">
                            <div class="d-flex align-items-center justify-content-between text-center">
                                <div>
                                    <h6 class="mb-1 font-weight-bold">$289.42</h6>
                                    <p class="mb-0 text-secondary">Last Week</p>
                                </div>
                                <div class="mb-1">
                                    <h6 class="mb-1 font-weight-bold">$856.14</h6>
                                    <p class="mb-0 text-secondary">Last Month</p>
                                </div>
                                <div>
                                    <h6 class="mb-1 font-weight-bold">$987,25</h6>
                                    <p class="mb-0 text-secondary">Last Year</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-12 col-xl-6 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-0">New Customers</h5>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                        </div>
                        <div class="customers-list p-3 mb-3">
                            <div class="customers-list-item d-flex align-items-center border-top border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-3.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Emy Jackson</h6>
                                    <p class="mb-0 font-13 text-secondary">emy_jac@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-4.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Martin Hughes</h6>
                                    <p class="mb-0 font-13 text-secondary">martin.hug@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-23.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Laura Madison</h6>
                                    <p class="mb-0 font-13 text-secondary">laura_01@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-24.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Shoan Stephen</h6>
                                    <p class="mb-0 font-13 text-secondary">s.stephen@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-20.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Keate Medona</h6>
                                    <p class="mb-0 font-13 text-secondary">Keate@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-16.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Paul Benn</h6>
                                    <p class="mb-0 font-13 text-secondary">pauly.b@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-25.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Winslet Maya</h6>
                                    <p class="mb-0 font-13 text-secondary">winslet_02@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-11.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Bruno Bernard</h6>
                                    <p class="mb-0 font-13 text-secondary">bruno.b@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-17.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Merlyn Dona</h6>
                                    <p class="mb-0 font-13 text-secondary">merlyn.d@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                            <div class="customers-list-item d-flex align-items-center border-bottom p-2 cursor-pointer">
                                <div class="">
                                    <img src="../../../../public/core/assets/images/avatars/avatar-7.png" class="rounded-circle" width="46" height="46" alt="" />
                                </div>
                                <div class="ms-2">
                                    <h6 class="mb-1 font-14">Alister Campel</h6>
                                    <p class="mb-0 font-13 text-secondary">alister_42@xyz.com</p>
                                </div>
                                <div class="list-inline d-flex customers-contacts ms-auto">	<a href="javascript:;" class="list-inline-item"><i class='bx bxs-envelope'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bxs-microphone'></i></a>
                                    <a href="javascript:;" class="list-inline-item"><i class='bx bx-dots-vertical-rounded'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-6 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-0">Orders Summary</h5>
                                </div>
                                <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                                </div>
                            </div>
                            <hr/>
                            <div class="row m-0 row-cols-1 row-cols-md-3">
                                <div class="col border-end">
                                    <div id="chart16"></div>
                                </div>
                                <div class="col border-end">
                                    <div id="chart17"></div>
                                </div>
                                <div class="col">
                                    <div id="chart18"></div>
                                </div>
                            </div>
                            <div id="chart19"></div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="card radius-10">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div>
                            <h5 class="mb-0">Leave Summary</h5>
                        </div>
                        <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
                        </div>
                    </div>
                    <hr/>
                    <div class="table-responsive" v-if="Object.keys(leave_application_list).length > 0">
                        <table class="table align-middle mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Sl</th>
                                    <th>Leave Title</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Total Leave Days</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-for="(row, index) in leave_application_list" :key="index">
                                <tr>
                                    <td>{{ index+1 }}</td>
                                    <td>{{ row.leave_policy_name }}</td>
                                    <td>{{ row.leave_start_date }}</td>
                                    <td>{{ row.leave_end_date }}</td>
                                    <td>{{ row.total_leave_day }}</td>
                                    <td>{{ row.leave_status == 0 ? "Draft" : row.leave_status == 1 ? "Pending" : row.leave_status == 2 ? "In Progress" : row.leave_status == 3 ? "Approved" : row.leave_status == 4 ? "Rejected" : row.leave_status == 5 ? "Cancelled" : "Draft"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card" v-else>
                        <h3 class="text-info text-center">No Leave Application</h3>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script src="../js/dashboard.js"></script>
<style>
    @import '../../../../public/core/assets/plugins/fullcalendar/css/main.min.css';
</style>