import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            mail_format_id: null,
            page: 1,
            limit: 10,
            showSuccessMessage:false,
        }
    },
    created() {
        document.title = "HrConnect - Leave";
        
        //console.log("UserInfo in Leave Request: ", this.userInfo)
    },
    async mounted() {
        const id = this.$route.params.id;
        const did = this.$route.params.did;

        let leave_approval_history = this.$api + this.$config.leave.leaveApplicationApprovalAPIEndPoint + "?leave_application_id=" + id;
        await this.$axios.get(leave_approval_history, {}).then((response) => {
            this.isLoading = false
            if (response.status == 200) {
                //  console.log("Data is", response.data.data);
                    const data = response.data.data;
                    for(let key in data){
                        // console.log("Data is = ", data[key]['delegated_employee_status']);
                        const delegated_employee_status = data[key]['delegated_employee_status'];
                        const delegated_employee_code = data[key]['delegated_employee_code'];
                        const step_num = data[key]['step_num'];
                        const dataKey = parseInt(key)  + 1;
                        
                        this.showSuccessMessage = true; 
                        if(delegated_employee_status !='Accepted' && step_num == dataKey && delegated_employee_code == did){
                            // console.log("delegated_employee_status",delegated_employee_status);
                            // console.log("step_num",step_num);
                            // console.log("dataKey",dataKey);
                            this.data_list_index = key;
                        }
                    }
                this.allDataList = response.data.data;
                // console.log("this.data_list_index",this.data_list_index);
                this.approveLeaveApprovalHistory();
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });


    },
    methods: {
        approveLeaveApprovalHistory: async function() {
            // console.log("this.allDataList",this.allDataList);
            // console.log("this.data_list_index",this.data_list_index);
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApprovalHistoryDetails + this.allDataList[this.data_list_index].id
            let data = {
                employee_id: this.allDataList[this.data_list_index].employee_id,
                employee_code: this.allDataList[this.data_list_index].employee_code,
                employee_name: this.allDataList[this.data_list_index].employee_name,
                delegation_date_time: this.allDataList[this.data_list_index].delegation_date_time,
                delegated_employee_id: this.allDataList[this.data_list_index].delegated_employee_id,
                delegated_employee_code: this.allDataList[this.data_list_index].delegated_employee_code,
                delegated_employee_remarks: this.delegated_employee_remarks,
                delegated_employee_status: "Rejected",
                leave_application_id: this.allDataList[this.data_list_index].leave_application_id,
                step_num: this.allDataList[this.data_list_index].step_num,
                total_step_num: this.allDataList[this.data_list_index].total_step_num,

            }
            let config = {
                method: 'PUT',
                url: url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 201 || response.status == 200) {
                    //console.log("Leave Approved Successfully");
                    this.$toast.success(response.data.message);
                    this.showSuccessMessage = true; 
                } else {
                    this.isLoading = false
                }
            }).catch(async error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
    }
}