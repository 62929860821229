<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Salary Grade
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Salary Grade List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
          <div class="row mb-3 ms-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  @click="searchData"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Salary Code</th>
                  <th>Health Allowance</th>
                  <th>Transport Allowance</th>
                  <th>Housing Allowance</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.salary_code }}</td>
                  <td>{{ li.health_salary }}</td>
                  <td>{{ li.transport_salary }}</td>
                  <td>{{ li.housing_salary }}</td>
                  

                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-success btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal3"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Salary Grade Creation</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Salary Code<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="salary_code"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Salary Code"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Basic Salary<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="basic_salary"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Basic Salary"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Health Allowance<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="health_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Health Allowance"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Transport Allowance<span
                        style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="transport_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Transport Allowance"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Housing Allowance<span
                        style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      v-model="housing_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Housing Allowance"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Bonus type<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <select
                      v-model="bonus_type"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in bonus_types"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createSalaryGrade()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Salary Grade</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Salary Code</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.salary_code"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Bank Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Basic Salary</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.basic_salary"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Basic Salary"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Health Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.health_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Branch Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Transport Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.transport_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Account Number"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Housing Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.housing_allowance"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Routing Number"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Bonus type</label
                    >
                    <select
                      v-model="salary_grade_details.bonus_type"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in bonus_types"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateSalaryGrade()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal3"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Salary Grade Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Salary Code</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.salary_code"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Basic Salary</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.basic_salary"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Health Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.health_allowance"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Transport Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.transport_allowance"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Housing Allowance</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.housing_allowance"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Bonus type</label
                    >
                    <input
                      type="text"
                      v-model="salary_grade_details.bonus_type.name"
                      class="form-control"
                      id="inputPhone"
                      disabled
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteSalaryGrade()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/salary_grade.js"></script>