import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            allDataList: [],
            cmp_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search:'',
            id: null,
            policy: {},
            emp_code: null,
            emp_id: null,
            employee: {},
            employees: [],
            leave_total_days: null,
            leave_avail_days: null,
            leave_balance: null,
            leave_balance_details: {} 
        }
    },
    mounted() {
        this.setURL = this.$config.leave.leaveApprovalHistoryAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_balance_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + params;
        this.getDataList(leave_balance_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;        
        this.$axios.get(emp_url, {
        }).then((response) => {
            if(response.status == 200) {
                // //console.log("Data is", response.data);
                for(const key in Object.keys(response.data.data)) {
                    this.employees.push(
                        {
                            emp_id: response.data.data[key].id,
                            emp_code: response.data.data[key].emp_code,
                        });
                }
            }
            else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        
    },
    created() {
        document.title = "HrConnect - Leave";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_balance_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + params;
            await this.getDataList(leave_balance_url);
        },
        deleteInfo: function(id) {
            this.cmp_id = id;
        },
        deleteLeaveBalance: async function() {
            let leave_balance_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + this.cmp_id;
            await this.$axios.delete(leave_balance_details_url, {

            }).then((response) => {
                if(response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.$router.go();
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error){
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateLeaveBalance: async function() {
            //console.log("cmployee Details is: ", this.employeeLeaveBalanceDetails);
            let cmp_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + this.cmp_id;
            await this.$axios.put(cmp_details_url, {
                employee_id: this.leave_balance_details.employee.emp_id,
                employee_code: this.leave_balance_details.employee.emp_code,
                leave_policy_id: this.leave_balance_details.leave_policy.id,
                leave_policy_name: this.leave_balance_details.leave_policy.name,
                leave_total_days: this.leave_balance_details.leave_total_days,
                leave_avail_days: this.leave_balance_details.leave_avail_days,
                leave_balance: this.leave_balance_details.leave_balance
                
            }).then((response) => {
                if(response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getLeaveBalanceData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {
                    }).then((response) => {
                        if(response.status == 200) {
                            //console.log("Data is", response.data);
                            this.dataList = response.data.data;
                            this.allDataList = response.data;
                        }
                        else {
                            this.isLoading = false
                            this.$swal.fire({
                                icon: "error",
                                text: "No Data Found!"
                            });
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });

        },
        editForm: async function(id) {
            this.cmp_id = id;
            let leave_balance_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + this.cmp_id;
            //console.log(leave_balance_details_url);
            await this.$axios.get(leave_balance_details_url, {
                    }).then((response) => {
                        if(response.status == 200) {
                            this.leave_balance_details.employee = {
                                emp_id: response.data.employee_id,
                                emp_code: response.data.employee_code
                            }
                            this.leave_balance_details.leave_policy= {
                                id: response.data.leave_policy_id,
                                name: response.data.leave_policy_name
                            }
                            this.leave_balance_details.leave_total_days = response.data.leave_total_days
                            this.leave_balance_details.leave_avail_days = response.data.leave_avail_days
                            this.leave_balance_details.leave_balance = response.data.leave_balance

                        }
                        else {
                          //console.log("In else");
                            this.isLoading = false
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
                    
  
          },
        createLeaveBalance: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint
           await this.$axios.post(url, {
                employee_id: this.employee.emp_id,
                employee_code: this.employee.emp_code,
                leave_policy_id: this.leave_policy.id,
                leave_policy_name: this.leave_policy.name,
                leave_total_days: this.leave_total_days,
                leave_avail_days: this.leave_avail_days,
                leave_balance: this.leave_balance

                    }).then((response) => {
                        if(response.status == 201) {
                            //console.log("leave_balance Created Successfully");
                            this.$toast.success(response.data.message);
                        }
                        else {
                            this.isLoading = false
                        }
                    }).catch(error => {
                        //console.log("Error is ", error.response)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
            // this.$router.go();
          },

    }
}