<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Salary</li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Generate
            </button>
            <!-- <button type="button" class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
								<a class="dropdown-item" href="javascript:;">Another action</a>
								<a class="dropdown-item" href="javascript:;">Something else here</a>
								<div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
							</div> -->
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Salary Info List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="example_length">
                  <label
                    >Show
                    <select
                      v-model="limit"
                      name="example_length"
                      aria-controls="example"
                      class="form-select form-select-sm mt-1"
                    >
                      <option
                        v-for="(option, key) in pageLimit"
                        :key="key"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div id="example_filter" class="dataTables_filter text-end">
                  <label class="text-start ms-2"
                    >Search:<input
                      type="search"
                      v-model="search"
                      class="form-control form-control-sm mt-1"
                      placeholder=""
                      aria-controls="example"
                    />
                  </label>
                  <button
                    type="button"
                    @click="searchData"
                    class="btn btn-primary px-4 radius-30 ms-2"
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee Code</th>
                  <!-- <th>Basic Salary</th> -->
                  <th>Gross Salary</th>
                  <th>Total Deduction</th>
                  <th>Net Payable</th>
                  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.emp_code }}</td>
                  <!-- <td>{{ li.basic_salary }}</td> -->
                  <td>{{ li.gross_salary }}</td>
                  <td>{{ li.total_cost_deducted}}</td>
                  <td>{{ li.net_payable }}</td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-success btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal2"
                      >
                        View
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-primary btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button> -->
                      <!-- <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
					<h3 class="text-info text-center">No Data Found</h3>
				</div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Generate Salary</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputEmployeeCode" class="form-label"
                      >Salary Issue Date<span style="color:red;font-size:18px">*</span></label
                    >
                    <input
                      type="date"
                      v-model="salary_issue_date"
                      class="form-control"
                      id="inputEmployeeCode"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label"
                      >Salary Issue Month<span style="color:red;font-size:18px">*</span></label
                    >
                    <select
                      v-model="salary_issue_month"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in months"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <button
                      type="button"
                      v-on:click="createSalary()"
                      data-bs-dismiss="modal"
                      class="btn btn-warning"
                    >
                      Generate
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Salary Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Employee Code</label>
                    <select
                      v-model="salary_details.employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Salary Code</label
                    >
                    <select
                      v-model="salary_details.salary"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in salaries"
                        :key="key"
                        :value="option"
                      >
                        {{ option.salary_code }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-md-4">
                    <label for="inputState" class="form-label">Tax</label>
                    <select
                      v-model="salary_details.tax"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in taxes"
                        :key="key"
                        :value="option"
                      >
                        {{ option.tax_code }}
                      </option>
                    </select>
                  </div> -->
                  <div class="col-md-4">
                    <label for="inputEmployeeCode" class="form-label"
                      >Basic Salary</label
                    >
                    <input
                      type="text"
                      v-model="salary_details.basic_salary"
                      class="form-control"
                      id="inputEmployeeCode"
                      placeholder="Enter Employee Code"
                    />
                  </div>

                  <div class="col-md-4">
                    <label for="inputEmployeeCode" class="form-label"
                      >Salary Issue Date</label
                    >
                    <input
                      type="date"
                      v-model="salary_details.salary_issue_date"
                      class="form-control"
                      id="inputEmployeeCode"
                      placeholder="Enter Employee Code"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Salary Issue Medium</label
                    >
                    <select
                      v-model="salary_details.salary_issue_medium"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in salary_issue_mediums"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateSalary()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update Salary
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal2"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">View Salary Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#salary"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Salary Details</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#account"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Account Details</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="salary"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Employee</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.employee.emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Salary Code</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.salary.salary_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <!-- <div class="col-md-4">
                        <label for="inputState" class="form-label">Tax</label>
                        <input
                          type="text"
                          v-model="salary_details.tax.tax_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div> -->
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Basic Salary</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.basic_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>

                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Gross Salary</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.gross_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Total Cost Deducted</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.total_cost_deducted"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Net Payable</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.net_payable"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Salary Issue Date</label
                        >
                        <input
                          type="date"
                          v-model="salary_details.salary_issue_date"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Salary Issue Medium</label
                        >
                        <input
                          type="text"
                          v-model="salary_details.salary_issue_medium.name"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade show" id="account" role="tabpanel">
                    <form class="row g-3" v-if="account_details != null">
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Employee</label
                        >
                        <input
                          type="text"
                          v-model="account_details.emp_code"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Bank Name</label
                        >
                        <input
                          type="text"
                          v-model="account_details.bank_name"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Branch Name</label
                        >
                        <input
                          type="text"
                          v-model="account_details.branch_name"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Account Type</label
                        >
                        <input
                          type="text"
                          v-model="account_details.account_type"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>

                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Account Number</label
                        >
                        <input
                          type="text"
                          v-model="account_details.account_number"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>

                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Routing Number</label
                        >
                        <input
                          type="text"
                          v-model="account_details.routing_number"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Swift Code</label
                        >
                        <input
                          type="text"
                          v-model="account_details.swift_code"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                    </form>
                    <div v-else>
                      <h3 class="text-center">No Account Details</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" v-on:click="updateEmployee()" data-bs-dismiss="modal" class="btn btn-primary">Update Employee</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteSalary()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout:fixed;background-color:#f9f9f9; font-family: 'Cambria Bold',serif;" id="bodyTable">
    <tbody>
        <tr>
            <td style="padding-right:10px;padding-left:10px;" align="center" valign="top" id="bodyCell">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapperBody" style="max-width:800px">
                    <tbody>
                        <tr>
                            <td align="center" valign="top">
                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tableCard" style="background-color:#fff;border-color:#e5e5e5;border-style:solid;border-width:0 1px 1px 1px; ">
                                    <tbody>
                                        <tr>
                                            <td style="background-color:#0089D0;font-size:1px;line-height:3px" class="topBorder" height="3">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-top: 60px; padding-left: 60px; padding-right: 60px; padding-bottom: 20px;" align="left" valign="middle" class="emailLogo">
                                                <a href="#" style="text-decoration:none" target="_blank">
                                                    <img alt="" border="0" src="../../../../../public/core/assets/images/hrconnect.jpg" style="width:100%;max-width:150px;height:auto;display:block" width="150">
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-bottom: 20px; padding-left: 60px; padding-right: 60px;" align="left" valign="top">
                                                <span style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>PRIVATE AND CONFIDENTIAL</b></span><br><br>
                                                <span style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: underline'><b>Pay Slip- For the Month of December -2016</b></span><br><br>
                                                <span style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Date: 09/01/2017</b></span><br>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-bottom: 20px; padding-left: 60px; padding-right: 60px;" align="left" valign="top">
                                                <span style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Name: Md.Mahamudur Zaman Bhuyan</b></span><br><br>
                                                <span style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Designation: Sr. Software Engineer</b></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding-left:20px;padding-right:20px; padding-left: 60px;padding-right: 60px; " align="center" valign="top" class="containtTable ui-sortable">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="tableButton">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding-top:20px;padding-bottom:20px" align="left" valign="top">
                                                                <table border="0" cellpadding="5" cellspacing="0" align="left" style="border-collapse: collapse;width: 100%; border-color:#09c1dd ;">
                                                                    <tbody>
                                                                        <tr align="center" style="border: 2px solid #0089D0;border-left: 2px solid #0089D0;">
                                                                            <td style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Payments</b></td>
                                                                            <td style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>TK.</b></td>
                                                                            <td style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Deductions</b></td>
                                                                            <td style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>TK.</b></td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 1px solid #fff;border-left: 2px solid #0089D0;">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Basic</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Advance (If any)</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>625</td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 1px solid #fff;border-left: 2px solid #0089D0;">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>House Rent</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Lunch Contribution</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>625</td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 1px solid #fff;border-left: 2px solid #0089D0;">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>T. A</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Leave W/O Pay</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>625</td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 1px solid #fff;border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>D. A</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Deduction for Mobile Bills</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>625</td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 1px solid #fff;border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Medical Allow</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>PF (Employee + Comp.) </td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>625</td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Mobile Allow</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'></td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Festival Bonus</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'>50,000</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-style:normal;text-decoration: none'>Others (Please see comments)</td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 2px solid #0089D0;border-left: 2px solid #0089D0">
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>Total:</b></td>
                                                                            <td bgcolor="#fff" style='border-right: 2px solid #0089D0;border-top:2px solid #000;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>71,500.00/=</b></td>
                                                                            <td style='border-right: 2px solid #0089D0;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'></td>
                                                                            <td bgcolor="#fff" style='border-right: 2px solid #0089D0;border-top:2px solid #000;font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none'><b>71,500.00/=</b></td>
                                                                        </tr>
                                                                        <tr bgcolor="#dde0e0" style="border-bottom: 2px solid #0089D0;">
                                                                            <td colspan="4" align="center"><b>Net Payment Transfer: TK. 69,875.00/=</b></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size:1px;line-height:1px" height="20">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="padding-bottom: 20px; padding-left: 60px; padding-right: 60px;" align="left" valign="top">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="space">
                                                    <tbody>
                                                        <tr>
                                                            <td style='font-family:"Cambria Bold",serif;font-size:14px;color:rgb(0,0,0);font-weight:bold;font-style:normal;text-decoration: none' height="30">N.B: Computer generated pay slip; no signature required</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </td>
        </tr>
    </tbody>
</table> -->
</template>
<script src="../js/salary.js"></script>