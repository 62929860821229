<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee Leave Requests
              </li>
            </ol>
          </nav>
        </div>
        <!-- <div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Apply Leave Application</button>
						</div>
					</div> -->
      </div>
      <!--end breadcrumb-->

      <div v-if="showSuccessMessage">
        <p>Leave Approved Successfully. You may now close this window/tab.</p>
      </div>
    </div>

  </div>
</template>
<script src="../js/leave_request_mail.js"></script>