import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            city_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            city_name: '',
            city_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.configurations.cityAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let city_url = this.$api + this.$config.configurations.cityAPIEndPoint + params;
        this.getDataList(city_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let city_url = this.$api + this.$config.configurations.cityAPIEndPoint + params;
            await this.getDataList(city_url);
        },
        deleteInfo: async function(id) {
            this.city_id = id;
        },
        deleteCity: async function() {
            let city_details_url = this.$api + this.$config.configurations.cityDetails + this.city_id;
            this.$axios.delete(city_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let city_url = this.$api + this.$config.configurations.cityAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(city_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateCity: async function() {
            //console.log("City Details is: ", this.city_details);
            let city_details_url = this.$api + this.$config.configurations.cityDetails + this.city_id;
            this.$axios.put(city_details_url, {
                city_name: this.city_details.city_name

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let city_url = this.$api + this.$config.configurations.cityAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(city_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getCityData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            this.$axios.get(emp_url, {}).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.city_id = id;
            let city_details_url = this.$api + this.$config.configurations.cityDetails + this.city_id;
            //console.log(city_details_url);
            this.$axios.get(city_details_url, {}).then(async(response) => {
                if (response.status == 200) {
                    this.city_details.city_name = response.data.city_name;

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createCity: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.cityAPIEndPoint
            this.$axios.post(url, {
                city_name: this.city_name,
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("city Created Successfully");
                    this.$toast.success(response.data.message);
                    let city_url = this.$api + this.$config.configurations.cityAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(city_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}