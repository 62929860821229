import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            employee: {},
            start_date: '',
            end_date: ''
        }
    },
    mounted() {
        this.getEmployees();
    },
    created() {
        document.title = "HrConnect - Reporting";
    },
    methods: {
        generateEmployeeAttendance: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.reporting.employeeAttendance + "?emp_code=" + this.employee.emp_code + "&start_date=" + this.start_date + "&end_date=" + this.end_date;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
            let filename = this.employee.emp_name.replace(/ /g, "") + '_attendance.csv';
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        generateTodayAttendance: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.reporting.employeeTodayAttendance;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Response: ",response.data);
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    //console.log("FileLink: ",fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'employee_attendances.csv');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        generateLateAttendance: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.reporting.employeeLateAttendance;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Response: ",response.data);
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    //console.log("FileLink: ",fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'employee_late_attendances.csv');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        generateEarlyAttendance: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.reporting.employeeEarlyAttendance;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
           await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Response: ",response.data);
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    //console.log("FileLink: ",fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'employee_early_attendances.csv');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        generateOvertime: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.reporting.employeeOvertime;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Response: ",response.data);
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    //console.log("FileLink: ",fileLink);
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'employee_overtime.csv');
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

    }
}