<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create Employee
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
          <div class="btn-group">
              <a href="/emp-bulk-upload.xlsx" class="btn btn-primary">Simple file</a>
              <input class="form-control btn btn-success" id="fileUpload" type="file" @change="uploadFile" name="files" accept=".csv, .xlsx" ref="inputFile" uploader="uploader" single hidden>
              <button type="button" class="btn btn-success" @click="chooseFile">
                  Bulk Upload
              </button>
          </div>
      </div>


      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee List</h6>
      <hr />
      <div class="card" v-if="Object.keys(dataList).length > 0">
        <div class="card-body">
          
            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="example_length">
                  <label
                    >Show
                    <select
                      v-model="limit"
                      name="example_length"
                      aria-controls="example"
                      class="form-select form-select-sm mt-1"
                    >
                      <option
                        v-for="(option, key) in pageLimit"
                        :key="key"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div id="example_filter" class="dataTables_filter text-end">
                  <label class="text-start ms-2"
                    >Search:<input
                      type="search"
                      v-model="search"
                      class="form-control form-control-sm mt-1"
                      placeholder=""
                      aria-controls="example"
                    />
                  </label>
                  <button
                    @click="searchData"
                    type="button"
                    class="btn btn-primary px-4 radius-30 ms-2"
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <!-- <th>Employee Code</th> -->
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              
              <tbody v-if="Object.keys(dataList).length > 0">
                <tr v-for="(li, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.first_name }}&nbsp;{{ li.last_name }}</td>
                  <td>{{ li.email }}</td>
                  <td>{{ li.phone }}</td>
                  <td>{{ li.designation_name }}</td>
                  <td>
                    <div class="col">
                      
                      <button
                        type="button"
                        class="btn btn-primary btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
            <v-pagination
              v-model="page"
              :pages="total_pages"
              :range-size="1"
              active-color="#037de2"
              @update:modelValue="updateHandler"
              :prevText="'Prev'"
              :nextText="'Next'"
              :active-class="myActiveBtn"
              :containerClass="'pagination'"
              class="pagination col-md-12"
            />
          </div>
          
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Creation</h5>
            <button
              type="button" @click="closeAddEmployee"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#primaryhome"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Employee</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#primaryprofile"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Other Documents</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#payrollInfo"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Employee Payroll Info</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="primaryhome"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="firstname"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="lastname"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Email<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="inputEmail"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="phone"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          required
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Country<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="country"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in countries"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >City<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="city"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in cities"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Gender<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="gender"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in genders"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Blood Group<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="blood_group"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in blood_groups"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Marital Status<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="marital_status"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in marital_statuses"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Company<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="company"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in companies"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Branch<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="branch"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in branches"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Department<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="department"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in departments"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Section<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="section"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in sections"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Role<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="role"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in roles"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="designation"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in designations"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee Code<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee Code"
                          required
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeDate" class="form-label"
                          >Date of Joining<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="date"
                          v-model="date_of_joining"
                          class="form-control"
                          id="inputEmployeeDate"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Employee Image<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="file"
                          @change="uploadImage"
                          name="files"
                          accept=".jpg, .png, .jpeg"
                          ref="uploadEmpImg"
                          uploader="uploader"
                          single
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="primaryprofile"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <!-- <div class="col-md-4">
										<label for="inputState" class="form-label">Employee Code</label>
										<select v-model="emp_code" id="inputState" class="form-select single-select">
											<option :key="key" :value="emp_code">{{emp_code}}</option>
										</select>
									</div> -->
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Certificates</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="file"
                          @change="uploadCertificates"
                          name="files"
                          accept=".jpg, .png, .pdf"
                          ref="uploadFileCertificates"
                          uploader="uploader"
                          multiple
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Verifications</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple2"
                          type="file"
                          @change="uploadVerifications"
                          name="files"
                          accept=".jpg, .png, .pdf"
                          ref="uploadFileVerifications"
                          uploader="uploader"
                          multiple
                        />
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="payrollInfo" role="tabpanel">
                    <form class="row g-3">
                      <!-- <div class="col-md-4">
										<label for="inputState" class="form-label">Employee Code</label>
										<select v-model="emp_code" id="inputState" class="form-select single-select">
											<option :key="key" :value="emp_code">{{emp_code}}</option>
										</select>
									</div> -->
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Salary Grade</label
                        >
                        <select
                          v-model="salary_grade"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in salary_grades"
                            :key="key"
                            :value="option"
                          >
                            {{ option.salary_code }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Salary Issue Medium</label
                        >
                        <select
                          v-model="salary_issue_medium"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in salary_issue_mediums"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Basic Salary</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.basic_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Health Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.health_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Housing Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.housing_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Transport Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.transport_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              v-on:click="createEmployee()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create Employee
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Employee Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputFirstName" class="form-label"
                      >First Name</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.first_name"
                      class="form-control"
                      id="inputFirstName"
                      placeholder="Enter Firstname"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputLastName" class="form-label"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.last_name"
                      class="form-control"
                      id="inputLastName"
                      placeholder="Enter Lastname"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input
                      type="email"
                      v-model="emp_details.email"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Phone Number</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.phone"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Country</label>
                    <select
                      v-model="emp_details.country"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in countries"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">City</label>
                    <select
                      v-model="emp_details.city"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in cities"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Gender</label>
                    <select
                      v-model="emp_details.gender"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in genders"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Blood Group</label
                    >
                    <select
                      v-model="emp_details.blood_group"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in blood_groups"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Marital Status</label
                    >
                    <select
                      v-model="emp_details.marital_status"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in marital_statuses"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Company</label>
                    <select
                      v-model="emp_details.company"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in companies"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Branch</label>
                    <select
                      v-model="emp_details.branch"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in branches"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Department</label
                    >
                    <select
                      v-model="emp_details.department"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in departments"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Section</label>
                    <select
                      v-model="emp_details.section"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in sections"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Role</label>
                    <select
                      v-model="emp_details.role"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in roles"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Designation</label
                    >
                    <select
                      v-model="emp_details.designation"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in designations"
                        :key="key"
                        :value="option"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputEmployeeCode" class="form-label"
                      >Employee Code</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.emp_code"
                      class="form-control"
                      id="inputEmployeeCode"
                      placeholder="Enter Employee Code"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputEmployeeDate" class="form-label"
                      >Date of Joining</label
                    >
                    <input
                      type="date"
                      v-model="emp_details.date_of_joining"
                      class="form-control"
                      id="inputEmployeeDate"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >Employee Image</label
                    >
                    <input
                      class="form-control"
                      id="formFileMultiple1"
                      type="file"
                      @change="updateImage"
                      name="files"
                      accept=".jpg, .png, .jpeg"
                      ref="inputFile"
                      uploader="uploader"
                      single
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateEmployee()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Stuck Off -->
    <div
      class="modal fade"
      id="exampleLargeModal3"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Stuck Off</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-info">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputLastName" class="form-label"
                      >Stuck Off Date</label
                    >
                    <input
                      type="date"
                      v-model="stuck_off_date"
                      class="form-control"
                      id="stuck_off"
                      placeholder="Enter Date"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-dark btn-block px-3 mt-2 ms-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleVerticallycenteredModal2"
            >
              Stuck Off
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal2"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Employee Creation</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#leaveApplication"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Employee Details</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#leaveHistory"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Other Documents</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="leaveApplication"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.first_name"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.last_name"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label">Email</label>
                        <input
                          type="email"
                          v-model="emp_details.email"
                          class="form-control"
                          id="inputEmail"
                          placeholder="Enter Email"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.phone"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Country</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.country.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label">City</label>
                        <input
                          type="text"
                          v-model="emp_details.city.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Gender</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.gender.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Blood Group</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.blood_group.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Marital Status</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.marital_status.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="inputState" class="form-label"
                          >Company</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.company.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Branch</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.branch.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Department</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.department.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Section</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.section.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Role</label>
                        <input
                          type="text"
                          v-model="emp_details.role.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.designation.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee Code</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee Code"
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="leaveHistory" role="tabpanel">
                    <div class="table-responsive overflow-hidden">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Sl. No</th>
                            <th>Employee Name</th>
                            <th>Files</th>
                          </tr>
                        </thead>
                        <tbody v-if="Object.keys(certificates).length > 0">
                          <tr v-for="(li, index) in certificates" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ li.emp_name }}</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  <i
                                    class="
                                      bx
                                      bxs-file
                                      me-2
                                      font-24
                                      text-primary
                                    "
                                  ></i>
                                </div>
                                <div class="font-weight-bold text-primary">
                                  <a
                                    :href="li.file"
                                    v-text="li.filename"
                                    @click.prevent="downloadFile(li.file)"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" v-on:click="updateEmployee()" data-bs-dismiss="modal" class="btn btn-primary">Update Employee</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Stuck Off Confirm -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal2"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createStuckOff()"
              data-bs-dismiss="modal"
              class="btn btn-success"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteEmployee()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/bulkEmployee.js"></script>