import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            grade_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            housing_allowance: '',
            transport_allowance: '',
            health_allowance: '',
            salary_code: '',
            bonus_type: {},
            bonus_types: [],
            basic_salary: '',
            salary_grade_details: {
                salary_code: '',
                health_salary: '',
                transport_salary: '',
                housing_salary: '',
                bonus_type: {
                    id: null,
                    name: ''
                }
            }
        }

    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + params;
        this.getDataList(salary_grade_url);

        this.bonus_types = [{
                id: 0,
                name: "Percentage"
            },
            {
                id: 1,
                name: "Amount"
            }
        ];

    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + params;
        await this.getDataList(salary_grade_url);
        },
        deleteInfo: function(id) {
            this.grade_id = id;
        },
        deleteSalaryGrade: async function() {
            this.isLoading = true
            let salary_grade_details_url = this.$api + this.$config.payroll.salaryGradeDetails + this.grade_id;
           await this.$axios.delete(salary_grade_details_url, {}).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await  this.getDataList(salary_grade_url);

                } else {
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateSalaryGrade: async function() {
            this.isLoading = true
            let grade_details_url = this.$api + this.$config.payroll.salaryGradeDetails + this.grade_id;
            let grade_data = {
                basic_salary: this.salary_grade_details.basic_salary,
                salary_code: this.salary_grade_details.salary_code,
                health_salary: this.salary_grade_details.health_allowance,
                transport_salary: this.salary_grade_details.transport_allowance,
                housing_salary: this.salary_grade_details.housing_allowance,
                bonus_calculation_type: this.salary_grade_details.bonus_type.id
            }
            let config = {
                url: grade_details_url,
                method: "PUT",
                data: grade_data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(salary_grade_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        editForm: async function(id) {
            this.grade_id = id;
            let salary_grade_details_url = this.$api + this.$config.payroll.salaryGradeDetails + this.grade_id;
            //console.log(salary_grade_details_url);
            let config = {
                url: salary_grade_details_url,
                method: "GET"
            }
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.salary_grade_details.basic_salary = response.data.basic_salary;
                    this.salary_grade_details.salary_code = response.data.salary_code;
                    this.salary_grade_details.health_allowance = response.data.health_salary;
                    this.salary_grade_details.transport_allowance = response.data.transport_salary;
                    this.salary_grade_details.housing_allowance = response.data.housing_salary;

                    if (response.data.bonus_calculation_type == 0) {
                        this.salary_grade_details.bonus_type = {
                            id: 0,
                            name: "Percentage"

                        }
                    } else if (response.data.bonus_calculation_type == 1) {
                        this.salary_grade_details.bonus_type = {
                            id: 1,
                            name: "Amount"

                        }
                    }
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createSalaryGrade: async function() {
            this.isLoading = true
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint
            let salary_grade_data = {
                basic_salary: this.basic_salary,
                salary_code: this.salary_code,
                health_salary: this.health_allowance,
                transport_salary: this.transport_allowance,
                housing_salary: this.housing_allowance,
                bonus_calculation_type: this.bonus_type.id

            }
            let config = {
                url: url,
                method: "POST",
                data: salary_grade_data
            }

            await this.postDataToBackend(config);
            this.isLoading = false
            this.housing_allowance = '';
            this.transport_allowance = '';
            this.health_allowance = '';
            this.salary_code = '';
            this.basic_salary = '';
            this.bonus_type = {};
            let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(salary_grade_url);
        },

    }
}