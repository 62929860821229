import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { authHeader } from "../../../../auth";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            certificate_id: null,
            page: 1,
            limit: 10,
            total_pages: 5,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            certificate_name: '',
            employees: [],
            employee: {},
            selectedFiles: undefined,
            certificate_details: {
                employee: {}
            }
        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        this.setURL = this.$config.configurations.certificateAPIEndpoint;
        let certificateurl = this.$api + this.$config.configurations.certificateAPIEndPoint + params;
        
        this.getCertificateList(certificateurl);
        let employee_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        let config = {
            method: "GET",
            url: employee_url,
            headers: authHeader()
        }
        this.$axios(config).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
                //console.log("Employees are: ", this.employees)
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Certificate";
    },
    methods: {
        getCertificateList: async function(url) {
            let configCertificate = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            await this.$axios(configCertificate).then((response) => {
                if (response.status == 200) {
                    this.total_pages = response.data.last_page;
                    // //console.log("Data is", response.data);
                    for (const key in Object.keys(response.data.data)) {
                        this.dataList.push({
                            id: response.data.data[key].id,
                            emp_code: response.data.data[key].emp_id,
                            emp_name: response.data.data[key].emp_name,
                            file: response.data.data[key].file,
                            filename: response.data.data[key].file.split('/')[5]
                        });
                    }
                    
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        downloadFile: async function(url) {
            //console.log("Url is: ", this.$backend + url)
            let new_url = this.$backend + url;
            let new_label = url.split('/')[5].split(".")[0];
            // let data_type = url.split('/')[5].split(".")[1];
            //console.log("data type: ", data_type);
            await this.$axios.get(new_url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        deleteInfo: function(id) {
            this.certificate_id = id;
        },
        uploadFiles: function(event) {
            this.selectedFiles = event.target.files;
            //console.log("Selected Files: ", this.selectedFiles);
        },
        deleteCertificate: async function() {
            let certificate_details_url = this.$api + this.$config.configurations.certificateDetails + this.certificate_id;
            await this.$axios.delete(certificate_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let certificateurl = this.$api + this.$config.configurations.certificateAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getCertificateList(certificateurl);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateCertificate: async function() {
            //console.log("designation Details is: ", this.certificate_details);
            let certificate_details_url = this.$api + this.$config.configurations.certificateDetails + this.certificate_id;
            const formData = new FormData();
            let siteConfig = {};
            //console.log("Employee Data: ", this.employee);
            formData.append("emp_id", this.certificate_details.employee.emp_id);
            formData.append("emp_code", this.certificate_details.employee.emp_code);
            formData.append("emp_name", this.certificate_details.employee.emp_name);
            for (let i = 0; i < this.certificate_details.selectedFiles.length; i++) {
                formData.append("file", this.selectedFiles[i]);
            }

            //console.log("URL is", certificate_details_url);

            siteConfig = {
                method: "PUT",
                url: certificate_details_url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.$axios(siteConfig).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let certificateurl = this.$api + this.$config.configurations.certificateAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getCertificateList(certificateurl);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getCertificateData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.certificate_id = id;
            let certificate_details_url = this.$api + this.$config.configurations.certificateDetails + this.certificate_id;
            //console.log(certificate_details_url);
            await this.$axios.get(certificate_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.certificate_details.employee = {
                            emp_id: response.data.emp_id,
                            emp_code: response.data.emp_code,
                            emp_name: response.data.emp_name
                        },
                        this.certificate_details.selectedFiles = response.data.file
                    //console.log("Single employee: ", this.certificate_details.employee);

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false;
                this.$toast.error(error.response.data.message);
            });
            //console.log("Employee Certificate: ", this.certificate_details);

        },
        createCertificate: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.certificateAPIEndPoint
            const formData = new FormData();
            let siteConfig = {};
            //console.log("Employee Data: ", this.employee);
            formData.append("emp_id", this.employee.emp_id);
            formData.append("emp_code", this.employee.emp_code);
            formData.append("emp_name", this.employee.emp_name);
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append("file", this.selectedFiles[i]);
            }

            //console.log("URL is", url);

            siteConfig = {
                method: "POST",
                url: url,
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.$axios(siteConfig

            ).then(async(response) => {
                if (response.status == 201) {
                    //console.log("Certificate Created Successfully");
                    this.$toast.success(response.data.message);
                    let certificateurl = this.$api + this.$config.configurations.certificateAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getCertificateList(certificateurl);
                    this.employee = '';
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let certificateurl = this.$api + this.$config.configurations.certificateAPIEndPoint + "?page=" + this.page + params;
            await this.getCertificateList(certificateurl);
            
        }

    }
}