<template>
    <div class="page-wrapper">
      <div class="page-content">
      <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
            <div class="card overflow-hidden radius-10">
              <div class="profile-cover bg-dark position-relative mb-4">
                  <div class="
                    user-profile-avatar
                    shadow
                    position-absolute
                    top-50
                    start-0
                    translate-middle-x
                    ">
                    <img :src="backend_url + userInfo.image" class="user-img" v-if="userInfo.image">
                    <img src="/user.png" class="user-img" v-else>
                  </div>
              </div>
              <div class="card-body">
                  <div class="
                    mt-5
                    d-flex
                    flex-column flex-sm-row
                    align-items-start
                    justify-content-between
                    gap-3 gap-sm-0
                    ">
                    <div class="">
                        <h3 class="mb-2">
                          {{ userInfo.first_name + " " + userInfo.last_name}}
                        </h3>
                        <p class="mb-1">
                          <strong>Email:</strong>
                          {{ userInfo.email }}
                        </p>
                    </div>
                    <div class="">
                       
                        <router-link to="/change-password" class="btn btn_primary"><i class="bx bx-key"></i>Change Password</router-link>
                    </div>
                  </div>
              </div>
            </div>
            <div class="card radius-10">
              <div class="card-body">
                             
                    <h5 class="mb-3">Edit Profile</h5>
                    <h5 class="mb-0 mt-4">User Information</h5>
                    <hr>
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.first_name"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.last_name"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.phone"
                          class="form-control"
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Emergency Contact Number</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.emergency_number"
                          class="form-control"
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Country</label>
                        <input
                          type="text"
                          v-model="userInfo.country_name"
                          class="form-control"
                          id="inputPhone" disabled
                        />
                        
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">City</label>
                        <input
                          type="text"
                          v-model="userInfo.city_name"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Gender</label>
                        <input
                          type="text"
                          v-model="userInfo.gender_title"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Blood Group</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.blood_group_title"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Marital Status</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.marital_status"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Company</label>
                        <input
                          type="text"
                          v-model="userInfo.company_name"
                          class="form-control" disabled
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Branch</label>
                        <input
                          type="text"
                          v-model="userInfo.branch_name" disabled
                          class="form-control"
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Department</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.department_name"
                          class="form-control" disabled
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Section</label>
                        <input
                          type="text" disabled
                          v-model="userInfo.section_name"
                          class="form-control"
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Role</label>
                        <input
                          type="text" disabled
                          v-model="userInfo.role_name"
                          class="form-control"
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.designation_name"
                          class="form-control" disabled
                          id="inputPhone"
                                                  />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee Code</label
                        >
                        <input
                          type="text"
                          v-model="userInfo.emp_code"
                          class="form-control"
                          id="inputEmployeeCode" disabled
                          placeholder="Enter Employee Code"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeDate" class="form-label"
                          >Date of Joining</label
                        >
                        <input
                          type="date"
                          v-model="userInfo.date_of_joining" disabled
                          class="form-control"
                          id="inputEmployeeDate"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Employee Image</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="file"
                          @change="updateImage"
                          name="files"
                          accept=".jpg, .png, .jpeg"
                          ref="inputFile"
                          uploader="uploader"
                          single
                        />
                      </div>
                    </div>
                    <div class="text-start mt-3">
                        <button type="submit" class="btn btn_primary px-4" v-on:click="updateEmployee()">
                        Save Changes
                        </button>
                    </div>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  <script src="../js/profile.js"></script>

<style>
  .radius-10 {
    border-radius: 10px !important;
}

.profile-cover {
    background-image: url('/banner.jpg');
    background-size: cover;
    height: 10rem;
    background-position: center;
}
.user-profile-avatar {
    background-color: #ffffff;
    width: 160px;
    height: 160px;
    padding: 5px;
    border-radius: 50%;
    margin-left: 6.5rem;
}
.user-profile-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.btn_primary {
    color: #fff;
    background-color: #037de2;
    border-color: #037de2;
}
.btn_primary:hover {
    color: #fff;
    background-color: #8436a8;
    border-color: #8436a8;
}
.btn_primary:focus {
    color: #fff;
    background-color: #8436a8;
    border-color: #8436a8;
    box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%);
}
.btn_outline_primary {
    color: #923eb9;
    border-color: #923eb9;
}
.btn_outline_primary:hover {
    color: #fff;
    background-color: #923eb9;
    border-color: #923eb9;
}
.btn_outline_primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(84 55 145 / 50%);
}
.user-change-photo {
    background-color: #ffffff;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    padding: 5px;
}
.user-change-photo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>