<template>
    <div class="page-wrapper">
      <div class="page-content">
      <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <div class="container">
        <div class="main-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                  <div class="card-title d-flex align-items-center">
                    <div>
                        <i class="fadeIn animated bx bx-key"></i>
                    </div>
                    <h5 class="mb-0 text-primary"> Change Password</h5>
                  </div>
                  <hr>   
                  <div class="row">
                      <div class="col-lg-8 mt-4 mx-auto">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                              <h6 class="mb-0">Old Password</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                              <input type="password" class="form-control" v-model="old_password">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                              <h6 class="mb-0">New Password</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                              <input type="password" class="form-control" v-model="password">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                              <h6 class="mb-0">Confirm Password</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                              <input type="password" class="form-control" v-model="password_confirmation">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9 text-secondary">
                              <input type="submit" class="btn btn-primary px-4" value="Update Password" @click="updatePassword">
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  <script src="../js/changePassword.js"></script>