import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            email: null,
            password: null,
            ConPassword: null,
            inputType: "password",
            iconFlag: true,
            inputTypeCon: "password",
            iconFlagCon: true
        }
    },
    created() {
        document.title = "HR Connect - Forgot Password";
    },
    methods: {
        changeInputType: function() {
            if (this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            } else if (this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        },
        changeInputTypeCon: function() {
            if (this.inputTypeCon == "password") {
                this.inputTypeCon = "text";
                this.iconFlagCon = false;
            } else if (this.inputTypeCon == "text") {
                this.inputTypeCon = "password";
                this.iconFlagCon = true;
            }
        },
        submit: async function() {
            this.isLoading = true;
            if (!this.password) {
                this.$toast.error("Please enter your password!");
                this.isLoading = false;
            } else if (!this.conPassword) {
                this.$toast.error("Please enter your confirm password!");
                this.isLoading = false;
            } else if (this.password != this.conPassword) {
                this.isLoading = false;
                this.$toast.error("Password & Confirm Password does not match!");
            } else if (this.password.length < 6) {
                this.isLoading = false;
                this.$toast.error("You have to enter at least 6 digit!");
            }
            else {
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.$api + this.$config.auth.forgotPassword,
                    data: data
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$router.push("/");                       
                    } else {
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
            }
        },
    }
}