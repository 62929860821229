import Loading from "vue-loading-overlay";
import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components: {
        Loading,
        VueTimepicker,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            policy_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search:'',
            id: null,
            employee_shift_details: {
                employee: {
                    emp_code:'',
                    emp_name:''
                },
                shift_policy: {
                    id: '',
                    policy_name:''
                }
            },
            shift_policy: {},
            employee: {},
            applicable_to_all: false,
            shift_policies: []
        }
    },
    watch: {
        applicable_to_all: async function() {
            if(this.applicable_to_all) {
                this.employee = {};
            }
        }
    },
    async mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let shift_policy_url = this.$api + this.$config.attendance.employeeShiftPolicyAPI + params;
        this.setURL = shift_policy_url;
        this.postURL = this.$api + this.$config.attendance.employeeShiftPolicyAPI;
        await this.getDataList(shift_policy_url);
        await this.getEmployees();
        await this.getShiftPolicies();
        
        
    },
    created() {
        document.title = "HrConnect - Attendance Management";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let shift_policy_url = this.$api + this.$config.attendance.employeeShiftPolicyAPI + params;
            await this.getDataList(shift_policy_url);
        },
        deleteInfo: async function(id) {
            this.policy_id = id;
        },
        getShiftPolicies: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let shift_policies = this.$api + this.$config.attendance.shiftPolicyAPI + params;
            let config = {
                method: "GET",
                url: shift_policies
            }
            await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    // //console.log("Data is", response.data);
                    for(const key in Object.keys(response.data.data)){
                        this.shift_policies.push({
                            id: response.data.data[key].id,
                            policy_name: response.data.data[key].policy_name
                        })
                    }
                    
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        deleteEmployeeShiftPolicy: async function() {
            let shift_employee_shift_details_url = this.$api + this.$config.attendance.employeeShiftPolicyDetails + this.policy_id;
            await this.$axios.delete(shift_employee_shift_details_url, {

            }).then(async(response) => {
                if(response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.getDataList(this.setURL);
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                    
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateEmployeeShiftPolicy: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.ShiftPolicyDetails);
            let employee_shift_details_url = this.$api + this.$config.attendance.employeeShiftPolicyDetails + this.policy_id;
            let data = {
                emp_code: this.employee_shift_details.employee.emp_code,
                emp_name: this.employee_shift_details.employee.emp_name,
                shift_policy_id: this.employee_shift_details.shift_policy.id,
                shift_policy_name: this.employee_shift_details.shift_policy.policy_name
            };
            let config = {
                method: "PUT",
                data: data,
                url: employee_shift_details_url
            };            
            await this.$axios(config).then(async(response) => {
                if(response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    await this.getDataList(this.setURL)
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        editForm: async function(id) {
            this.policy_id = id;
            let employee_shift_details_url = this.$api + this.$config.attendance.employeeShiftPolicyDetails + this.policy_id;
            //console.log(employee_shift_details_url);
            await this.$axios.get(employee_shift_details_url, {
                    }).then(async(response) => {
                        if(response.status == 200) {
                            this.employee_shift_details.employee = {
                                emp_code: response.data.emp_code,
                                emp_name: response.data.emp_name
                            };
                            this.employee_shift_details.shift_policy = {
                                id: response.data.shift_policy_id,
                                policy_name: response.data.shift_policy_name,
                            }

                        }
                        else {
                          //console.log("In else");
                            this.isLoading = false
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
                    
  
          },
          createEmployeeShiftPolicy: async function() {
            let data = {
                emp_code: this.employee.emp_code,
                emp_name: this.employee.emp_name,
                shift_policy_id: this.shift_policy.id,
                shift_policy_name: this.shift_policy.policy_name,
                is_applicable_for_all: this.applicable_to_all
            };
            let config = {
                method: "POST",
                url: this.postURL,
                data: data
            };
            await this.postDataToBackend(config);
            await this.getDataList(this.setURL);
          },
    }
}