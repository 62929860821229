import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            menu_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            role: {},
            roles: [],
            check: [],
            selectedItem: [],
            pages: []
        }
    },
    mounted() {

        let role_pages_url = this.$api + this.$config.role.roleAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        let configRole = {
            method: "GET",
            url: role_pages_url,
            headers: authHeader()
        }
        this.$axios(configRole).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.roles.push({
                        id: response.data.data[key].id,
                        name: response.data.data[key].title,
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint;
        let configMenu = {
            method: "GET",
            url: menu_pages_url,
            headers: authHeader()
        }
        this.$axios(configMenu).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.pages.push({
                        page_name: response.data[key].page_name,
                        page_id: response.data[key].page_id,
                        page_url: response.data[key].page_url,
                        child_pages: response.data[key].child_pages,
                    });
                    //console.log("Pages are: ", this.pages)
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });



    },

    created() {
        document.title = "HrConnect - Role";
    },
    methods: {
       
        addMenu: function(page, child, event) {
            if (event.target.checked) {
                for (let i = 0; i < this.pages.length; i++) {
                    if (this.pages[i].page_id == page.page_id) {
                        this.pages[i].selected = true
                    }

                }
            } else {
                for (let i = 0; i < this.pages.length; i++) {
                    if (page.page_id == this.pages[i].page_id) {
                        for (let j = 0; j < this.pages[i].child_pages.length; j++) {
                            if (this.pages[i].child_pages[j].page_id == child.page_id) {
                                this.pages[i].child_pages[j].selected = false
                            }
                        }
                    }

                }
            }

        },
        addChild: function(page, event) {
            if (event.target.checked) {
                for (let i = 0; i < this.pages.length; i++) {
                    if (page.page_id == this.pages[i].page_id) {
                        this.pages[i].selected = true;
                        for (let j = 0; j < this.pages[i].child_pages.length; j++) {
                            this.pages[i].child_pages[j].selected = true
                        }
                    }

                }
            } else {
                for (let i = 0; i < this.pages.length; i++) {
                    if (page.page_id == this.pages[i].page_id) {
                        this.pages[i].selected = false;
                        for (let j = 0; j < this.pages[i].child_pages.length; j++) {
                            this.pages[i].child_pages[j].selected = false
                        }
                    }

                }
            }

        },
        deleteInfo: function(id) {
            this.menu_id = id;
        },
        deleteMenuPages: async function() {
            this.isLoading = true
            let menu_pages_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            await this.$axios.delete(menu_pages_details_url, {

            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(menu_pages_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateMenuPages: async function() {
            this.isLoading = true
            let menu_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            let data = {
                page_name: this.menu_page_details.page_name,
                page_url: this.menu_page_details.page_url,
                page_parent_id: this.menu_page_details.parent_page.id,
                parent_page_name: this.menu_page_details.parent_page.name
            }
            let config = {
                method: "PUT",
                data: data,
                url: menu_details_url
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(menu_pages_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        editForm: async function(id) {
            this.menu_id = id;
            let menu_page_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            await this.$axios.get(menu_page_details_url).then((response) => {
                if (response.status == 200) {
                    this.menu_page_details.parent_page = {
                        id: response.data.page_parent_id,
                        name: response.data.parent_page_name
                    }
                    this.menu_page_details.page_name = response.data.page_name
                    this.menu_page_details.page_url = response.data.page_url

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            //console.log("Parent Page details: ", this.menu_page_details.parent_page);


        },
        addPages: async function() {
            this.isLoading = true
            console.log("Parent Page details: ", this.role)
            for (let i = 0; i < this.pages.length; i++) {
                if (this.pages[i].selected == true) {
                    this.selectedItem.push({
                        role_id: 1,//this.role.id,
                        role_name: 'admin',//this.role.name,
                        page_id: this.pages[i].page_id,
                        page_name: this.pages[i].page_name,
                        page_url: this.pages[i].page_url
                    })
                    for (let j = 0; j < this.pages[i].child_pages.length; j++) {
                        if (this.pages[i].child_pages[j].selected == true) {
                            this.selectedItem.push({
                                role_id: 1,
                                role_name: 'admin',
                                page_id: this.pages[i].child_pages[j].page_id,
                                page_name: this.pages[i].child_pages[j].page_name,
                                page_url: this.pages[i].child_pages[j].page_url
                            })
                        }
                    }
                }

            }
            let url = this.$api + this.$config.role.rolePagesAPIEndPoint
                // let menu_data = {
                //     page_name: this.page_name,
                //     page_url: this.page_url,
                //     page_parent_id: this.parent_page.id,
                //     parent_page_name: this.parent_page.name
                // }

            let config = {
                method: "POST",
                data: this.selectedItem,
                url: url
            }
            await this.postDataToBackend(config);
            this.isLoading = false
            let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(menu_pages_url);
            this.selectedItem = [];
        },

    }
}