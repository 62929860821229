import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            misc_cost_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            employees: [],
            employee: {},
            food_cost: '',
            late_cost: '',
            leave_cost: '',
            tax_amount: null,
            office_property_cost: '',
            provident_fund_cost: '',
            misc_cost_details: {
                employee: {
                    emp_id: null,
                    emp_code: null
                }
            }



        }
    },
    mounted() {
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let misc_cost = this.$api + this.$config.payroll.miscCostAPIEndPoint + params;
        this.getDataList(misc_cost);
    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let misc_cost = this.$api + this.$config.payroll.miscCostAPIEndPoint + params;
        await this.getDataList(misc_cost);
        },
        deleteInfo: function(id) {
            this.misc_cost_id = id;
        },
        deleteMiscCost: async function() {
            let misc_cost_details_url = this.$api + this.$config.payroll.miscCostDetails + this.misc_cost_id;
            this.$axios.delete(misc_cost_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let misc_cost = this.$api + this.$config.payroll.miscCostAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(misc_cost);
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateMiscCost: async function() {
            let misc_cost_details_url = this.$api + this.$config.payroll.miscCostDetails + this.misc_cost_id;
            let data = {
                emp_id: this.misc_cost_details.employee.emp_id,
                emp_code: this.misc_cost_details.employee.emp_code,
                emp_name: this.misc_cost_details.employee.emp_name,
                food_cost: this.misc_cost_details.food_cost,
                late_cost: this.misc_cost_details.late_cost,
                leave_cost: this.misc_cost_details.leave_cost,
                office_property_cost: this.misc_cost_details.office_property_cost,
                pf_cost: this.misc_cost_details.pf_cost
            }
            let config = {
                url: misc_cost_details_url,
                method: "PUT",
                data: data
            };
            await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let misc_cost_url = this.$api + this.$config.payroll.miscCostAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(misc_cost_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getMiscCostData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.misc_cost_id = id;
            let misc_cost_details_url = this.$api + this.$config.payroll.miscCostDetails + this.misc_cost_id;
            let config = {
                url: misc_cost_details_url,
                method: "GET"
            }
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.misc_cost_details.employee = {
                        emp_id: response.data.emp_id,
                        emp_code: response.data.emp_code,
                        emp_name: response.data.emp_name,
                    };
                    this.misc_cost_details.food_cost = response.data.food_cost;
                    this.misc_cost_details.late_cost = response.data.late_cost;
                    this.misc_cost_details.leave_cost = response.data.leave_cost;
                    this.misc_cost_details.office_property_cost = response.data.office_property_cost;
                    this.misc_cost_details.pf_cost = response.data.pf_cost;
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createMiscCost: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.payroll.miscCostAPIEndPoint;
            let data = {
                emp_id: this.employee.emp_id,
                emp_code: this.employee.emp_code,
                emp_name: this.employee.emp_name,
                food_cost: this.food_cost,
                late_cost: this.late_cost,
                leave_cost: this.leave_cost,
                office_property_cost: this.office_property_cost,
                pf_cost: this.provident_fund_cost
            }
            let config = {
                url: url,
                method: "POST",
                data: data
            }
            // await this.postDataToBackend(config);
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 201) {
                    this.$toast.success(response.data.message);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                let err = error.response.data.errors
                if(err){
                    for (const property in err) {
                        this.$toast.error(`${property}: ${err[property]}`);
                      }
                } else {
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false
            });
            this.employee = {};
            this.food_cost = '';
            this.late_cost = '';
            this.leave_cost = '';
            this.office_property_cost = '';
            this.provident_fund_cost = '';
            this.misc_cost_details = '';
            let misc_cost = this.$api + this.$config.payroll.miscCostAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=";
            await this.getDataList(misc_cost);

        },

    }
}