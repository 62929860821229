<template>
    <div class="page-wrapper">
			<div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
				<!--breadcrumb-->
				<div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Tables</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Income Tax</li>
							</ol>
						</nav>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Create</button>
						</div>
					</div>
				</div>
				<!--end breadcrumb-->
				<h6 class="mb-0 text-uppercase">Income Tax List</h6>
				<hr/>
				<div class="card" v-if="Object.keys(allDataList).length > 0">
					<div class="card-body">
						<div class="table-responsive overflow-hidden">
							<div class="row mb-3 ms-2">
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_length" id="example_length">
								<label>Show <select v-model="limit" name="example_length" aria-controls="example" class="form-select form-select-sm">
									<option v-for="(option, key) in pageLimit" :key="key" :value="option">{{option}}</option>
									</select>
									</label>
									</div>
									</div>
									<div class="col-sm-12 col-md-6">
										<div id="example_filter" class="dataTables_filter text-end">
											<label class="text-start">Search:<input type="search" v-model="search" class="form-control form-control-sm" placeholder="" aria-controls="example">
											</label>
											<button type="button" class="btn btn-primary px-4 radius-30 ms-2">Go</button>
										</div>
										
									</div>
									
							</div>
							<table class="table table-striped table-bordered">
								<thead>
									<tr>
										<th>Sl. No</th>
										<th>Tax Code</th>
										<th>Tax Product</th>
                                        <th>Tax Amount</th>
                                        <th>Tax Grade</th>
                                        <th>Action</th>
									</tr>
								</thead>
								<tbody v-if="Object.keys(allDataList).length > 0">
									<tr v-for="(li,index) in allDataList" :key="index">
										<td>{{index + 1}}</td>
										<td>{{li.tax_code}}</td>
                                        <td>{{li.tax_product}}</td>
                                        <td>{{li.tax_amount}}</td>
                                        <td>{{li.tax_grade}}</td>
										<td>
											<div class="col">
												<button type="button" class="btn btn-success btn-block px-3 mt-2" data-bs-toggle="modal" @click="editForm(li.id)" data-bs-target="#exampleLargeModal3">View</button>
												<button type="button" class="btn btn-primary btn-block px-3 mt-2 ms-2" data-bs-toggle="modal" @click="editForm(li.id)" data-bs-target="#exampleLargeModal1">Edit</button>
												<button type="button" class="btn btn-danger btn-block px-3 mt-2 ms-2" data-bs-toggle="modal" @click="deleteInfo(li.id)" data-bs-target="#exampleVerticallycenteredModal">Delete</button>
											</div>
										</td>
									</tr>
								</tbody>
								<!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
							</table>
						</div>
						<!-- <div class="row" v-if="allDataList">
                                <pagination v-if="allDataList.last_page > 1"
                                                    :pagination="allDataList"
                                                    :offset="5"
                                                    @paginate="getEmployeeData(setURL + '?page=' + allDataList.current_page + '&limit=' + limit + '&search=' + search)"
                                ></pagination>
                            </div> -->
						<!-- <nav aria-label="Page navigation example" class="float-end mt-2">
							<ul class="pagination round-pagination right">
								<li class="page-item"><a class="page-link" href="javascript:;">Previous</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;javascript:;">1</a>
								</li>
								<li class="page-item active"><a class="page-link" href="javascript:;">2</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">3</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">Next</a>
								</li>
							</ul>
						</nav> -->
					</div>
				</div>
				<div class="card" v-else>
					<h3 class="text-info text-center">No Data Found</h3>
				</div>
			</div>
            <!-- Modal -->
            <div class="modal fade" id="exampleLargeModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
							<h5>Income Tax Creation</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-primary">
							<div class="card-body p-5">
								<form class="row g-3">
                                    
									<div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Code<span style="color:red;font-size:18px">*</span></label>
										<input type="text" v-model="tax_code" class="form-control" id="inputPhone" placeholder="Tax Code">
									</div>
									
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Product<span style="color:red;font-size:18px">*</span></label>
										<input type="text" v-model="tax_product" class="form-control" id="inputPhone" placeholder="Tax Product">
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Amount<span style="color:red;font-size:18px">*</span></label>
										<input type="text" v-model="tax_amount" class="form-control" id="inputPhone" placeholder="Tax Amount">
									</div>
                                   
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Grade<span style="color:red;font-size:18px">*</span></label>
										<input type="text" v-model="tax_grade" class="form-control" id="inputPhone" placeholder="Tax Grade">
									</div>
                                    
									
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="createIncomeTax()" data-bs-dismiss="modal" class="btn btn-primary">Create</button>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Update Modal -->
			<div class="modal fade" id="exampleLargeModal1" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
							<h5>Update Income Tax</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-warning">
							<div class="card-body p-5">
								
								<form class="row g-3">
                                    
									<div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Code</label>
										<input type="text" v-model="income_tax_details.tax_code" class="form-control" id="inputPhone" placeholder="Tax Code">
									</div>
									
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Product</label>
										<input type="text" v-model="income_tax_details.tax_product" class="form-control" id="inputPhone" placeholder="Tax Product">
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Amount</label>
										<input type="text" v-model="income_tax_details.tax_amount" class="form-control" id="inputPhone" placeholder="Tax Amount">
									</div>
                                   
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Grade</label>
										<input type="text" v-model="income_tax_details.tax_grade" class="form-control" id="inputPhone" placeholder="Tax Grade">
									</div>
                                    
									
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="updateIncomeTax()" data-bs-dismiss="modal" class="btn btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>

			<!-- View Modal -->
			<div class="modal fade" id="exampleLargeModal3" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
							<h5>Income Tax info</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-warning">
							<div class="card-body p-5">
								
								<form class="row g-3">
                                    
									<div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Code</label>
										<input type="text" v-model="income_tax_details.tax_code" class="form-control" id="inputPhone" disabled>
									</div>
									
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Product</label>
										<input type="text" v-model="income_tax_details.tax_product" class="form-control" id="inputPhone" disabled>
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Amount</label>
										<input type="text" v-model="income_tax_details.tax_amount" class="form-control" id="inputPhone" disabled>
									</div>
                                   
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Tax Grade</label>
										<input type="text" v-model="income_tax_details.tax_grade" class="form-control" id="inputPhone" disabled>
									</div>
                                    
									
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Delete Modal -->
			<div class="modal fade" id="exampleVerticallycenteredModal" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header bg-primary">
							<h5 class="modal-title">Are You Sure?</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" v-on:click="deleteIncomeTax()" data-bs-dismiss="modal" class="btn btn-danger">Delete</button>
						</div>
					</div>
				</div>
			</div>
</div>
        
</template>
<script src="../js/income_tax.js"></script>