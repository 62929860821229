import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            policy_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search:'',
            id: null,
            leave_title: '',
            short_code: '',
            total_days: null,
            applicable_to_all: false,
            available_after_month: null,
            weekend_holiday_count: false,
            is_carry_forward: false,
            max_carry_forward: null,
            status: '',
            policy_details: {}
        }
    },
    mounted() {
        this.setURL = this.$config.leave.leavePolicyAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + params;
        this.getDataList(leave_policy_url);
        
        
    },
    created() {
        document.title = "HrConnect - Leave Management";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + params;
           await this.getDataList(leave_policy_url);
        },
        deleteInfo: function(id) {
            this.policy_id = id;
        },
        deleteLeavePolicy: async function() {
            let leave_policy_details_url = this.$api + this.$config.leave.leavePolicyDetails + this.policy_id;
            await this.$axios.delete(leave_policy_details_url, {

            }).then((response) => {
                if(response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.$router.go();
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                    
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateLeavePolicy: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.leavePolicyDetails);
            let policy_details_url = this.$api + this.$config.leave.leavePolicyDetails + this.policy_id;
           await this.$axios.put(policy_details_url, {
                leave_title: this.policy_details.leave_title,
                short_code: this.policy_details.short_code,
                total_days: this.policy_details.total_days,
                applicable_to_all: this.policy_details.applicable_to_all,
                available_after_month: this.policy_details.available_after_month,
                weekend_holiday_count: this.policy_details.weekend_holiday_count,
                is_carry_forward: this.policy_details.is_carry_forward,
                max_carry_forward: this.policy_details.max_carry_forward,
                status: this.policy_details.status? 0:1
                
            }).then(async(response) => {
                if(response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(leave_policy_url)
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getLeavePolicyData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {
                    }).then((response) => {
                        if(response.status == 200) {
                            //console.log("Data is", response.data);
                            this.dataList = response.data.data;
                            this.allDataList = response.data;
                        }
                        else {
                            this.isLoading = false
                            this.$swal.fire({
                                icon: "error",
                                text: "No Data Found!"
                            });
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });

        },
        editForm: async function(id) {
            this.policy_id = id;
            let leave_policy_details_url = this.$api + this.$config.leave.leavePolicyDetails + this.policy_id;
            //console.log(leave_policy_details_url);
            await this.$axios.get(leave_policy_details_url, {
                    }).then((response) => {
                        if(response.status == 200) {
                            this.policy_details.leave_title = response.data.leave_title,
                            this.policy_details.short_code = response.data.short_code,
                            this.policy_details.total_days = response.data.total_days,
                            this.policy_details.applicable_to_all = response.data.applicable_to_all,
                            this.policy_details.available_after_month = response.data.available_after_month,
                            this.policy_details.weekend_holiday_count = response.data.weekend_holiday_count,
                            this.policy_details.is_carry_forward = response.data.is_carry_forward,
                            this.policy_details.max_carry_forward = response.data.max_carry_forward,
                            this.policy_details.status = response.data.status? false:true

                        }
                        else {
                          //console.log("In else");
                            this.isLoading = false
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
                    
  
          },
        createLeavePolicy: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.leave.leavePolicyAPIEndPoint
            //console.log("Applicable To: ", this.applicable_to_all)
            //console.log("Weekend Count: ", this.weekend_holiday_count)
            await this.$axios.post(url, {
                leave_title: this.leave_title,
                short_code: this.short_code,
                total_days: this.total_days,
                applicable_to_all: this.applicable_to_all,
                available_after_month: this.available_after_month,
                weekend_holiday_count: this.weekend_holiday_count,
                is_carry_forward: this.is_carry_forward,
                max_carry_forward: this.max_carry_forward,
                status: this.status? 0 : 1

                    }).then(async(response) => {
                        if(response.status == 201) {
                            //console.log("leave_policy Created Successfully");
                            this.$toast.success(response.data.message);
                            let leave_policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                            await this.getDataList(leave_policy_url)
                        }
                        else {
                            this.isLoading = false
                        }
                    }).catch(error => {
                        //console.log("Error is ", error.response)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
            // this.$router.go();
          },

    }
}