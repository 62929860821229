<template>
  <div class="page-wrapper">
    <div class="page-content">
      <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create Employee
            </button>
          </div>
          
        </div>
      </div>

      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee List</h6>
      <hr />
      <div class="card" >
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    aria-controls="example" @change="searchData()"
                    class="form-select form-select-sm mt-1"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
                <label
                  >Active Status
                  <select
                    v-model="isActive"
                    @change="isActiveStatus()"
                    aria-controls="example"
                    class="form-select form-select-sm mt-1">
                    <option :value="true">Active</option>
                    <option :value="false">Inactive</option>
                  </select>
                </label>
                <label
                  >Company
                  <select  
                    v-model="searchCompany" class="form-select form-select" @change="selectedCompany(searchCompany)">
                    <option
                      v-for="(option, key) in companies"
                      :key="key"
                      :value="option"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start ms-2"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm mt-1"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  @click="searchData()"
                  type="button"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table id="exampleEmp" class="table table-striped table-bordered emp-tbl" style="font-size: 10px;">
              <thead>
                <tr>
                  <th class="sticky-col">Sl. No</th>
                  <th class="sticky-col">Name</th>
                  <th class="sticky-col">Designation</th>
                  <th class="sticky-col">Employee ID</th>
                  <th>Joining Date</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Department</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody v-if="Object.keys(dataList).length > 0">
                <tr v-for="(li, index) in dataList" :key="index">
                  <th class="sticky-col">{{ index + 1 }}</th>
                  <th class="sticky-col">{{ li.first_name }}&nbsp;{{ li.last_name }}</th>
                  <td class="sticky-col">{{ li.designation_name }}</td>
                  <td class="sticky-col">{{ li.emp_code }}</td>
                  <td>{{ li.date_of_joining }}</td>
                  <td>{{ li.email }}</td>
                  <td>{{ li.phone }}</td>
                  <td>{{ li.department_name }}</td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-primary m-1"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal2"
                      >
                      <i class="lni lni-eye me-0"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-primary m-1"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal1"
                        v-if="li.is_active==true?'disabled':''" 
                      >
                      <i class="bx bx-edit-alt me-0"></i>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-dark btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id, li.emp_code)"
                        data-bs-target="#exampleLargeModal3"
                      >
                        Stuck Off
                      </button> 
                      <button
                        type="button"
                        class="btn btn-outline-danger m-1"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                      <i class="bx bx-trash me-0"></i>
                      </button>-->
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10"><center>No data found!</center></td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee ID</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
            <v-pagination
              v-model="page"
              :pages="total_pages"
              :range-size="1"
              active-color="#037de2"
              @update:modelValue="updateHandler"
              :prevText="'Prev'"
              :nextText="'Next'"
              :containerClass="'pagination'"
              class="pagination col-md-12"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Creation</h5>
            <button
              type="button"
              @click="closeAddEmployee"
              class="btn-close"
              data-bs-dismiss="modal"
              id="closeAddEmployee"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#primaryhome"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="fadeIn animated bx bx-message-square-check font-18 me-1"
                          ></i>
                        </div>
                        <div class="tab-title">Employee</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#primaryprofile"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Other Documents</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#payrollInfo"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Employee Payroll Info</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="primaryhome"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="firstname"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="lastname"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Email<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="inputEmail"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="phone"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          required
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Country<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="country"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in countries"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >City<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="city"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in cities"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Gender<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="gender"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in genders"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Blood Group</label
                        >
                        <select
                          v-model="blood_group"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in blood_groups"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Marital Status<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="marital_status"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in marital_statuses"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Company<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="company"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in companies"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Branch<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="branch"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in branches"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Department<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="department"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in departments"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Section<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="section"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in sections"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Role<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="role"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in roles"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="designation"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in designations"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee ID<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="text"
                          v-model="emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee ID"
                          required
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeDate" class="form-label"
                          >Date of Joining<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="date"
                          v-model="date_of_joining"
                          class="form-control"
                          id="inputEmployeeDate"
                          required
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeDate" class="form-label"
                          >Date of Probation(End date)</label>
                        <select
                          v-model="select_probation_month"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option :value="1">1 month</option>
                          <option :value="2">2 months</option>
                          <option :value="3">3 months</option>
                          <option :value="4">4 months</option>
                          <option :value="5">5 months</option>
                          <option :value="6">6 months</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Employee Type<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="employee_type"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option>Intern</option>
                          <option>Part time</option>
                          <option>Full time</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Employee Image<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="file"
                          @change="uploadImage"
                          name="files"
                          accept=".jpg, .png, .jpeg"
                          ref="uploadEmpImg"
                          uploader="uploader"
                          single
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="primaryprofile"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                     
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-body">
                            <!-- <h4 class="card-title text-center">Upload Product Image</h4>
                                <hr> -->
                            <div class="table-responsive">
                              <table id="pro" class="table table-hover">
                                <thead>
                                  <tr>
                                    <th>File</th>
                                    <th>Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(content, index) in tableRows"
                                    :key="index"
                                    :row="index"
                                  >
                                    <td>
                                      <input
                                        type="file"
                                        @change="uploadCertificates"
                                        accept=".jpg, .png, .pdf"
                                        class="form-control"
                                        placeholder="Upload certificate file"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        v-model="content.certificatesType"
                                        class="form-control"
                                        placeholder="Enter certificate name"
                                      />
                                    </td>
                                    <td class="mt-10">
                                      <span
                                        v-if="index != currentIndex"
                                        @click="deleteRow(index)"
                                        class="btn btn-danger"
                                        ><i class="bx bx-trash"></i
                                      ></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="text-center">
                              <span @click="addNewRow()" class="btn btn-info"
                                ><i class="bx bx-plus"></i> ADD NEW</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="payrollInfo" role="tabpanel">
                    <form class="row g-3">
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Gross Salary</label
                        >
                        <input
                          type="number"
                          v-model="gross_salary"
                          @change="grossSalary()"
                          class="form-control"
                          placeholder="Enter Gross Salary"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Basic Salary</label
                        >
                        <input
                          type="number"
                          v-model="basic_salary"
                          disabled
                          class="form-control"
                          placeholder="Enter Basic Salary"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >House Rent</label
                        >
                        <input
                          type="number"
                          v-model="house_rent"
                          disabled
                          class="form-control"
                          placeholder="Enter House Rent"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Medical</label
                        >
                        <input
                          type="number"
                          v-model="medical"
                          disabled
                          class="form-control"
                          placeholder="Enter Medical amount"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Conveyance</label
                        >
                        <input
                          type="number"
                          v-model="conveyance"
                          disabled
                          class="form-control"
                          placeholder="Enter Conveyance amount"
                        />
                      </div>
                      <!-- <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Salary Grade</label
                        >
                        <select
                          v-model="salary_grade"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in salary_grades"
                            :key="key"
                            :value="option"
                          >
                            {{ option.salary_code }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Salary Issue Medium</label
                        >
                        <select
                          v-model="salary_issue_medium"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in salary_issue_mediums"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Basic Salary</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.basic_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Health Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.health_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Housing Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.housing_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div>
                      <div
                        class="col-md-6"
                        v-if="selected_salary_grade == true"
                      >
                        <label for="inputEmail" class="form-label"
                          >Transport Allowance</label
                        >
                        <input
                          type="text"
                          v-model="salary_grade.transport_salary"
                          class="form-control"
                          id="inputEmployeeCode"
                          disabled
                        />
                      </div> -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button> -->
            <button
              type="button"
              v-on:click="createEmployee()"
              class="btn btn-primary"
            >
              Create Employee
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Employee Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              id="closeEditEmployee"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#editPrimaryHome"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="fadeIn animated bx bx-message-square-check font-18 me-1"
                          ></i>
                        </div>
                        <div class="tab-title">Employee</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#editPrimaryProfile"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-folder font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Other Documents</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#editPayrollInfo"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Employee Payroll Info</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#editEmployeeStatus"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="lni lni-comments-alt font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Status</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="editPrimaryHome"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.first_name"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.last_name"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label">Email</label>
                        <input
                          type="email"
                          v-model="emp_details.email"
                          class="form-control"
                          id="inputEmail"
                          
                          placeholder="Enter Email"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.phone"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Country</label>
                        <select
                          v-model="emp_details.country"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in countries"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">City</label>
                        <select
                          v-model="emp_details.city"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in cities"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Gender</label>
                        <select
                          v-model="emp_details.gender"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in genders"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Blood Group</label
                        >
                        <select
                          v-model="emp_details.blood_group"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in blood_groups"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Marital Status</label
                        >
                        <select
                          v-model="emp_details.marital_status"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in marital_statuses"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Company</label>
                        <select
                          v-model="emp_details.company"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in companies"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Branch</label>
                        <select
                          v-model="emp_details.branch"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in branches"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Department</label
                        >
                        <select
                          v-model="emp_details.department"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in departments"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Section</label>
                        <select
                          v-model="emp_details.section"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in sections"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Role</label>

                        <select
                          v-model="emp_details.role"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in roles"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="emp_details.designation"
                          id="inputState"
                          class="form-select single-select"
                          required
                        >
                          <option
                            v-for="(option, key) in designations"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee ID</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          
                          placeholder="Enter Employee ID"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee Type</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.employee_type"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee type"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Date of joining</label
                        >
                        <input
                          type="date"
                          v-model="emp_details.date_of_joining"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="select Employee joining"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Date of probation</label
                        >
                        <input
                          type="date"
                          v-model="emp_details.probation_date"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="select Employee probation"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Employee Image</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="file"
                          @change="updateImage"
                          name="files"
                          accept=".jpg, .png, .jpeg"
                          ref="inputFile"
                          uploader="uploader"
                          single
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="editPrimaryProfile"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                     
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="table-responsive overflow-hidden">
                              <h4>File list</h4>
                              <table class="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th>Sl. No</th>
                                    <th>Files</th>
                                  </tr>
                                </thead>
                                <tbody v-if="Object.keys(certificates).length > 0">
                                  <tr v-for="(li, index) in certificates" :key="index">
                                    <td style="width: 20px">{{ index + 1 }}</td>
                                    <td>
                                      <div class="d-flex align-items-center">
                                        <div>
                                          <i
                                            class="bx bxs-file me-2 font-24 text-primary"
                                          ></i>
                                        </div>
                                        <div class="font-weight-bold text-primary">
                                          <span
                                            class="anchorLink"
                                            @click="
                                              downloadWithAxios(
                                                $backend + li.file,
                                                li.filename
                                              )
                                            "
                                            >{{ li.name }}</span
                                          >
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="2">No data found!</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="table-responsive">
                              <h4 class="card-title text-center">Upload certificate</h4>
                                               <hr>
                              <table id="pro" class="table table-hover">
                                <thead>
                                  <tr>
                                    <th>File</th>
                                    <th>Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(content, index) in tableRows"
                                    :key="index"
                                    :row="index"
                                  >
                                    <td>
                                      <input
                                        type="file"
                                        @change="uploadCertificates"
                                        accept=".jpg, .png, .pdf"
                                        class="form-control"
                                        placeholder="Upload certificate file"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        v-model="content.certificatesType"
                                        class="form-control"
                                        placeholder="Enter certificate name"
                                      />
                                    </td>
                                    <td class="mt-10">
                                      <span
                                        v-if="index != currentIndex"
                                        @click="deleteRow(index)"
                                        class="btn btn-danger"
                                        ><i class="bx bx-trash"></i
                                      ></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="text-center">
                              <span @click="addNewRow()" class="btn btn-info"
                                ><i class="bx bx-plus"></i> ADD NEW</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="editPayrollInfo" role="tabpanel">
                    <form class="row g-3" v-if="emp_details.salary_info">
                      
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Gross Salary</label
                        >
                        <input
                          type="number"
                          v-model="emp_details.salary_info.gross_salary"
                          @change="EditGrossSalary(emp_details.salary_info.gross_salary)"
                          class="form-control"
                          placeholder="Enter Gross Salary"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Basic Salary</label
                        >
                        <input
                          type="number"
                          v-model="emp_details.salary_info.basic_salary"
                          disabled
                          class="form-control"
                          placeholder="Enter Basic Salary"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >House Rent</label
                        >
                        <input
                          type="number"
                          v-model="emp_details.salary_info.house_rent"
                          disabled
                          class="form-control"
                          placeholder="Enter House Rent"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Medical</label
                        >
                        <input
                          type="number"
                          v-model="emp_details.salary_info.medical"
                          disabled
                          class="form-control"
                          placeholder="Enter Medical amount"
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Conveyance</label
                        >
                        <input
                          type="number"
                          v-model="emp_details.salary_info.conveyance"
                          disabled
                          class="form-control"
                          placeholder="Enter Conveyance amount"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="editEmployeeStatus" role="tabpanel">
                    <form class="row g-3" v-if="emp_details.salary_info">
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Is Active</label>
                        <select
                          v-model="emp_details.is_active"
                          id="inputState"
                          class="form-select single-select" >
                          <option :value="true">Active </option>
                          <option :value="false">Inactive </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Last working date</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="date"
                          v-model="emp_details.lastWorkingDate"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateEmployee()"
              
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Stuck Off -->
    <div
      class="modal fade"
      id="exampleLargeModal3"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Stuck Off</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-info">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputLastName" class="form-label"
                      >Stuck Off Date</label
                    >
                    <input
                      type="date"
                      v-model="stuck_off_date"
                      class="form-control"
                      id="stuck_off"
                      placeholder="Enter Date"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-dark btn-block px-3 mt-2 ms-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleVerticallycenteredModal2"
            >
              Stuck Off
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal2"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#leaveApplication"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="fadeIn animated bx bx-message-square-check font-18 me-1"
                          ></i>
                        </div>
                        <div class="tab-title">Employee Details</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#leaveHistory"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Other Documents</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#viewEmployeeStatus"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="lni lni-comments-alt font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Status</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="leaveApplication"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputFirstName" class="form-label"
                          >First Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.first_name"
                          class="form-control"
                          id="inputFirstName"
                          placeholder="Enter Firstname"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputLastName" class="form-label"
                          >Last Name</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.last_name"
                          class="form-control"
                          id="inputLastName"
                          placeholder="Enter Lastname"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label">Email</label>
                        <input
                          type="email"
                          v-model="emp_details.email"
                          class="form-control"
                          id="inputEmail"
                          placeholder="Enter Email"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Phone Number</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.phone"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Country</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.country.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label">City</label>
                        <input
                          type="text"
                          v-model="emp_details.city.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Gender</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.gender.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Blood Group</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.blood_group.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Marital Status</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.marital_status.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-5">
                        <label for="inputState" class="form-label"
                          >Company</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.company.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Branch</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.branch.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Department</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.department.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Section</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.section.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Role</label>
                        <input
                          type="text"
                          v-model="emp_details.role.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Designation</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.designation.name"
                          class="form-control"
                          id="inputPhone"
                          placeholder="Enter Phone number"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee ID</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.emp_code"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee ID"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Employee Type</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.employee_type"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="Enter Employee type"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Date of joining</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.date_of_joining"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="select Employee joining"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputEmployeeCode" class="form-label"
                          >Date of probation</label
                        >
                        <input
                          type="text"
                          v-model="emp_details.probation_date"
                          class="form-control"
                          id="inputEmployeeCode"
                          placeholder="select Employee probation"
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="leaveHistory" role="tabpanel">
                    <div class="table-responsive overflow-hidden">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Sl. No</th>
                            <th>Files</th>
                          </tr>
                        </thead>
                        <tbody v-if="Object.keys(certificates).length > 0">
                          <tr v-for="(li, index) in certificates" :key="index">
                            <td style="width: 20px">{{ index + 1 }}</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  <i
                                    class="bx bxs-file me-2 font-24 text-primary"
                                  ></i>
                                </div>
                                <div class="font-weight-bold text-primary">
                                  <span
                                    class="anchorLink"
                                    @click="
                                      downloadWithAxios(
                                        $backend + li.file,
                                        li.filename
                                      )
                                    "
                                    >{{ li.name }}</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="2">No data found!</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="viewEmployeeStatus" role="tabpanel">
                    <form class="row g-3" v-if="emp_details.salary_info">
                      <div class="col-md-4">
                        <label for="inputState" class="form-label">Is Active</label>
                        <select
                          v-model="emp_details.is_active" disabled
                          id="inputState"
                          class="form-select single-select" >
                          <option :value="true">Active </option>
                          <option :value="false">Inactive </option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Last working date</label
                        >
                        <input
                          class="form-control"
                          id="formFileMultiple1"
                          type="text" disabled
                          v-model="emp_details.lastWorkingDate"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" v-on:click="updateEmployee()" data-bs-dismiss="modal" class="btn btn-primary">Update Employee</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Stuck Off Confirm -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal2"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createStuckOff()"
              data-bs-dismiss="modal"
              class="btn btn-success"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteEmployee()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/employee.js"></script>
<style>
.anchorLink {
  cursor: pointer;
  color: #0d6efd;
}
#example_length label{
  padding-right: 10px;
}
#exampleEmp td{
  text-align: left;
  vertical-align: middle;
}
#exampleEmp th{
  text-align: left;
  vertical-align: middle;
}
</style>
