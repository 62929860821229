import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            allDataList: [],
            department_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            name: '',
            emp_details: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                emp_code: '',
                marital_status: {},
                company: {},
                country: {},
                city: {},
                blood_group: {},
                gender: {},
                branch: {},
                department: {},
                section: {},
                role: {},
                designation: {}
            },
        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let stuckOff_url = this.$api + this.$config.employees.stuckOffEndPoint + "?page=" + params;
        this.getDataList(stuckOff_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        editForm: async function(id) {
            this.emp_id = id;
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            //console.log(emp_details_url);
            await this.$axios.get(emp_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.emp_details.first_name = response.data.first_name;
                    this.emp_details.last_name = response.data.last_name;
                    this.emp_details.email = response.data.email;
                    this.emp_details.phone = response.data.phone;
                    this.emp_details.emp_code = response.data.emp_code;
                    this.emp_details.date_of_joining = response.data.date_of_joining;
                    this.emp_details.image = this.$backend + response.data.image;
                    this.emp_details.stuck_off_date = response.data.date_off_stuck_off;
                    if (response.data.marital_status == "Married") {
                        this.emp_details.marital_status = {
                            id: 1,
                            name: "Married"
                        }
                    } else if (response.data.marital_status == "Unmarried") {
                        this.emp_details.marital_status = {
                            id: 2,
                            name: "Unmarried"
                        }
                    }
                    this.emp_details.country = {
                        id: response.data.country_id,
                        name: response.data.country_name
                    }
                    this.emp_details.company = {
                        id: response.data.company_id,
                        name: response.data.company_name
                    }
                    this.emp_details.city = {
                        id: response.data.city_id,
                        name: response.data.city_name
                    }
                    this.emp_details.blood_group = {
                        id: response.data.blood_group_id,
                        name: response.data.blood_group_title
                    }
                    this.emp_details.gender = {
                        id: response.data.gender_id,
                        name: response.data.gender_title
                    }
                    this.emp_details.branch = {
                        id: response.data.branch_id,
                        name: response.data.branch_name
                    }
                    this.emp_details.department = {
                        id: response.data.department_id,
                        name: response.data.department_name
                    }
                    this.emp_details.section = {
                        id: response.data.section_id,
                        name: response.data.section_name
                    }
                    this.emp_details.role = {
                        id: response.data.role_id,
                        name: response.data.role_name
                    }
                    this.emp_details.designation = {
                        id: response.data.designation_id,
                        name: response.data.designation_name
                    }
                } else {
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let stuckOff_url = this.$api + this.$config.employees.stuckOffEndPoint + params;
            await this.getDataList(stuckOff_url);
        }
        // deleteInfo: function(id) {
        //     this.department_id = id;
        // },
        // deleteDepartment: function() {
        //     let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
        //     this.$axios.delete(department_details_url, {

        //     }).then((response) => {
        //         if (response.status == 200) {
        //             //console.log("Delete Successfully");
        //             this.$toast.success(response.data.message);
        //             let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        //             this.getDataList(department_url);
        //         } else {
        //             //console.log("In else");
        //             this.isLoading = false
        //         }
        //     }).catch(error => {
        //         //console.log(error)
        //         this.isLoading = false
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "No Data Found!"
        //         });
        //     });
        // },
        // updateDepartment: function() {
        //     let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
        //     this.$axios.put(department_details_url, {
        //         title: this.department_details.name,

        //     }).then((response) => {
        //         if (response.status == 200) {
        //             //console.log("Update Successfully");
        //             this.$toast.success(response.data.message);
        //             let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        //             this.getDataList(department_url);
        //         } else {
        //             //console.log("In else");
        //             this.isLoading = false
        //         }
        //     }).catch(error => {
        //         //console.log(error)
        //         this.isLoading = false
        //         this.$toast.error(error.response.data.message);
        //     });
        // },
        // getDepartmentData: function(url) {
        //     let emp_url = this.$api + url;
        //     //console.log("Employee Url is: ", emp_url)
        //     this.$axios.get(emp_url, {}).then((response) => {
        //         if (response.status == 200) {
        //             //console.log("Data is", response.data);
        //             this.dataList = response.data.data;
        //             this.allDataList = response.data;
        //         } else {
        //             this.isLoading = false
        //         }
        //     }).catch(error => {
        //         //console.log(error)
        //         this.isLoading = false
        //         this.$toast.error(error.response.data.message);
        //     });

        // },
        // editForm: function(id) {
        //     this.department_id = id;
        //     let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
        //     //console.log(department_details_url);
        //     this.$axios.get(department_details_url, {}).then((response) => {
        //         if (response.status == 200) {
        //             this.department_details.name = response.data.title;

        //         } else {
        //             //console.log("In else");
        //             this.isLoading = false
        //         }
        //     }).catch(error => {
        //         //console.log(error)
        //         this.isLoading = false
        //         this.$toast.error(error.response.data.message);
        //     });

        // },
        // createDepartment: function() {
        //     //console.log("Employee Creation Method");
        //     let url = this.$api + this.$config.configurations.departmentAPIEndPoint
        //     this.$axios.post(url, {
        //         title: this.name,
        //     }).then((response) => {
        //         if (response.status == 201) {
        //             //console.log("Department Created Successfully");
        //             this.$toast.success(response.data.message);
        //             let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        //             this.getDataList(department_url);
        //         } else {
        //             this.isLoading = false
        //         }
        //     }).catch(error => {
        //         //console.log("Error is ", error.response)
        //         this.isLoading = false
        //         this.$toast.error(error.response.data.message);
        //     });
        // },

    }
}