import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            allDataList: [],
            department_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            name: '',
            department_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.configurations.departmentAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + params;
        this.getDataList(department_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + params;
            await this.getDataList(department_url);
        },
        deleteInfo: async function(id) {
            this.department_id = id;
        },
        deleteDepartment: async function() {
            let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
            await this.$axios.delete(department_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(department_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error){
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateDepartment: async function() {
            let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
            await this.$axios.put(department_details_url, {
                title: this.department_details.name,

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(department_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getDepartmentData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.department_id = id;
            let department_details_url = this.$api + this.$config.configurations.departmentDetails + this.department_id;
            //console.log(department_details_url);
            await this.$axios.get(department_details_url, {}).then(async(response) => {
                if (response.status == 200) {
                    this.department_details.name = response.data.title;

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createDepartment: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.departmentAPIEndPoint
            await this.$axios.post(url, {
                title: this.name,
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("Department Created Successfully");
                    this.$toast.success(response.data.message);
                    let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(department_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}