import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars"
        }
    },
    created() {
        document.title = "HrConnect - Signup";
    }
}