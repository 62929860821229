<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Verification
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Add Verification
            </button>
            <!-- <button type="button" class="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown">	<span class="visually-hidden">Toggle Dropdown</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-end">	<a class="dropdown-item" href="javascript:;">Action</a>
								<a class="dropdown-item" href="javascript:;">Another action</a>
								<a class="dropdown-item" href="javascript:;">Something else here</a>
								<div class="dropdown-divider"></div>	<a class="dropdown-item" href="javascript:;">Separated link</a>
							</div> -->
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Verification List</h6>
      <hr />
      <div class="card" v-if="Object.keys(dataList).length > 0">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm mt-1"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start ms-2"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm mt-1"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  @click="searchData"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee Name</th>
                  <th>Verification</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(dataList).length > 0">
                <tr v-for="(li, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.emp_name }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <i class="bx bxs-file me-2 font-24 text-primary"></i>
                      </div>
                      <div class="font-weight-bold text-primary">
                        <span class="anchorTag"  @click="downloadWithAxios($domainName+li.file, li.filename)"> {{ li.filename }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-primary btn-block px-5 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-5 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="dataList">
							<v-pagination
							v-model="page"
							:pages="total_pages"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Employee Creation</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <div class="card-title d-flex align-items-center">
                  <div>
                    <i class="bx bxs-user me-1 font-22 text-primary"></i>
                  </div>
                  <h5 class="mb-0 text-primary">Verification Creation</h5>
                </div>
                <hr />
                <form class="row g-3">
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Employee Code<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <select
                      v-model="employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Employee Code<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <select
                      v-model="employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >File Upload<span style="color: red; font-size: 18px"
                        >*</span
                      ></label
                    >
                    <input
                      class="form-control"
                      id="formFileMultiple"
                      type="file"
                      @change="uploadFiles"
                      name="files"
                      ref="inputFile"
                      uploader="uploader"
                      multiple
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createVerification()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create Verification
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Employee Creation</h5> -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <div class="card-title d-flex align-items-center">
                  <div>
                    <i class="bx bxs-user me-1 font-22 text-primary"></i>
                  </div>
                  <h5 class="mb-0 text-primary">Update Verification</h5>
                </div>
                <hr />
                <form class="row g-3">
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Employee Code</label
                    >
                    <select
                      v-model="verification_details.employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Employee Code</label
                    >
                    <select
                      v-model="verification_details.employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label"
                      >File Upload</label
                    >
                    <input
                      class="form-control"
                      id="formFileMultiple"
                      type="file"
                      @change="uploadFiles"
                      name="files"
                      accept=".jpg, .png"
                      ref="inputFile"
                      uploader="uploader"
                      multiple
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateVerification()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update Certificate
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteVerification()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/verification.js"></script>