<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Stuck Off Employee
              </li>
            </ol>
          </nav>
        </div>
        <!-- <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create Stuck Off Employee
            </button>
          </div>
        </div> -->
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Stuck Off Employee List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
          
            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length" id="example_length">
                  <label
                    >Show
                    <select
                      v-model="limit"
                      name="example_length"
                      aria-controls="example"
                      class="form-select form-select-sm mt-1"
                    >
                      <option
                        v-for="(option, key) in pageLimit"
                        :key="key"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div id="example_filter" class="dataTables_filter text-end">
                  <label class="text-start ms-2"
                    >Search:<input
                      type="search"
                      v-model="search"
                      class="form-control form-control-sm mt-1"
                      placeholder=""
                      aria-controls="example"
                    />
                  </label>
                  <button
                    type="button"
                    @click="searchData"
                    class="btn btn-primary px-4 radius-30 ms-2"
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee Code</th>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.emp_code }}</td>
                  <td>{{ li.first_name + " " + li.last_name }}</td>
                  <td>{{ li.email }}</td>
                  <td>{{ li.designation_name }}</td>

                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-success btn-block px-5 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        View
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-danger btn-block px-5 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
            <v-pagination
              v-model="page"
              :pages="total_page"
              :range-size="1"
              active-color="#037de2"
              @update:modelValue="updateHandler"
              :prevText="'Prev'"
              :nextText="'Next'"
              :active-class="myActiveBtn"
              :containerClass="'pagination'"
              class="pagination col-md-12"
            />
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Stuck Employee Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputFirstName" class="form-label"
                      >First Name</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.first_name"
                      class="form-control"
                      id="inputFirstName"
                      placeholder="Enter Firstname"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputLastName" class="form-label"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.last_name"
                      class="form-control"
                      id="inputLastName"
                      placeholder="Enter Lastname"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input
                      type="email"
                      v-model="emp_details.email"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Email"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Phone Number</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.phone"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="inputState" class="form-label">Country</label>
                    <input
                      type="text"
                      v-model="emp_details.country.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="inputState" class="form-label">City</label>
                    <input
                      type="text"
                      v-model="emp_details.city.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="inputState" class="form-label">Gender</label>
                    <input
                      type="text"
                      v-model="emp_details.gender.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="inputState" class="form-label"
                      >Blood Group</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.blood_group.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="inputState" class="form-label"
                      >Marital Status</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.marital_status.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="inputState" class="form-label">Company</label>
                    <input
                      type="text"
                      v-model="emp_details.company.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Branch</label>
                    <input
                      type="text"
                      v-model="emp_details.branch.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label"
                      >Stuck Off Employee</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.department.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">Section</label>
                    <input
                      type="text"
                      v-model="emp_details.section.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label">Role</label>
                    <input
                      type="text"
                      v-model="emp_details.role.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputState" class="form-label"
                      >Designation</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.designation.name"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Phone number"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputEmployeeCode" class="form-label"
                      >Employee Code</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.emp_code"
                      class="form-control"
                      id="inputEmployeeCode"
                      placeholder="Enter Employee Code"
                      disabled
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="inputEmployeeCode" class="form-label"
                      >Stuck Off Date</label
                    >
                    <input
                      type="text"
                      v-model="emp_details.stuck_off_date"
                      class="form-control"
                      id="inputEmployeeCode"
                      placeholder="Enter Employee Code"
                      disabled
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/stuck_off.js"></script>