import Loading from "vue-loading-overlay";
import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VueTimepicker,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            policy_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search:'',
            id: null,
            setURL: '',
            postURL: '',
            policy_name: null,
            start_time: '10:00',
            end_time: '06:00',
            grace: '',
            overtime_after: '',
            shift_policy: {},
            shift_title: '',
            
        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let shift_policy_url = this.$api + this.$config.attendance.shiftPolicyAPI + params;
        this.setURL = shift_policy_url;
        this.postURL = this.$api + this.$config.attendance.shiftPolicyAPI;
        this.getDataList(shift_policy_url);
        
        
    },
    created() {
        document.title = "HrConnect - Attendance Management";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let shift_policy_url = this.$api + this.$config.attendance.shiftPolicyAPI + params;
        await this.getDataList(shift_policy_url);
        },
        deleteInfo: async function(id) {
            this.policy_id = id;
        },
        deleteShiftPolicy: async function() {
            let shift_policy_url = this.$api + this.$config.attendance.shiftPolicyDetails + this.policy_id;
            await this.$axios.delete(shift_policy_url, {

            }).then(async(response) => {
                if(response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    await this.getDataList(this.setURL);
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                    
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateShiftPolicy: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.ShiftPolicyDetails);
            //console.log("Shift Policy Details: ", this.shift_policy);
            let shift_policy_url = this.$api + this.$config.attendance.shiftPolicyDetails + this.policy_id;
            let data = {
                policy_name: this.shift_policy.policy_name,
                start_time: this.shift_policy.start_time + '00',
                end_time: this.shift_policy.end_time + '00',
                grace: this.shift_policy.grace,
                overtime_after: this.shift_policy.overtime_after + ':' + '00'
            };
            let config = {
                method: "PUT",
                url: shift_policy_url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                if(response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    await this.getDataList(this.setURL);
                }
                else {
                  //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        
        editForm: async function(id) {
            this.policy_id = id;
            let shift_shift_policy_url = this.$api + this.$config.attendance.shiftPolicyDetails + this.policy_id;
            //console.log(shift_shift_policy_url);
            await this.$axios.get(shift_shift_policy_url, {
                    }).then(async(response) => {
                        if(response.status == 200) {
                            this.shift_policy.policy_name = response.data.policy_name,
                            this.shift_policy.start_time = response.data.start_time,
                            this.shift_policy.end_time = response.data.end_time,
                            this.shift_policy.overtime_after = response.data.overtime_after,
                            this.shift_policy.grace = response.data.grace
                            //console.log("Shift Policy is: ", this.shift_policy);

                        }
                        else {
                          //console.log("In else");
                            this.isLoading = false
                            this.$toast.error(response.data.message);
                        }
                    }).catch(error => {
                        //console.log(error)
                        this.isLoading = false
                        this.$toast.error(error.response.data.message);
                    });
                    
  
          },
        createShiftPolicy: async function() {
            //console.log("Start time: ", this.start_time);
            let data = {
                policy_name: this.policy_name,
                start_time:  this.start_time + ':' + '00',
                end_time: this.end_time + ':' + '00',
                grace: this.grace,
                overtime_after: this.overtime_after + ':' + '00'
            };
            let config = {
                method: "POST",
                url: this.postURL,
                data: data
            };
            await this.postDataToBackend(config);
            await await this.getDataList(this.setURL);
          },

    }
}