import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            leave_application_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            policy: {},
            emp_code: null,
            emp_id: null,
            employee: {},
            employees: [],
            policies: [],
            leave_balance_data: [],
            leave_start_date: '',
            leave_end_date: '',
            total_leave: null,
            is_half_day: false,
            leave_status: 1,
            day_parts: [],
            day_part: {},
            leave_application_details: {},
            leave_approval_history_list: {},
            isEmployeeSelected: false,
            reason: null,
            delegated_employee_remarks: null
        }
    },
    async mounted() {
        await this.getCurrentUserInfo();
        this.setURL = this.$config.leave.leaveApplicationAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit + '&employee_code='+this.userInfo.emp_code;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_application_url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint + params;
        await this.getDataList(leave_application_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        let config = {
            method: "GET",
            url: emp_url,
            headers: authHeader()
        }
        this.$axios(config).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

        let policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit;
        await this.getPolicyData(policy_url);
        let leave_balance_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.userInfo.emp_code;
        let configLeaveBalance = {
            method: "GET",
            url: leave_balance_url,
            headers: authHeader()
        }
        this.$axios(configLeaveBalance).then((response) => {
            if (response.status == 200) {
                this.leave_balance_data = response.data.data;
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        this.day_parts = [{
                id: 0,
                name: "First Half",
            },
            {
                id: 1,
                name: "Second Half",
            }
        ]
        this.employee.emp_id = this.userInfo.id;
        this.employee.emp_code = this.userInfo.emp_code;
        this.employee.emp_name = this.userInfo.first_name + " " + this.userInfo.last_name;
    },
    async created() {
        document.title = "HrConnect - Employee";
        let policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit;
        await this.getPolicyData(policy_url);
    },
    watch: {
        is_half_day: function() {
            if (this.leave_start_date != null && this.is_half_day == true) {
                this.total_leave = 0.5
                this.leave_end_date = this.leave_start_date
            } else {
                this.total_leave = null
            }
        },
        leave_end_date: function() {
            if (this.leave_start_date != null && this.leave_end_date != null) {
                this.total_leave = (new Date(this.leave_end_date) - new Date(this.leave_start_date)) / (1000 * 60 * 60 * 24) + 1
            }
        },
        employee: function() {
            this.isEmployeeSelected = true
        }
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function  () {
            let params = "?page=" + this.page + "&limit=" + this.limit + '&employee_code='+this.userInfo.emp_code;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_application_url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint + params;
            await this.getDataList(leave_application_url);
        },
        async getPolicyData(url){
            let configPolicy = {
                method: "GET",
                url: url,
                headers: authHeader()
            }
            await this.$axios(configPolicy).then((response) => {
                if (response.status == 200) {
                    // this.policies = response.data.data
                    console.log("policies Data is", response.data.data);
                    const policy = response.data.data
                    this.policies = []
                    for (const key in Object.keys(policy)) {
                        this.policies.push({
                            id: policy[key].id,
                            name: policy[key].leave_title,
                        });
                    }
                  
                   
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        deleteInfo: function(id) {
            this.leave_application_id = id;
        },
        cancelLeaveApplication: async function() {
            //console.log("Canceled Application: ", this.leave_application_details)
            let leave_application_details_url = this.$api + this.$config.leave.leaveApplicationDetails + this.leave_application_id;
           await this.$axios.delete(leave_application_details_url, {
                // employee_id: this.leave_application_details.emp_id,
                // employee_code: this.leave_application_details.emp_code,
                // employee_name: this.leave_application_details.emp_name,
                // leave_policy_id: this.leave_application_details.policy.id,
                // leave_policy_name: this.leave_application_details.policy.name,
                // leave_start_date: this.leave_application_details.leave_start_date,
                // leave_end_date: this.leave_application_details.leave_end_date,
                // is_half_day: this.leave_application_details.is_half_day,
                // leave_status: 5

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.info(response.data.message);
                    let leave_application_url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&employee_code=" + this.userInfo.emp_code;
                    await this.getDataList(leave_application_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateLeaveApplication: async function() {
            let leave_details_url = this.$api + this.$config.leave.leaveApplicationDetails + this.leave_application_id;
            await this.$axios.put(leave_details_url, {
                employee_id: this.leave_application_details.employee.emp_id,
                employee_code: this.leave_application_details.employee.emp_code,
                employee_name: this.leave_application_details.employee.emp_name,
                leave_policy_id: this.leave_application_details.policy.id,
                leave_policy_name: this.leave_application_details.policy.name,
                leave_start_date: this.leave_application_details.leave_start_date,
                leave_end_date: this.leave_application_details.leave_end_date,
                is_half_day: this.leave_application_details.is_half_day,
                leave_status: this.leave_status

            }).then( async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_application_url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_application_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getLeaveapplicationData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            let configLeaveApplication = {
                method: "GET",
                url: emp_url,
                headers: authHeader()
            }
            await this.$axios(configLeaveApplication, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.allDataList = []
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.leave_application_id = id;
            let leave_application_details_url = this.$api + this.$config.leave.leaveApplicationDetails + this.leave_application_id;
            let configEditLeaveApplication = {
                method: "GET",
                url: leave_application_details_url,
                headers: authHeader()
            }
            await this.$axios(configEditLeaveApplication, {}).then((response) => {
                if (response.status == 200) {

                    this.leave_application_details.emp_id = response.data.employee_id;
                    this.leave_application_details.emp_code = response.data.employee_code;
                    this.leave_application_details.emp_name = response.data.employee_name;
                    this.leave_application_details.leave_policy_id = response.data.leave_policy_id;
                    this.leave_application_details.leave_policy_name = response.data.leave_policy_name;
                    this.leave_application_details.leave_start_date = response.data.leave_start_date;
                    this.leave_application_details.leave_end_date = response.data.leave_end_date;
                    this.leave_application_details.total_days = response.data.total_leave_day;
                    this.leave_application_details.is_half_day = response.data.is_half_day;
                    this.leave_application_details.delegated_employee_remarks = response.data.leave_reason,
                    this.leave_application_details.leave_status = response.data.leave_status == 0 ? "Draft" : response.data.leave_status == 1 ? "Pending" : response.data.leave_status == 2 ? "In Progress" : response.data.leave_status == 3 ? "Approved" : response.data.leave_status == 4 ? "Rejected" : response.data.leave_status == 5 ? "Cancelled" : "Draft";

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            let leave_approval_history = this.$api + this.$config.leave.leaveApplicationApprovalAPIEndPoint + "?leave_application_id=" + this.leave_application_id;
            let configApproveLeaveApplication = {
                method: "GET",
                url: leave_approval_history,
                headers: authHeader()
            }
            await this.$axios(configApproveLeaveApplication, {}).then((response) => {
                if (response.status == 200) {
                    // //console.log("Data is", response.data);
                    this.leave_approval_history_list = response.data.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createLeaveApplication: async function() {
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint
            if (this.is_half_day == true) {
                this.leave_end_date = this.leave_start_date
            }
            let application_data = {
                employee_id: this.employee.emp_id,
                employee_code: this.employee.emp_code,
                employee_name: this.employee.emp_name,
                leave_policy_id: this.policy.id,
                leave_policy_name: this.policy.name,
                leave_start_date: this.leave_start_date,
                leave_end_date: this.leave_end_date,
                is_half_day: this.is_half_day,
                day_part: this.day_part.id,
                leave_status: this.leave_status,
                leave_reason: this.reason
            }
            let config = {
                method: "POST",
                data: application_data,
                url: url,
                headers: authHeader()
            }
            await this.$axios(config).then(async(response) => {
                if (response.status == 201) {
                    this.$toast.success(response.data.message);
                    this.allDataList = []


                    let leave_balance_url = this.$api + this.$config.leave.leaveApplicationAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + '&employee_code='+this.userInfo.emp_code;

                    //let leave_balance_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.userInfo.emp_code;
                    await this.getDataList(leave_balance_url);

                     this.employee = ''
                     this.policy = ''
                     this.leave_start_date = ''
                     this.leave_end_date =''
                     this.is_half_day =''
                     this.day_part.id =''
                     this.leave_status =''
                     this.reason = ''
                    
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                this.isLoading = false
                const _this = this
                console.log("test error: " + error);
                if (error.response.status == 400) {
                    this.$toast.error(error.response.data.message);
                }
                else if (error.response.status == 500) {
                    this.$toast.error("Failed to process your request!");
                }
                else {
                    // //console.log('Errors Key are: ', Object.keys(error.response.data.errors))
                    Object.keys(error.response.data.errors).forEach(function(value) {
                        //console.log("Value is: ", value, "key is: ", key)
                        _this.$toast.error(error.response.data.errors[value][0]);
                    });
                }
            });
        },

    }
}