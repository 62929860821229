<template>
    <div class="page-wrapper">
        <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />

            <h6 class="mb-0 text-uppercase">Calender</h6>
            <br>
            <div class="card-body">
                <div id='calendar'></div>
            </div>

        </div>


    </div>
</template>
<style>
@import '../../../../../public/core/assets/plugins/fullcalendar/css/main.min.css';
</style>
<script src="../js/company_calender.js"></script>