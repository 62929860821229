import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            branch_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            name: '',
            companies: [],
            company: {},
            branch_details: {

            }
        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint + params;
        this.getDataList(branch_url);
        let company_url = this.$api + this.$config.configurations.companyAPIEndPoint;
        let config = {
            method: "GET",
            url: company_url,
            headers: authHeader()
        }
        this.$axios(config).then(async(response) => {
            if (response.status == 200) {
                //console.log("Data is", response.data);
                // this.company.id = response.data.data.id;
                // this.company.name = response.data.data.name;
                for (const key in Object.keys(response.data)) {
                    this.companies.push({
                        id: response.data[key].id,
                        name: response.data[key].company_name
                    });
                }
                //console.log("All companies data list: ", this.companies);
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        deleteInfo: async function(id) {
            this.branch_id = id;
        },
        deleteBranch: async function() {
            let branch_details_url = this.$api + this.$config.configurations.branchDetails + this.branch_id;
            await this.$axios.delete(branch_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(branch_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error){
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateBranch: async function() {
            //console.log("cmployee Details is: ", this.branch_details);
            let branch_details_url = this.$api + this.$config.configurations.branchDetails + this.branch_id;
            await this.$axios.put(branch_details_url, {
                company_id: this.branch_details.company.id,
                company_name: this.branch_details.company.name,
                name: this.branch_details.name,

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(branch_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getCompanyData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.branch_id = id;
            let branch_details_url = this.$api + this.$config.configurations.branchDetails + this.branch_id;
            //console.log(branch_details_url);
            await await this.$axios.get(branch_details_url, {}).then(async(response) => {
                if (response.status == 200) {
                    this.branch_details.name = response.data.name;
                    this.branch_details.company = {
                        id: response.data.company_id,
                        name: response.data.company_name
                    }

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createBranch: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.branchAPIEndPoint
            await this.$axios.post(url, {
                company_id: this.company.id,
                company_name: this.company.name,
                name: this.name,
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("Company Created Successfully");
                    this.$toast.success(response.data.message);
                    let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(branch_url)
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint + params;
            await this.getDataList(branch_url);
        }

    }
}