<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Leave Application History
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Apply Leave Application
            </button>
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee Leave Application History</h6>
      <hr />
      
      <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4">
        <template  v-if="Object.keys(leave_balance_data).length > 0">
          <div class="col"  v-for="(li, index) in leave_balance_data" :key="index">
            <div class="card radius-10">
              <div class="card-body" style="position: relative;">
                <div class="d-flex align-items-center">
                  <div>
                    <p class="mb-0">{{ li.leave_policy_name }}</p>
                    <h5 class="mb-0">{{ li.leave_avail_days }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        
      </div>
      
      <div class="card" v-if="Object.keys(this.allDataList).length > 0">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm mt-1"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start ms-2"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm mt-1"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  @click="searchData"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee name</th>
                  <th>Leave Policy Name</th>
                  <th>Leave Start Date</th>
                  <th>Leave End Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(this.allDataList).length > 0">
                <tr v-for="(li, index) in this.allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.employee_name }}</td>
                  <td>{{ li.leave_policy_name }}</td>
                  <td>{{ li.leave_start_date }}</td>
                  <td>{{ li.leave_end_date }}</td>
                  <td>{{li.leave_status == 0 ? "Draft" : li.leave_status == 1 ? "Pending" : li.leave_status == 2 ? "In Progress" : li.leave_status == 3 ? "Approved" : li.leave_status == 4 ? "Rejected" : li.leave_status == 5 ? "Cancelled" : "Draft"}}</td>

                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        v-if="
                          li.leave_status == 0 ||
                          li.leave_status == 1 ||
                          li.leave_status == 2
                        "
                        class="btn btn-danger btn-sm btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleVerticallycenteredModal"
                        @click="deleteInfo(li.id)"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        v-else
                        class="btn btn-secondary btn-sm btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleVerticallycenteredModal"
                        disabled
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Leave Application Creation</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#primaryhome"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Leave Application</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#primaryprofile"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Leave Balance</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="primaryhome"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div
                        class="col-md-4"
                        v-if="userInfo.role_name == 'Admin'"
                      >
                        <label for="inputState" class="form-label"
                          >Employee Code</label
                        >
                        <select
                          v-model="employee"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in employees"
                            :key="key"
                            :value="option"
                          >
                            {{ option.emp_code }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="col-md-4"
                        v-if="userInfo.role_name == 'Admin'"
                      >
                        <label for="inputState" class="form-label"
                          >Employee Name<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="employee"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in employees"
                            :key="key"
                            :value="option"
                          >
                            {{ option.emp_name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Leave Policy<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="policy"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in policies"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>

                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Half Day</label
                        >
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            v-model="is_half_day"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                            >Yes/No</label
                          >
                        </div>
                      </div>
                      <div class="col-md-6" v-if="is_half_day == true">
                        <label for="inputEmail" class="form-label"
                          >Day Part<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <select
                          v-model="day_part"
                          id="inputState"
                          class="form-select single-select"
                        >
                          <option
                            v-for="(option, key) in day_parts"
                            :key="key"
                            :value="option"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label for="inputPhone" class="form-label"
                          >Leave Start Date<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="date"
                          v-model="leave_start_date"
                          :min="new Date().toISOString().split('T')[0]"
                          class="form-control"
                          id="inputPhone"
                        />
                      </div>
                      <div class="col-md-4" v-if="is_half_day == false">
                        <label for="inputEmail" class="form-label"
                          >Leave End Date<span
                            style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <input
                          type="date"
                          v-model="leave_end_date"
                          :min="new Date().toISOString().split('T')[0]"
                          class="form-control"
                          id="inputEmail"
                        />
                      </div>
                      <div class="col-md-12">
                        <label for="inputState" class="form-label"
                          >Leave Reason<span style="color: red; font-size: 18px"
                            >*</span
                          ></label
                        >
                        <textarea
                          class="form-control"
                          v-model="reason"
                          id="reason"
                          placeholder="Enter Reason for Leave"
                        >
                        </textarea>
                      </div>
                      <div class="col-md-6" v-if="total_leave">
                        <label for="inputEmail" class="form-label"
                          >Total leave Days</label
                        >
                        <input
                          type="text"
                          v-model="total_leave"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="primaryprofile"
                    role="tabpanel"
                  >
                    <div class="table-responsive overflow-hidden">
                      <!-- <div class="row mb-3">
														<div class="col-sm-12 col-md-6">
															<div class="dataTables_length" id="example_length">
														<label>Show <select v-model="limit" name="example_length" aria-controls="example" class="form-select form-select-sm">
															<option v-for="(option, key) in pageLimit" :key="key" :value="option">{{option}}</option>
															</select>
															</label>
															</div>
															</div>
															<div class="col-sm-12 col-md-6">
																<div id="example_filter" class="dataTables_filter">
																	<label>Search:<input type="search" v-model="search" class="form-control form-control-sm" placeholder="" aria-controls="example">
																	</label>
																	<button type="button" class="btn btn-primary px-4 radius-30 ms-2">Go</button>
																</div>
																
															</div>
															
													</div> -->
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Leave Policy Name</th>
                            <th>Total Leave Days</th>
                            <th>Total Avail Days</th>
                            <th>Total Leave Balance</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="Object.keys(leave_balance_data).length > 0"
                        >
                          <tr
                            v-for="(li, index) in leave_balance_data"
                            :key="index"
                          >
                            <td>{{ li.leave_policy_name }}</td>
                            <td>{{ li.leave_total_days }}</td>
                            <td>{{ li.leave_avail_days }}</td>
                            <td>{{ li.leave_balance }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              v-on:click="createLeaveApplication()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">View Leave Application</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body ">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#leaveApplication"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Leave Application</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#leaveHistory"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Leave Approval History</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="leaveApplication"
                    role="tabpanel"
                  >
                    <form class="row g-3">
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Employee Code</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.emp_code"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Leave Policy</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.leave_policy_name"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Leave Start Date</label
                        >
                        <input
                          type="date"
                          v-model="leave_application_details.leave_start_date"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Leave End Date</label
                        >
                        <input
                          type="date"
                          v-model="leave_application_details.leave_end_date"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputState" class="form-label"
                          >Total Leave Days</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.total_days"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-3">
                        <label for="inputEmail" class="form-label"
                          >Half Day</label
                        >
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            v-model="leave_application_details.is_half_day"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            disabled
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                            >Yes/No</label
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label for="inputState" class="form-label"
                          >Status</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.leave_status"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Remarks</label
                        >
                        <textarea
                          class="form-control"
                          style="resize: none"
                          v-model="leave_application_details.delegated_employee_remarks"
                          id="inputEmail"
                          disabled
                        >
                        </textarea>
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="leaveHistory" role="tabpanel">
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Employee Name</th>
                            <th>Delegated Employee Code</th>
                            <th>Delegated Employee Name</th>
                            <th>Delegated Employee Status</th>
                            <th>Delegated Employee Remarks</th>
                            <th>Step No</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            Object.keys(leave_approval_history_list).length > 0
                          "
                        >
                          <tr
                            v-for="(li, index) in leave_approval_history_list"
                            :key="index"
                          >
                            <td>{{ li.employee_name }}</td>
                            <td>{{ li.delegated_employee_code }}</td>
                            <td>{{ li.delegated_employee_name }}</td>
                            <td>
                              {{
                                li.delegated_employee_status == ""
                                  ? "Pending"
                                  : li.delegated_employee_status
                              }}
                            </td>
                            <td>{{ li.delegated_employee_remarks }}</td>
                            <td>{{ li.step_num }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" v-on:click="updateLeaveApplication()" data-bs-dismiss="modal" class="btn btn-primary">Update Leave application</button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="cancelLeaveApplication()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/leave_application.js"></script>