import Loading from "vue-loading-overlay";
import html2pdf from 'html2pdf.js';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            salary_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            pdfSection: false,
            employees: [],
            salaries: [],
            salary_issue_mediums: [],
            months: [{
                    id: "01",
                    name: 'January'
                },
                {
                    id: "02",
                    name: 'February'
                },
                {
                    id: "03",
                    name: 'March'
                },
                {
                    id: "04",
                    name: 'April'
                },
                {
                    id: "05",
                    name: 'May'
                },
                {
                    id: "06",
                    name: 'June'
                },
                {
                    id: "07",
                    name: 'July'
                },
                {
                    id: "08",
                    name: 'August'
                },
                {
                    id: "09",
                    name: 'September'
                },
                {
                    id: "10",
                    name: 'October'
                },
                {
                    id: "11",
                    name: 'November'
                },
                {
                    id: "12",
                    name: 'December'
                }
            ],
            taxes: [],
            salary: {},
            employee: {},
            tax: {},
            account_details: {},
            basic_salary: null,
            gross_salary: null,
            salary_issue_date: '',
            salary_issue_month: '',

            salary_details: {
                employee: {},
                salary: {
                    salary_issue_medium: {}
                },
                tax: {},
                basic_salary: '',
                gross_salary: '',
                salary_issue_date: '',
                salary_issue_medium: ''
            },

        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let url = this.$api + this.$config.payroll.SalaryAPIEndPoint + params;
        this.getDataList(url);
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        this.$axios.get(salary_grade_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.salaries.push({
                        id: response.data.data[key].id,
                        salary_code: response.data.data[key].salary_code
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

        this.salary_issue_mediums = [{
                id: 0,
                name: "Cheque"
            },
            {
                id: 1,
                name: "Cash"
            },
            {
                id: 2,
                name: "Bank Account"
            }
        ];
        let income_tax_url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        this.$axios.get(income_tax_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.taxes.push({
                        id: response.data.data[key].id,
                        tax_code: response.data.data[key].tax_code
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        // emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
    },
    created() {
        document.title = "HrConnect - Payroll";

    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let url = this.$api + this.$config.payroll.SalaryAPIEndPoint + params;
        await this.getDataList(url);
        },
        deleteInfo: function(id) {
            this.salary_id = id;
        },

        deleteSalary: async function() {
            let salary_details_url = this.$api + this.$config.payroll.SalaryDetails + this.salary_id;
            await this.$axios.delete(salary_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let url = this.$api + this.$config.payroll.SalaryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error);
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateSalary: async function() {
            //console.log("Salary Details is: ", this.salary_details);
            let salary_details_url = this.$api + this.$config.payroll.SalaryDetails + this.salary_id;
            //console.log(salary_details_url);
            let updated_data = {
                emp_id: this.salary_details.employee.emp_id,
                emp_code: this.salary_details.employee.emp_code,
                salary_code: this.salary_details.salary.salary_code,
                salary_grade: this.salary_details.salary.id,
                gross_salary: this.salary_details.gross_salary,
                basic_salary: this.salary_details.basic_salary,
                net_payable: 25000,
                tax_id: this.salary_details.tax.id,
                tax_code: this.salary_details.tax.tax_code,
                salary_issue_date: this.salary_details.salary_issue_date,
                salary_issue_medium: this.salary_details.salary_issue_medium.id,
                total_cost_deducted: 1500
            }
            let config = {
                method: "PUT",
                url: salary_details_url,
                data: updated_data
            }

            await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let url = this.$api + this.$config.payroll.SalaryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(url);
                } else {
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        downloadPDF: async function() {
            var element = document.getElementById('bodyTable');
            var opt = {
                filename: 'payslip.pdf',
            };

            let worker = html2pdf().set(opt).from(element).save();
            worker.save();
        },
        editForm: async function(id, emp_code) {
            this.salary_id = id;
            //console.log("Salary Id: ", this.salary_id, id);
            let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + emp_code;
            this.$axios.get(account_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.account_details = response.data.data[0];
                    //console.log("Account Details: ", this.account_details);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            let salary_url = this.$api + this.$config.payroll.SalaryDetails + this.salary_id;
            //console.log(id, emp_code);
            await this.$axios.get(salary_url, {}).then((response) => {
                if (response.status == 200) {
                    this.salary_details.employee = {
                        emp_id: response.data.salary.emp_id,
                        emp_code: response.data.salary.emp_code
                    };
                    // this.salary_details.tax = {
                    //     id: response.data.salary.tax_id,
                    //     tax_code: response.data.salary.tax_code
                    // };
                    this.salary_details.salary = {
                        id: response.data?.salary_grade.id,
                        salary_code: response.data?.salary_grade?.salary_code
                    };
                    this.salary_details.basic_salary = response.data?.salary_grade?.basic_salary;
                    this.salary_details.gross_salary = response.data?.salary?.gross_salary;
                    this.salary_details.net_payable = response.data?.salary?.net_payable;
                    this.salary_details.total_cost_deducted = response.data?.salary?.total_cost_deducted;
                    this.salary_details.salary_issue_date = response.data?.salary?.salary_issue_date;

                    if (response.data.salary.salary_issue_medium == 0) {
                        this.salary_details.salary.salary_issue_medium = {
                            id: 0,
                            name: "Cash"
                        };
                    } else if (response.data.salary.salary_issue_medium == 1) {
                        this.salary_details.salary.salary_issue_medium = {
                            id: 1,
                            name: "Cheque"
                        };
                    } else if (response.data.salary.salary_issue_medium == 2) {
                        this.salary_details.salary_issue_medium = {
                            id: 2,
                            name: "Bank Account"
                        };
                    }
                    //console.log("this.salary_details",this.salary_details);

                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        createSalary: async function() {
            //console.log("Salary Creation Method");
            let url = this.$api + this.$config.reporting.generateSalaryAPI;
            let salary_data = {
                salary_issue_date: this.salary_issue_date,
                salary_month: this.salary_issue_month.id
            }
            let config = {
                url: url,
                data: salary_data,
                method: "POST"
            }
            await this.postDataToBackend(config);
            this.salary_issue_date = '';
            let salary_url = this.$api + this.$config.payroll.SalaryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(salary_url);
        },

    }
}