import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            leaveDataList: [],
            leaveEditDataList: [],
            leaveData: [],
            cmp_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            policy: {},
            emp_code: null,
            emp_id: null,
            employee: {},
            employees: [],
            policies: [],
            editPolicies: [],
            leave_total_days: null,
            leave_avail_days: null,
            leave_balance: null,
            leave_balance_details: {},
            tableRows: [{
                leavePolicy: '',
                leaveTotalDays: 0,
                leaveAvailDays: 0,
                leaveBalance: 0,
            }],
            currentIndex: 0,

        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_balance_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + params;
        // this.getData(leave_balance_url);
        this.getData(leave_balance_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        let configEmp = {
            method: "GET",
            url: emp_url,
            headers: authHeader()
        }
        this.$axios(configEmp).then((response) => {
            if (response.status == 200) {
                //console.log("Data is", response.data);
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let leave_policy_url = this.$api + this.$config.leave.leavePolicyAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit;
        let configPolicy = {
            method: "GET",
            url: leave_policy_url,
            headers: authHeader()
        }
        this.$axios(configPolicy).then((response) => {
            if (response.status == 200) {
                const policy = response.data.data
                for (const key in Object.keys(policy)) {
                    this.policies.push({
                        id: policy[key].id,
                        name: policy[key].leave_title,
                        total_days: policy[key].total_days,
                    });
                    this.editPolicies.push({
                        id: policy[key].id,
                        name: policy[key].leave_title
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Leave";
    },
    methods: {
        addNewRow() {
            this.tableRows.push({
                leavePolicy: '',
                leaveTotalDays: 0,
                leaveAvailDays: 0,
                leaveBalance: 0,
            });
            this.currentIndex++
        },
        deleteRow(index) {
            this.tableRows.splice(index, 1);
            this.currentIndex--
        },
        changeLeavePolicy(row){
            row.leaveTotalDays = row.leavePolicy.total_days
            row.leaveAvailDays = row.leavePolicy.total_days
        },
        changeLeaveBalance(row){            

            var leave_total_days = row.leaveTotalDays;
            var leaveBalance = row.leaveBalance;

            // console.log(`leave_total_days`, leave_total_days);
            // console.log(`leaveBalance`, leaveBalance);

            if(leave_total_days < leaveBalance){
                this.$toast.error("Please input a valid balance");
                row.leaveBalance = 0
                row.leaveAvailDays = 0
                return false;
            }
            //if((leave_total_days) && (leaveBalance)){
                var total = leave_total_days - leaveBalance
                row.leaveAvailDays = total
            //}
        },
        async getData(endPoint,action='') {
            try {
              let configPolicy = {
                method: "GET",
                url: endPoint,
                headers: authHeader(),
              };
          
              const response = await this.$axios(configPolicy);
          
              if (response.status === 200) {
                const leaveData = response.data.data;
                const last_page = response.data.last_page;
                // console.log("last_page==", last_page);
                // console.log("leaveData==", leaveData);
                // console.log("action==", action);
                
                this.leaveData.push(leaveData);
                
                this.page++;
                if(action !="search"){
                    if (this.page <= last_page) {
                    let params = `?page=${this.page}&limit=${this.limit}`;
            
                    if (this.search) {
                        params += `&search=${this.search}`;
                    }
            
                    let leave_balance_url =
                        this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + params;
            
                    // Recursively call getData for the next page
                    await this.getData(leave_balance_url);
                    }
                } else{
                    console.log(leaveData);
                    this.dataCalculator(leaveData,'search')  
                }
                if(action !="search"){
                    if (this.page == (last_page+1)) {
                        this.dataCalculator(this.leaveData,'all')  
                    }
                }

              } else {
                this.isLoading = false;
              }
            } catch (error) {
              this.isLoading = false;
              this.$toast.error(
                error.response ? error.response.data.message : "An error occurred."
              );
            }
          },
          
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            this.page = 1;
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_balance_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + params;
            if(this.search){
                await this.getData(leave_balance_url,'search');
            } else {
                await this.getData(leave_balance_url,'');
            }
        },
        dataCalculator(leaveData,action = ''){
            const employeesData = {};
                // console.log("leaveData",leaveData)
                
                if(action =="search"){
                    leaveData.forEach((employee) => {
                        const {
                            employee_id,
                            employee_code,
                            employee_name,
                            created,
                            updated,
                            deleted,
                        } = employee;
                
                        if (!employeesData[employee_code]) {
                            employeesData[employee_code] = {
                            employee_id,
                            employee_code,
                            employee_name,
                            created,
                            updated,
                            deleted,
                            };
                        }
                
                        // const leavePolicyIndex = Object.keys(employeesData[employee_code])
                            //     .filter((key) => key.startsWith("leave_policy_id")).length;
                            
                            //     console.log(leavePolicyIndex);
                            let leavePolicyIndexNo = 0;
                            if(employee.leave_policy_id == 1){
                                leavePolicyIndexNo = 1;
                            } else if(employee.leave_policy_id == 2){
                                leavePolicyIndexNo = 2;
                            } else if(employee.leave_policy_id == 6){
                                leavePolicyIndexNo = 0;
                            }
                        employeesData[employee_code][`id_${leavePolicyIndexNo}`] = employee.id;
                        employeesData[employee_code][`leave_policy_id_${leavePolicyIndexNo}`] = employee.leave_policy_id;
                        employeesData[employee_code][`leave_policy_name_${leavePolicyIndexNo}`] = employee.leave_policy_name;
                        employeesData[employee_code][`leave_total_days_${leavePolicyIndexNo}`] = employee.leave_total_days;
                        employeesData[employee_code][`leave_avail_days_${leavePolicyIndexNo}`] = employee.leave_avail_days;
                        employeesData[employee_code][`leave_balance_${leavePolicyIndexNo}`] = employee.leave_balance;
                    });
                }
                if(action !="search"){

                    leaveData.forEach((leaveList) => {
                        leaveList.forEach((employee) => {
                            const {
                                employee_id,
                                employee_code,
                                employee_name,
                                created,
                                updated,
                                deleted,
                            } = employee;

                            if (!employeesData[employee_code]) {
                                employeesData[employee_code] = {
                                    employee_id,
                                    employee_code,
                                    employee_name,
                                    created,
                                    updated,
                                    deleted,
                                };
                            }

                            // const leavePolicyIndex = Object.keys(employeesData[employee_code])
                            //     .filter((key) => key.startsWith("leave_policy_id")).length;
                            
                            //     console.log(leavePolicyIndex);
                                let leavePolicyIndexNo = 0;
                                if(employee.leave_policy_id == 1){
                                    leavePolicyIndexNo = 1;
                                } else if(employee.leave_policy_id == 2){
                                    leavePolicyIndexNo = 2;
                                } else if(employee.leave_policy_id == 6){
                                    leavePolicyIndexNo = 0;
                                }
                            employeesData[employee_code][`id_${leavePolicyIndexNo}`] = employee.id;
                            employeesData[employee_code][`leave_policy_id_${leavePolicyIndexNo}`] = employee.leave_policy_id;
                            employeesData[employee_code][`leave_policy_name_${leavePolicyIndexNo}`] = employee.leave_policy_name;
                            employeesData[employee_code][`leave_total_days_${leavePolicyIndexNo}`] = employee.leave_total_days;
                            employeesData[employee_code][`leave_avail_days_${leavePolicyIndexNo}`] = employee.leave_avail_days;
                            employeesData[employee_code][`leave_balance_${leavePolicyIndexNo}`] = employee.leave_balance;
                        });
                    });
                }
                const result = Object.values(employeesData);
                this.leaveDataList = (result);
                // console.log(this.leaveDataList);
        },
        deleteInfo: function (id) {
            this.cmp_id = id;
        },
        deleteLeaveBalance: async function () {
            let leave_balance_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + this.cmp_id;
            await this.$axios.delete(leave_balance_details_url, {

            }).then((response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.$router.go();
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error){
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateLeaveBalance: async function () {
            console.log("Update Leave Balance", this.leaveEditDataList);
        
            for (let index = 0; index < 5; index++) {
                const sysID = "id_" + index;
        
                if (this.leaveEditDataList[sysID]) {
                    const leaveID = this.leaveEditDataList[sysID];
        
                    if (!leaveID) {
                        return;
                    }
        
                    console.log("leaveID", leaveID);
        
                    // const leavePolicy = {
                    //     id: this.leaveEditDataList[`leave_policy_id_${index}`],
                    //     name: this.leaveEditDataList[`leave_policy_name_${index}`],
                    //     total_days: this.leaveEditDataList[`leave_total_days_${index}`],
                    //     avail_days: this.leaveEditDataList[`leave_avail_days_${index}`],
                    //     balance: this.leaveEditDataList[`leave_balance_${index}`],
                    // };
        
                    // const data = {
                    //     employee_id: this.leaveEditDataList.employee_id,
                    //     employee_code: this.leaveEditDataList.employee_code,
                    //     employee_name: this.leaveEditDataList.employee_name,
                    //     leave_policy: leavePolicy,
                    // };

                    var data =  {
                        employee_id: this.leaveEditDataList['employee_id'],
                        employee_code: this.leaveEditDataList['employee_code'],
                        employee_name: this.leaveEditDataList['employee_name'],
                        leave_policy_id: this.leaveEditDataList[`leave_policy_id_${index}`],
                        leave_policy_name: this.leaveEditDataList[`leave_policy_name_${index}`],
                        leave_total_days: this.leaveEditDataList[`leave_total_days_${index}`],
                        leave_avail_days: this.leaveEditDataList[`leave_avail_days_${index}`],
                        leave_balance: this.leaveEditDataList[`leave_balance_${index}`]

                    }
        
                    const cmp_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + leaveID;
        
                    const configPolicy = {
                        method: "PUT",
                        url: cmp_details_url,
                        data: data,
                        headers: authHeader(),
                    };
        
                    try {
                        const response = await this.$axios(configPolicy);
        
                        if (response.status === 200) {
                            this.$toast.success(response.data.message);
                            // const leave_approval_steps_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit;
                            // await this.getData(leave_approval_steps_url);
                        } else {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        this.isLoading = false;
                        this.$toast.error(error.response.data.message);
                    }
                }
            } 
            window.location.reload();
        },          
        getLeaveBalanceData: async function (url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function (id) {
            const employeesData = {};
            
            for (let index = 0; index < 5; index++) {
                const sysID = "id_" + index;
        
                if (id[sysID]) {
                    const leaveID = id[sysID];
                    this.cmp_id = leaveID;
        
                    if (!leaveID) {
                        return;
                    }
        
                    let leave_balance_details_url = this.$api + this.$config.leave.employeeLeaveBalanceDetails + leaveID;
        
                    try {
                        const response = await this.$axios.get(leave_balance_details_url, {});
        
                        if (response.status === 200) {
                            const dataObj = response.data;
        
                            const {
                                employee_id,
                                employee_code,
                                employee_name,
                                created,
                                updated,
                                deleted,
                                leave_policy_id,
                                leave_policy_name,
                                leave_total_days,
                                leave_avail_days,
                                leave_balance,
                            } = dataObj;
        
                            if (!employeesData[employee_code]) {
                                employeesData[employee_code] = {
                                    employee_id,
                                    employee_code,
                                    employee_name,
                                    created,
                                    updated,
                                    deleted,
                                };
                            }
        
                            let leavePolicyIndexNo = 0;
                            if (leave_policy_id === 1) {
                                leavePolicyIndexNo = 1;
                            } else if (leave_policy_id === 2) {
                                leavePolicyIndexNo = 2;
                            } else if (leave_policy_id === 6) {
                                leavePolicyIndexNo = 0;
                            }
        
                            employeesData[employee_code][`id_${leavePolicyIndexNo}`] = dataObj.id;
                            employeesData[employee_code][`leave_policy_id_${leavePolicyIndexNo}`] = leave_policy_id;
                            employeesData[employee_code][`leave_policy_name_${leavePolicyIndexNo}`] = leave_policy_name;
                            employeesData[employee_code][`leave_total_days_${leavePolicyIndexNo}`] = leave_total_days;
                            employeesData[employee_code][`leave_avail_days_${leavePolicyIndexNo}`] = leave_avail_days;
                            employeesData[employee_code][`leave_balance_${leavePolicyIndexNo}`] = leave_balance;
                        } else {
                            this.isLoading = false;
                        }
                    } catch (error) {
                        this.isLoading = false;
                        this.$toast.error(error.response.data.message);
                    }
                }
            }
        
            this.leaveEditDataList = Object.values(employeesData)[0];
            // console.log("this.leaveEditDataList======", this.leaveEditDataList);
        },
        
        createLeaveBalance: async function () {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint
            var tableRows = this.tableRows;

            let i= 0;
            for(let row of tableRows){
                i++;
                var data =  {
                    employee_id: this.employee.emp_id,
                    employee_code: this.employee.emp_code,
                    employee_name: this.employee.emp_name,
                    leave_policy_id: row.leavePolicy.id,
                    leave_policy_name: row.leavePolicy.name,
                    leave_total_days: row.leaveTotalDays,
                    leave_avail_days: row.leaveAvailDays,
                    leave_balance: row.leaveBalance
                }
                let config = {
                    method: "POST",
                    url: url,
                    data: data,
                    headers: authHeader()
                }
                await this.$axios(config).then(async(response) => {
                    if (response.status == 201) {
                        //console.log("leave_balance Created Successfully");
                        if(tableRows.length == i){
                            // this.$toast.success(response.data.message);
                            this.$toast.success("Leave has been saved successfully");
                            let leave_balance_url = this.$api + this.$config.leave.employeeLeaveBalanceAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                            await this.getData(leave_balance_url);
                            this.employee = ''
                            this.tableRows= [{
                                leavePolicy: '',
                                leaveTotalDays: '',
                                leaveAvailDays: '',
                                leaveBalance: ''
                            }]
                        }
                    } else {
                        this.isLoading = false
                    }
                }).catch(error => {
                    //console.log("Error is ", error.response)
                    this.isLoading = false
                    this.$toast.error(error.response.data.message);
                });
            }
            // this.$router.go();
        },
        leaveBalanceAmount(){
            //console.log("leaveBalanceAmount", this.leave_balance_details);
            var amount = this.leave_balance_details.leave_total_days - this.leave_balance_details.leave_balance
            this.leave_balance_details.leave_avail_days = amount
        }
    }
}