import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            employee: {},
            start_date: '',
            end_date: '',
            events: []
        }
    },
    beforeCreate() {
        var scripts = [
            "/core/assets/plugins/fullcalendar/js/main.min.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
            let fullcalendarScript = document.createElement('script')
            fullcalendarScript.setAttribute('src', 'http://localhost:8080/core/assets/plugins/fullcalendar/js/main.min.js')
            document.head.appendChild(fullcalendarScript);

            let fullCalLoadScript = document.createElement('script')
            fullCalLoadScript.setAttribute('src', 'http://localhost:8080/core/assets/js/app-fullcalendar.js')
            document.head.appendChild(fullCalLoadScript);
        });
    },
    mounted() {
        this.getTodayLeave();
    },
    created() {
        document.title = "HrConnect - Reporting";
        
    },
    methods: {
        generateEmployeeAttendance: async function() {
            let url = this.$api + this.$config.reporting.employeeAttendance + "?emp_code=" + this.employee.emp_code + "&start_date=" + this.start_date + "&end_date=" + this.end_date;
            let config = {
                method: "GET",
                url: url,
                responseType: 'blob'
            }
            let filename = this.employee.emp_name.replace(/ /g, "") + '_attendance.csv';
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);
                
                    fileLink.click();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getTodayLeave: async function() {
            localStorage.setItem('flag', 'company');
            let url = this.$api + this.$config.reporting.todayLeave;
            let config = {
                method: "GET",
                url: url
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    let company_data = response.data;
                    for(const key in Object.keys(company_data)){
                        this.events.push({
                            title: company_data[key].employee_name.split(" ")[0] + ", EC: " + company_data[key].employee_code,
                            start: company_data[key].date
                        })
                    }
                    localStorage.setItem('leave_events', JSON.stringify(this.events));
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        }
        

    }
}