import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import Mixin from "../../../mixin";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            leave_application_list: [],
            backend_url: '',
            events: [],
            dashboardData: {}
        }
    },
    mixins: [Mixin],
    async created() {
        document.title = "HrConnect - Dashboard";
        localStorage.setItem('flag', 'dash');
        await this.getCurrentUserInfo();
        await this.getDashboardData();
        var scripts = [
            "/core/assets/plugins/fullcalendar/js/main.min.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    mounted() {
        let fullcalendarScript = document.createElement('script')
        fullcalendarScript.setAttribute('src', this.$base +'core/assets/plugins/fullcalendar/js/main.min.js')
        document.head.appendChild(fullcalendarScript);

        let fullCalLoadScript = document.createElement('script')
        fullCalLoadScript.setAttribute('src', this.$base + 'core/assets/js/app-fullcalendar.js')
        document.head.appendChild(fullCalLoadScript);
        this.backend_url = this.$backend
    },
    methods: {
        getDashboardData: async function() {
            
            this.isLoading = true;
            //console.log("User Data in dashboard: ", this.userInfo)
            let config = {
                method: "GET",
                url: this.$api + this.$config.dashboard.getDashboardData + this.userInfo.emp_code,
                headers: authHeader()
            };

            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.employee_info;
                    this.leave_application_list = response.data.leave_application_info;
                    let company_data = response.data.company_calendar_info;
                    this.dashboardData = response.data;
                    // let leave_event = response.data.leave_days;
                    for(const key in Object.keys(company_data)){
                        this.events.push({
                            title: company_data[key].day_type,
                            start: company_data[key].date
                        })
                    }
                    // for(const key in Object.keys(leave_event)){
                    //     this.events.push({
                    //         title: "Leave Day",
                    //         start: leave_event[key]
                    //     })
                    // }
                    // console.log("Events are: ", this.events);
                    localStorage.setItem('events', JSON.stringify(this.events));
                }
                this.isLoading = false;
            }).catch(error => {
                //console.log(error);
                this.isLoading = false;
                if (error.response.status == 401) {
                    this.redirectToLoginPage();
                } else {
                    this.$toast.error(error.response.data.message);
                }
            });
        }
    }
}