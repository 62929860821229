import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            email: null,
        }
    },
    created() {
        document.title = "HR Connect - Forgot Password";
    },
    methods: {
        submit: async function() {
            this.isLoading = true;
            if (!this.email) {
                this.$toast.error("Please enter your email!");
                this.isLoading = false;
            } else {
                // this.$router.push('/reset-password')
                let data = {
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.$api + this.$config.auth.forgotPassword,
                    data: data
                };
                await this.$axios(config).then(async (response) => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$toast.success(response.data.message);
                        this.$router.push("/");                       
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
            }
        },
    }
}