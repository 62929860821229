import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/routes';
import axios from 'axios';
import Toaster from "@meforma/vue-toaster";
import mixins from './mixin';
import moment from 'moment';
import jsonData from '../server/server.json';

const app = createApp(App);

/* Vue Router */

/* Vue Router */
// app.$config = require('./config/api.json');
const vueConfig = require("vue-config");
let config = require("../config/api.json");
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$base = jsonData.server_info.base;
app.config.globalProperties.$api = jsonData.server_info.api;
app.config.globalProperties.$backend = jsonData.server_info.backend;
app.config.globalProperties.$domainName = jsonData.server_info.api;
// app.config.globalProperties.$api = 'http://127.0.0.1:8000/';
// app.config.globalProperties.$backend = 'http://127.0.0.1:8000';
app.config.globalProperties.$date = {
    formatDate(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    },
    formatTime(time) {
        return moment(time, "HH:mm").format("hh:mm");
    },
    addMonth(date, month) {
        var currentDate = moment(date);
        var futureMonth = moment(currentDate).add(month, 'M');
        // var futureMonthEnd = moment(futureMonth).endOf('month');

        // if(currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
        //     futureMonth = futureMonth.add(1, 'd');
        // }
        return futureMonth.format('YYYY-MM-DD')
    }
};
app.use(vueConfig, config);
app.mixin(mixins)
app.use(Toaster, {
    position: "top-right",
    duration: 5000
});

app.use(router).mount('#app')