import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            policy_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            employee: {},
            employees: [],
            num_of_days_worked: null,
            num_of_hour_worked: null,
            late_entry: null,
            employee_work_log_details: {}

        }
    },
    mounted() {
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });



    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        deleteInfo: function(id) {
            this.policy_id = id;
        },
        deleteEmployeeWorkLog: async function() {
            let employee_work_log_url = this.$api + this.$config.leave.EmployeeWorkLogDetails + this.policy_id;
           await this.$axios.delete(employee_work_log_url, {

            }).then((response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateEmployeeWorkLog: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.EmployeeWorkLogDetails);
            let policy_details_url = this.$api + this.$config.leave.EmployeeWorkLogDetails + this.policy_id;
            await this.$axios.put(policy_details_url, {
                leave_title: this.policy_details.leave_title,
                short_code: this.policy_details.short_code,
                total_days: this.policy_details.total_days,
                applicable_to_all: this.policy_details.applicable_to_all,
                available_after_month: this.policy_details.available_after_month,
                weekend_holiday_count: this.policy_details.weekend_holiday_count,
                is_carry_forward: this.policy_details.is_carry_forward,
                max_carry_forward: this.policy_details.max_carry_forward,
                status: this.policy_details.status ? 0 : 1

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_policy_url = this.$api + this.$config.leave.EmployeeWorkLogAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_policy_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getEmployeeWorkLogData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.policy_id = id;
            let employee_work_log_url = this.$api + this.$config.leave.EmployeeWorkLogDetails + this.policy_id;
            //console.log(employee_work_log_url);
           await  this.$axios.get(employee_work_log_url, {}).then((response) => {
                if (response.status == 200) {
                    this.policy_details.leave_title = response.data.leave_title,
                        this.policy_details.short_code = response.data.short_code,
                        this.policy_details.total_days = response.data.total_days,
                        this.policy_details.applicable_to_all = response.data.applicable_to_all,
                        this.policy_details.available_after_month = response.data.available_after_month,
                        this.policy_details.weekend_holiday_count = response.data.weekend_holiday_count,
                        this.policy_details.is_carry_forward = response.data.is_carry_forward,
                        this.policy_details.max_carry_forward = response.data.max_carry_forward,
                        this.policy_details.status = response.data.status ? false : true

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createEmployeeWorkLog: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.leave.EmployeeWorkLogAPIEndPoint
            //console.log("Applicable To: ", this.applicable_to_all)
            //console.log("Weekend Count: ", this.weekend_holiday_count)
            await this.$axios.post(url, {
                leave_title: this.leave_title,
                short_code: this.short_code,
                total_days: this.total_days,
                applicable_to_all: this.applicable_to_all,
                available_after_month: this.available_after_month,
                weekend_holiday_count: this.weekend_holiday_count,
                is_carry_forward: this.is_carry_forward,
                max_carry_forward: this.max_carry_forward,
                status: this.status ? 0 : 1

            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("leave_policy Created Successfully");
                    this.$toast.success(response.data.message);
                    let leave_policy_url = this.$api + this.$config.leave.EmployeeWorkLogAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_policy_url)
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}