import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            menu_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            page_name: '',
            page_url: '',
            parent_page: {
                id: null,
                name: ''
            },
            parent_pages: [],
            menu_page_details: {},

        }
    },
    mounted() {
        this.setURL = this.$config.leave.menuPagesAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + params;
        this.getDataList(menu_pages_url);
        let emp_url = this.$api + this.$config.role.menuPagesAll;
        let configEmp = {
            method: "GET",
            url: emp_url,
            headers: authHeader()
        }
        this.$axios(configEmp).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.parent_pages.push({
                        id: response.data.data[key].id,
                        name: response.data.data[key].page_name,
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + params;
            await this.getDataList(menu_pages_url);
        },
        deleteInfo: function(id) {
            this.menu_id = id;
        },
        deleteMenuPages: async function() {
            this.isLoading = true
            let menu_pages_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            await this.$axios.delete(menu_pages_details_url, {

            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(menu_pages_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateMenuPages: async function() {
            this.isLoading = true
            let menu_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            let data = {
                page_name: this.menu_page_details.page_name,
                page_url: this.menu_page_details.page_url,
                page_parent_id: this.menu_page_details.parent_page.id,
                parent_page_name: this.menu_page_details.parent_page.name
            }
            let config = {
                method: "PUT",
                data: data,
                url: menu_details_url
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(menu_pages_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        editForm: async function(id) {
            this.menu_id = id;
            let menu_page_details_url = this.$api + this.$config.role.menuPagesDetails + this.menu_id;
            await this.$axios.get(menu_page_details_url).then((response) => {
                if (response.status == 200) {
                    this.menu_page_details.parent_page = {
                        id: response.data.page_parent_id,
                        name: response.data.parent_page_name
                    }
                    this.menu_page_details.page_name = response.data.page_name
                    this.menu_page_details.page_url = response.data.page_url

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            //console.log("Parent Page details: ", this.menu_page_details.parent_page);


        },
        createMenuPages: async function() {
            this.isLoading = true
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.role.menuPagesAPIEndPoint
            let menu_data = {
                page_name: this.page_name,
                page_url: this.page_url,
                page_parent_id: this.parent_page.id,
                parent_page_name: this.parent_page.name
            }

            let config = {
                method: "POST",
                data: menu_data,
                url: url
            }
            await this.postDataToBackend(config);
            this.isLoading = false
            let menu_pages_url = this.$api + this.$config.role.menuPagesAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(menu_pages_url);
        },

    }
}