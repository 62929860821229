import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            country_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            country_name: '',
            country_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.configurations.countryAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let country_url = this.$api + this.$config.configurations.countryAPIEndPoint + params;
        this.getDataList(country_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: async function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let country_url = this.$api + this.$config.configurations.countryAPIEndPoint + params;
            await this.getDataList(country_url);
        },
        deleteInfo: async function(id) {
            this.country_id = id;
        },
        deleteCountry: async function() {
            let country_details_url = this.$api + this.$config.configurations.countryDetails + this.country_id;
            await this.$axios.delete(country_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let country_url = this.$api + this.$config.configurations.countryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(country_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateCountry: async function() {
            //console.log("country Details is: ", this.country_details);
            let country_details_url = this.$api + this.$config.configurations.countryDetails + this.country_id;
            await this.$axios.put(country_details_url, {
                country_name: this.country_details.country_name

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let country_url = this.$api + this.$config.configurations.countryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(country_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getCountryData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.country_id = id;
            let country_details_url = this.$api + this.$config.configurations.countryDetails + this.country_id;
            //console.log(country_details_url);
            await this.$axios.get(country_details_url, {}).then(async(response) => {
                if (response.status == 200) {
                    this.country_details.country_name = response.data.country_name;

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createCountry: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.countryAPIEndPoint
            await this.$axios.post(url, {
                country_name: this.country_name,
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("country Created Successfully");
                    this.$toast.success(response.data.message);
                    let country_url = this.$api + this.$config.configurations.countryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(country_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}