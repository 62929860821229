import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";
export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            leave_approval_chain_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            emp_code: null,
            emp_id: null,
            employee_details: [],
            //employee: {},
            form_employee: {},
            form_mail_format: [],
            form_delegated_employee: [],
            step_num: [],
            form_step_names: [],
            form_step_num: [],
            employees: [],
            mail_format: {},
            mail_formats: [],
            delegated_employee: {},
            delegated_employees: [],
            total_step_num: null,

            step_names: [],
            chain_data: [],
            leave_approval_steps_details: {},
            form: {
                index: [],
                employees: [],
                mail_formats: [],
                delegated_employees: [],
                step_num: [],
                step_names: []
            },
        }
    },
    mounted() {
        this.setURL = this.$config.leave.employeeLeaveBalanceAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_approval_steps_url = this.$api + this.$config.leave.leaveApprovalChainAPIEndPoint + params;
        this.getDataList(leave_approval_steps_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        let configEmp = {
            method: "GET",
            url: emp_url,
            headers: authHeader()
        }
        this.$axios(configEmp, {}).then((response) => {
            if (response.status == 200) {
                // //console.log("Data is", response.data);
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                    this.delegated_employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let mail_url = this.$api + this.$config.leave.mailFormatAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        let configMail = {
            method: "GET",
            url: mail_url,
            headers: authHeader()
        }
        this.$axios(configMail, {}).then((response) => {
            if (response.status == 200) {
                // //console.log("Data is", response.data);
                for (const key in Object.keys(response.data.data)) {
                    this.mail_formats.push({
                        id: response.data.data[key].id,
                        title: response.data.data[key].template_title,
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_approval_steps_url = this.$api + this.$config.leave.leaveApprovalChainAPIEndPoint + params;
           await this.getDataList(leave_approval_steps_url);
        },
        deleteInfo: function(id) {
            this.leave_approval_chain_id = id;
        },
        deleteLeaveChainSteps: async function() {
            let leave_approval_steps_details_url = this.$api + this.$config.leave.leaveApprovalChainDetails + this.leave_approval_chain_id;
            await this.$axios.delete(leave_approval_steps_details_url, {

            }).then((response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.$router.go();
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error){
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
        },
        updateLeaveChainSteps: async function() {
            // console.log("Employee Details: ", this.leave_approval_steps_details.delegated_employee)
            let cmp_details_url = this.$api + this.$config.leave.leaveApprovalChainDetails + this.leave_approval_chain_id;
            await this.$axios.put(cmp_details_url, {
                employee_id: this.leave_approval_steps_details.employee.emp_id,
                employee_code: this.leave_approval_steps_details.employee.emp_code,
                employee_name: this.leave_approval_steps_details.employee.emp_name,
                mail_format_id: this.leave_approval_steps_details.mail_format.id,
                mail_format_title: this.leave_approval_steps_details.mail_format.title,
                delegated_employee_id: this.leave_approval_steps_details.delegated_employee.emp_id,
                delegated_employee_code: this.leave_approval_steps_details.delegated_employee.emp_code,
                delegated_employee_name: this.leave_approval_steps_details.delegated_employee.emp_name,
                step_num: this.leave_approval_steps_details.step_num,
                step_name: this.leave_approval_steps_details.step_names,
                total_step_num: this.leave_approval_steps_details.total_step_num,

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_approval_steps_url = this.$api + this.$config.leave.leaveApprovalChainAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(leave_approval_steps_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        groupByEmployeeCodeAndStepNum(data) {
            return data.reduce((result, item) => {
                const key = `${item.employee_code}_${item.step_num}`;
        
                if (!result[key]) {
                result[key] = [];
                }
        
                result[key].push(item);
        
                return result;
            }, {});
        },
        getLeaveBalanceData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = this.groupByEmployeeCodeAndStepNum(response.data);
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.leave_approval_chain_id = id;
            let leave_approval_steps_details_url = this.$api + this.$config.leave.leaveApprovalChainDetails + this.leave_approval_chain_id;
           await this.$axios.get(leave_approval_steps_details_url, {}).then((response) => {
                if (response.status == 200) {
                    // console.log("response",response);
                    this.leave_approval_steps_details.employee = {
                        emp_id: response.data.employee_id,
                        emp_code: response.data.employee_code,
                        emp_name: response.data.employee_name
                    }
                    this.leave_approval_steps_details.mail_format = {
                        id: response.data.mail_format_id,
                        title: response.data.mail_format_title
                    }
                    this.leave_approval_steps_details.delegated_employee = {
                        emp_id: response.data.delegated_employee_id,
                        emp_name: response.data.delegated_employee_name,
                        emp_code: response.data.delegated_employee_code
                    }
                    this.leave_approval_steps_details.step_num = response.data.step_num
                    this.leave_approval_steps_details.step_names = response.data.step_name
                    this.leave_approval_steps_details.total_step_num = response.data.total_step_num
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createLeaveApprovalChainSteps: async function() {
            // //console.log("Employee Creation Method");
            // //console.log("Employee Details: ", this.form)
                ////console.log("Delegated Employee: ", this.delegated_employee)

            // //console.log(this.total_step_num);
            // //console.log(this.form_employee);
            // //console.log(this.mail_formats);

            let formArray = [];
            for (let i = 0; i <= this.total_step_num - 1; i++) {
                let employee = this.form_employee;
                let mailformat = this.form_mail_format[i];
                let delegated_emp = this.form_delegated_employee[i];
                let step_number = i + 1
                let step_namess = this.form_step_names[i];
                //console.log("mailformat",mailformat)
                if(!mailformat){
                    this.$toast.error("Please select a mail format");
                }
                //console.log("Delegated Employee details: ", step_number)
                let formObj = {
                    employee_id: employee.emp_id,
                    employee_code: employee.emp_code,
                    employee_name: employee.emp_name,
                    mail_format_id: mailformat.id,
                    mail_format_title: mailformat.title,
                    delegated_employee_id: delegated_emp.emp_id,
                    delegated_employee_code: delegated_emp.emp_code,
                    delegated_employee_name: delegated_emp.emp_name,
                    step_num: step_number,
                    step_name: step_namess,
                    total_step_num: this.total_step_num,

                };
                formArray.push(formObj);
            }
            //console.log('formArray = ', this.formArray);
            let url = this.$api + this.$config.leave.leaveApprovalChainAPIEndPoint;
            let siteConfig = {
                method: 'POST',
                url: url,
                data: formArray
            }
            await this.$axios(siteConfig).then(async(response) => {
                if (response.status == 201) {
                    //console.log("leave_approval_steps Created Successfully");
                    this.$toast.success(response.data.message);
                    let leave_approval_steps_url = this.$api + this.$config.leave.leaveApprovalChainAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(leave_approval_steps_url);
                   this.form_employee = ''
                   this.total_step_num = ''
                   window.location.reload();
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },

    }
}