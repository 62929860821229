import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            username: null,
            password: null,
            inputType: "password",
            iconFlag: true
        }
    },
    created() {
        document.title = "HR Connect - Login";
    },
    methods: {
        login: async function() {
            this.isLoading = true;
            if (!this.username || !this.password) {
                this.isLoading = false;
            } else {
                let data = {
                    username: this.username,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$api + this.$config.auth.loginAPIEndpoint,
                    data: data
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        localStorage.setItem("token", response.data.access);
                        await this.getPermissionPages();
                        this.$router.push("/");
                        window.location.href = '/';                        
                    } else {
                        this.isLoading = false;
                    }
                }).catch(async error => {
                    // if (error.response.status == 401) {     
                        this.$toast.error(error.response.data.detail);                 
                    // }
                    this.isLoading = false;
                })
            }
        },
        changeInputType: function() {
            if (this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            } else if (this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        }
    }
}