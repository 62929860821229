import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
// import $ from 'jquery';
import { MonthPicker } from 'vue-month-picker'
export default {

    components: {
        Loading,
        MonthPicker
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            menu_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            employees: [],
            months: [{
                    id: "01",
                    name: 'January'
                },
                {
                    id: "02",
                    name: 'February'
                },
                {
                    id: "03",
                    name: 'March'
                },
                {
                    id: "04",
                    name: 'April'
                },
                {
                    id: "05",
                    name: 'May'
                },
                {
                    id: "06",
                    name: 'June'
                },
                {
                    id: "07",
                    name: 'July'
                },
                {
                    id: "08",
                    name: 'August'
                },
                {
                    id: "09",
                    name: 'September'
                },
                {
                    id: "10",
                    name: 'October'
                },
                {
                    id: "11",
                    name: 'November'
                },
                {
                    id: "12",
                    name: 'December'
                }
            ],
            employee: {},
            month: {},
            date: {
                from: null,
                to: null,
                month: null,
                year: null
            }

        }
    },
    mounted() {
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
    },

    created() {
        document.title = "HrConnect - PayRoll";
        // $(".yearpicker").yearpicker();
    },
    methods: {
        showDate(date) {
            this.date = date;
            this.date.month = this.month.name;
        },
        generatePayslip: async function() {
            let url = this.$api + this.$config.reporting.downloadPayslip + "?emp_code=" + this.employee.emp_code + "&salary_month=" +
                this.month.id + "&salary_year=" + this.date.year;
            // let menu_data = {
            //     page_name: this.page_name,
            //     page_url: this.page_url,
            //     page_parent_id: this.parent_page.id,
            //     parent_page_name: this.parent_page.name
            // }

            // let config = {
            //     method: "GET",
            //     url: url
            // }
            let new_label = this.employee.emp_name + "_" + this.employee.emp_code + "_payslip.pdf";
           await this.$axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    //console.log("PDF Response: ", response.data);
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },

    }
}