import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            income_tax_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            tax_code: '',
            tax_product: '',
            tax_amount: '',
            tax_grade: '',
            income_tax_details: {}


        }
    },
    mounted() {
        let income_tax_url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        this.getDataList(income_tax_url);
    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        deleteInfo: function(id) {
            this.income_tax_id = id;
        },
        deleteIncomeTax: async function() {
            let income_tax_details_url = this.$api + this.$config.payroll.incomeTaxDetails + this.income_tax_id;
            await this.$axios.delete(income_tax_details_url, {

            }).then( async (response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    //console.log("In else");
                    this.isLoading = false
                    let income_tax_url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(income_tax_url);
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateIncomeTax: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.IncomeTaxDetails);
            let income_tax_details_url = this.$api + this.$config.payroll.incomeTaxDetails + this.income_tax_id;
            let data = {
                tax_code: this.income_tax_details.tax_code,
                tax_grade: this.income_tax_details.tax_grade,
                tax_product: this.income_tax_details.tax_product,
                tax_amount: this.income_tax_details.tax_amount
            }
            let config = {
                url: income_tax_details_url,
                method: "PUT",
                data: data
            }
            await this.$axios(config).then( async (response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let income_tax_url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(income_tax_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        editForm: async function(id) {
            this.income_tax_id = id;
            let income_tax_details_url = this.$api + this.$config.payroll.incomeTaxDetails + this.income_tax_id;
            //console.log(income_tax_details_url);
            let config = {
                url: income_tax_details_url,
                method: "GET"
            }
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.income_tax_details.tax_code = response.data.tax_code,
                        this.income_tax_details.tax_grade = response.data.tax_grade,
                        this.income_tax_details.tax_product = response.data.tax_product,
                        this.income_tax_details.tax_amount = response.data.tax_amount
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createIncomeTax: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint;
            let data = {
                tax_code: this.tax_code,
                tax_grade: this.tax_grade,
                tax_product: this.tax_product,
                tax_amount: this.tax_amount,
            };
            let config = {
                url: url,
                method: "POST",
                data: data
            }
            await this.postDataToBackend(config);
            this.tax_code = '';
            this.tax_product = '';
            this.tax_amount = '';
            this.tax_grade = '';
            let income_tax_url = this.$api + this.$config.payroll.incomeTaxAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
           await this.getDataList(income_tax_url);
        },

    }
}