import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            mail_format_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            template_title: '',
            employees: [],
            policies: [],
            subject: '',
            mail_body: '',
            status: '',
            delegated_employee_remarks: '',
            data_list_index: null,
            leave_application_id: null,
            leave_approval_history_details: {},
            leave_application_details: {},
            leave_approval_history_list: []
        }
    },
    created() {
        document.title = "HrConnect - Leave";
        this.getCurrentUserInfo();
        //console.log("UserInfo in Leave Request: ", this.userInfo)
    },
    async mounted() {
        await this.getCurrentUserInfo();
        //console.log("UserInfo in Leave Request: ", this.userInfo)
        this.setURL = this.$config.leave.leaveApprovalHistoryAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        this.search = this.userInfo.emp_code;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + params;
        await this.getDataList(leave_approval_url);
    },

    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            this.search = this.userInfo.emp_code;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + params;
            await this.getDataList(leave_approval_url);
        },
        deleteInfo: function(id) {
            this.mail_format_id = id;
        },
        rejectLeaveApprovalHistory: async function() {

            //console.log("Employee Leave Approval History Method");
            //console.log(this.dataList[this.data_list_index])
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApprovalHistoryDetails + this.allDataList[this.data_list_index].id
            let data = {
                employee_id: this.allDataList[this.data_list_index].employee_id,
                employee_code: this.allDataList[this.data_list_index].employee_code,
                employee_name: this.allDataList[this.data_list_index].employee_name,
                delegation_date_time: this.allDataList[this.data_list_index].delegation_date_time,
                delegated_employee_id: this.allDataList[this.data_list_index].delegated_employee_id,
                delegated_employee_code: this.allDataList[this.data_list_index].delegated_employee_code,
                delegated_employee_remarks: this.delegated_employee_remarks,
                delegated_employee_status: "Rejected",
                leave_application_id: this.allDataList[this.data_list_index].leave_application_id,
                step_num: this.allDataList[this.data_list_index].step_num,
                total_step_num: this.allDataList[this.data_list_index].total_step_num,

            }
            let config = {
                method: 'PUT',
                url: url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200 || response.status == 201) {
                    //console.log("Leave Approved Successfully");
                    this.$toast.success(response.data.message);
                    this.leave_approval_history_list = []
                    let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_approval_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        rejectLeaveApprovalHistoryHome: async function(id, index) {
            await this.editForm(id, index);
            //console.log("Employee Leave Approval History Method");
            //console.log(this.dataList[this.data_list_index])
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApprovalHistoryDetails + this.allDataList[this.data_list_index].id
            let data = {
                employee_id: this.allDataList[this.data_list_index].employee_id,
                employee_code: this.allDataList[this.data_list_index].employee_code,
                employee_name: this.allDataList[this.data_list_index].employee_name,
                delegation_date_time: this.allDataList[this.data_list_index].delegation_date_time,
                delegated_employee_id: this.allDataList[this.data_list_index].delegated_employee_id,
                delegated_employee_code: this.allDataList[this.data_list_index].delegated_employee_code,
                delegated_employee_remarks: this.delegated_employee_remarks,
                delegated_employee_status: "Rejected",
                leave_application_id: this.allDataList[this.data_list_index].leave_application_id,
                step_num: this.allDataList[this.data_list_index].step_num,
                total_step_num: this.allDataList[this.data_list_index].total_step_num,

            }
            let config = {
                method: 'PUT',
                url: url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200 || response.status == 201) {
                    //console.log("Leave Approved Successfully");
                    this.$toast.success(response.data.message);
                    this.leave_approval_history_list = []
                    let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_approval_url);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        updateLeaveApprovalHistory: async function() {
            //console.log("cmployee Details is: ", this.ApprovalHistoryDetails);
            let cmp_details_url = this.$api + this.$config.leave.ApprovalHistoryDetails + this.mail_format_id;
            await this.$axios.put(cmp_details_url, {
                template_title: this.leave_approval_history_details.template_title,
                subject: this.leave_approval_history_details.subject,
                mail_body: this.leave_approval_history_details.mail_body,
                status: this.leave_approval_history_details.status

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    this.leave_approval_history_list = []
                    await this.getDataList(leave_approval_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        getLeaveApprovalHistory: async function(url) {
            let emp_url = this.$api + url;
            console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    console.log("getLeaveApprovalHistory Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id, index) {
            this.leave_application_id = id;
            this.data_list_index = index;
            let leave_application_details_url = this.$api + this.$config.leave.leaveApplicationDetails + this.leave_application_id;
            //console.log(leave_application_details_url);
            this.isLoading = true
            await this.$axios.get(leave_application_details_url, {}).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    this.leave_application_details.employee_code = response.data.employee_code
                    this.leave_application_details.leave_policy_name = response.data.leave_policy_name
                    this.leave_application_details.leave_start_date = response.data.leave_start_date
                    this.leave_application_details.leave_end_date = response.data.leave_end_date
                    this.leave_application_details.is_half_day = response.data.is_half_day
                    this.leave_application_details.total_leave_day = response.data.total_leave_day
                    this.leave_application_details.leave_reason = response.data.leave_reason

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            let leave_approval_history = this.$api + this.$config.leave.leaveApplicationApprovalAPIEndPoint + "?leave_application_id=" + this.leave_application_id;
            await this.$axios.get(leave_approval_history, {}).then((response) => {
                if (response.status == 200) {
                     //console.log("Data is", response.data);
                    this.leave_approval_history_list = []
                    this.leave_approval_history_list = response.data.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        approveLeaveApprovalHistoryHome: async function(id, index) {
            await this.editForm(id, index);
            // console.log("Employee Leave Approval History Method");
            // console.log(this.allDataList[this.data_list_index])
            // console.log(this.data_list_index)
            // console.log(this.allDataList)
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApprovalHistoryDetails + this.allDataList[this.data_list_index].id
            let data = {
                employee_id: this.allDataList[this.data_list_index].employee_id,
                employee_code: this.allDataList[this.data_list_index].employee_code,
                employee_name: this.allDataList[this.data_list_index].employee_name,
                delegation_date_time: this.allDataList[this.data_list_index].delegation_date_time,
                delegated_employee_id: this.allDataList[this.data_list_index].delegated_employee_id,
                delegated_employee_code: this.allDataList[this.data_list_index].delegated_employee_code,
                delegated_employee_remarks: this.delegated_employee_remarks,
                delegated_employee_status: "Accepted",
                leave_application_id: this.allDataList[this.data_list_index].leave_application_id,
                step_num: this.allDataList[this.data_list_index].step_num,
                total_step_num: this.allDataList[this.data_list_index].total_step_num,

            }
            let config = {
                method: 'PUT',
                url: url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 201 || response.status == 200) {
                    //console.log("Leave Approved Successfully");
                    this.$toast.success(response.data.message);
                    let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    this.leave_approval_history_list = []
                    await this.getDataList(leave_approval_url);
                    this.delegated_employee_remarks = ''
                } else {
                    this.isLoading = false
                }
            }).catch(async error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        approveLeaveApprovalHistory: async function() {
            // console.log("Employee Leave Approval History Method");
            // console.log(this.allDataList[this.data_list_index])
            this.isLoading = true
            let url = this.$api + this.$config.leave.leaveApprovalHistoryDetails + this.allDataList[this.data_list_index].id
            let data = {
                employee_id: this.allDataList[this.data_list_index].employee_id,
                employee_code: this.allDataList[this.data_list_index].employee_code,
                employee_name: this.allDataList[this.data_list_index].employee_name,
                delegation_date_time: this.allDataList[this.data_list_index].delegation_date_time,
                delegated_employee_id: this.allDataList[this.data_list_index].delegated_employee_id,
                delegated_employee_code: this.allDataList[this.data_list_index].delegated_employee_code,
                delegated_employee_remarks: this.delegated_employee_remarks,
                delegated_employee_status: "Accepted",
                leave_application_id: this.allDataList[this.data_list_index].leave_application_id,
                step_num: this.allDataList[this.data_list_index].step_num,
                total_step_num: this.allDataList[this.data_list_index].total_step_num,

            }
            let config = {
                method: 'PUT',
                url: url,
                data: data
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 201 || response.status == 200) {
                    //console.log("Leave Approved Successfully");
                    this.$toast.success(response.data.message);
                    let leave_approval_url = this.$api + this.$config.leave.leaveApprovalHistoryAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    this.leave_approval_history_list = []
                    await this.getDataList(leave_approval_url);
                    this.delegated_employee_remarks = ''
                } else {
                    this.isLoading = false
                }
            }).catch(async error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

    }
}