import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            selected_image: '',
        }
    },
   async created() {
       this.backend_url = this.$backend
        await this.getCurrentUserInfo();
    },
    methods: {
        formValidation(){
            if(!this.old_password){
                this.$toast.error("Please enter your old password!");
                return false
            } else if(!this.password){
                this.$toast.error("Please enter your new password!");
                return false
            } else if(!this.password_confirmation){
                this.$toast.error("Please enter your confirmation password!");
                return false
            } else if(this.password != this.password_confirmation){
                this.$toast.error("Password and confirmation password does not match!");
                return false
            }
            console.log("Please enter your confirmation password")
            return true;
        },
        async updatePassword(){
            if(this.formValidation()){
                this.isLoading = true;
                let data = {
                    email: this.userInfo.email,
                    old_password: this.old_password,
                    new_password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$api + this.$config.auth.changePassword,
                    data: data
                };

                await this.$axios(config).then(async (response) => {
                    if (response.status == 200) {
                        this.$toast.success(response.data.message);
                    } 
                    this.isLoading = false
                }).catch(error => {
                    console.log(error.response)
                    this.isLoading = false
                    this.$toast.error(error.response.data.message);
                });
            }
        },
        updateImage: function(event) {
            this.selected_image = event.target.files[0];
        },
        updateEmployee: async function() {
            const emp_data = new FormData();
            if(this.userInfo.phone != ''){
                if(this.userInfo.phone == this.userInfo.emergency_number){
                    this.$toast.error('Emergency phone number should be different');
                    return false
                }
             }

            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.userInfo.id;
            
            emp_data.append("emp_code", this.userInfo.emp_code);
            emp_data.append("company_id", this.userInfo.company_id);
            emp_data.append("company_name", this.userInfo.company_name);
            emp_data.append("first_name", this.userInfo.first_name);
            emp_data.append("last_name", this.userInfo.last_name);
            emp_data.append("country_id", this.userInfo.country_id);
            emp_data.append("country_name", this.userInfo.country_name);
            emp_data.append("city_id", this.userInfo.city_id);
            emp_data.append("city_name", this.userInfo.city_name);
            emp_data.append("blood_group_id", this.userInfo.blood_group_id);
            emp_data.append("blood_group_title", this.userInfo.blood_group_title);
            emp_data.append("marital_status", this.userInfo.marital_status);
            emp_data.append("gender_id", this.userInfo.gender_id);
            emp_data.append("gender_title", this.userInfo.gender_title);
            emp_data.append("email", this.userInfo.email);
            emp_data.append("phone", this.userInfo.phone);
            emp_data.append("emergency_number", this.userInfo.emergency_number);
            emp_data.append("branch_id", this.userInfo.branch_id);
            emp_data.append("branch_name", this.userInfo.branch_name);
            emp_data.append("department_id", this.userInfo.department_id);
            emp_data.append("department_name", this.userInfo.department_name);
            emp_data.append("section_id", this.userInfo.section_id);
            emp_data.append("section_name", this.userInfo.section_name);
            emp_data.append("role_id", this.userInfo.role_id);
            emp_data.append("role_name", this.userInfo.role_name);
            emp_data.append("designation_id", this.userInfo.designation_id);
            emp_data.append("designation_name", this.userInfo.designation_name);
            emp_data.append("date_of_joining", this.userInfo.date_of_joining);
            emp_data.append("is_active", this.userInfo.is_active);
            // emp_data.append("image", this.userInfo.image);
            if(this.selected_image){
                emp_data.append("image", this.selected_image);
            }
            let employee_name = this.userInfo.first_name + " " + this.userInfo.last_name;
            emp_data.append("emp_name", employee_name);



            let config = {
                method: "PUT",
                url: emp_details_url,
                data: emp_data,
                headers: authHeader()
                // headers: {
                //     "Content-Type": "multipart/form-data"
                // }
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    this.$toast.success(response.data.message);
                    if(this.selected_image){
                        location.reload();
                    }
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                console.log("err",error);
                let err = error.response.data.errors ? error.response.data.errors : null
                if(err){
                    for (const property in err) {
                        this.$toast.error(`${err[property]}`);
                      }
                } else {
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false
            });
        },
    }
}