<template>
  <div class="page-wrapper">
    <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
      <!--breadcrumb-->
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee Work Log
              </li>
            </ol>
          </nav>
        </div>
        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleLargeModal"
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee Work Log List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
          <div class="row mb-3 ms-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee Code</th>
                  <th>Total Working Day</th>
                  <th>Total Working Hours</th>
                  <th>Late Entries</th>
                  >
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.emp_code }}</td>
                  <td>{{ li.num_of_days_worked }}</td>
                  <td>{{ li.number_of_hour_worked }}</td>
                  <td>{{ li.late_entry }}</td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-success btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal3"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.id)"
                        data-bs-target="#exampleLargeModal1"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2 ms-2"
                        data-bs-toggle="modal"
                        @click="deleteInfo(li.id)"
                        data-bs-target="#exampleVerticallycenteredModal"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="row" v-if="allDataList">
                                <pagination v-if="allDataList.last_page > 1"
                                                    :pagination="allDataList"
                                                    :offset="5"
                                                    @paginate="getEmployeeData(setURL + '?page=' + allDataList.current_page + '&limit=' + limit + '&search=' + search)"
                                ></pagination>
                            </div> -->
          <!-- <nav aria-label="Page navigation example" class="float-end mt-2">
							<ul class="pagination round-pagination right">
								<li class="page-item"><a class="page-link" href="javascript:;">Previous</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;javascript:;">1</a>
								</li>
								<li class="page-item active"><a class="page-link" href="javascript:;">2</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">3</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:;">Next</a>
								</li>
							</ul>
						</nav> -->
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Data Found</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputState" class="form-label"
                      >Employee Code</label
                    >
                    <select
                      v-model="employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Days Worked</label
                    >
                    <input
                      type="text"
                      v-model="num_of_days_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Days Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Hour Worked</label
                    >
                    <input
                      type="text"
                      v-model="num_of_hour_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Hour Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Late Entry</label
                    >
                    <input
                      type="text"
                      v-model="late_entry"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Late Entry"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createEmployeeWorkLog()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputState" class="form-label"
                      >Employee Code</label
                    >
                    <select
                      v-model="employee_work_log_details.employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Days Worked</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_details.num_of_days_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Days Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Hour Worked</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_details.num_of_hour_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Hour Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Late Entry</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_details.late_entry"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Late Entry"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="updateEmployeeWorkLog()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal3"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputState" class="form-label"
                      >Employee Code</label
                    >
                    <select
                      v-model="employee_work_log_details.employee"
                      id="inputState"
                      class="form-select single-select"
                    >
                      <option
                        v-for="(option, key) in employees"
                        :key="key"
                        :value="option"
                      >
                        {{ option.emp_code }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Days Worked</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_detailsnum_of_days_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Days Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Number of Hour Worked</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_detailsnum_of_hour_worked"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Number of Hour Worked"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Late Entry</label
                    >
                    <input
                      type="text"
                      v-model="employee_work_log_detailslate_entry"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Late Entry"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="deleteEmployeeWorkLog()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/employee_work_log.js"></script>