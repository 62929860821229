<template>
  <div class="page-wrapper">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="page-content">
      <!--breadcrumb-->
      <div class="page-breadcrumb d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Tables</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Employee Leave Requests
              </li>
            </ol>
          </nav>
        </div>
        <!-- <div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Apply Leave Application</button>
						</div>
					</div> -->
      </div>
      <!--end breadcrumb-->
      <h6 class="mb-0 text-uppercase">Employee Leave Application Request List</h6>
      <hr />
      <div class="card" v-if="Object.keys(allDataList).length > 0">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="example_length">
                <label
                  >Show
                  <select
                    v-model="limit"
                    name="example_length"
                    aria-controls="example"
                    class="form-select form-select-sm mt-1"
                  >
                    <option
                      v-for="(option, key) in pageLimit"
                      :key="key"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="example_filter" class="dataTables_filter text-end">
                <label class="text-start ms-2"
                  >Search:<input
                    type="search"
                    v-model="search"
                    class="form-control form-control-sm mt-1"
                    placeholder=""
                    aria-controls="example"
                  />
                </label>
                <button
                  type="button"
                  @click="searchData"
                  class="btn btn-primary px-4 radius-30 ms-2"
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Employee name</th>
                  <th>Delegation Time</th>
                  <th>Delegated Employee Code</th>
                  <th>Delegated Employee Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(allDataList).length > 0">
                <tr v-for="(li, index) in allDataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ li.employee_name }}</td>
                  <td>{{ $date.formatDate(li.delegation_date_time) }}</td>
                  <td>{{ li.delegated_employee_code }}</td>
                  <td>{{ li.delegated_employee_status }}</td>
                  <td>
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-info btn-block px-3 mt-2"
                        data-bs-toggle="modal"
                        @click="editForm(li.leave_application_id, index)"
                        data-bs-target="#exampleLargeModal1"
                        style="border-bottom-right-radius: 0px !important;border-top-right-radius: 0px !important;"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block px-3 mt-2"
                        v-on:click="rejectLeaveApprovalHistoryHome(li.leave_application_id, index)"
                        style="border-bottom-left-radius: 0px !important;border-top-left-radius: 0px !important;
                                border-bottom-right-radius: 0px !important;border-top-right-radius: 0px !important;"
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        v-on:click="approveLeaveApprovalHistoryHome(li.leave_application_id, index)"
                        class="btn btn-primary btn-block px-3 mt-2"
                        style="border-bottom-left-radius: 0px !important;border-top-left-radius: 0px !important;"
                      >
                        Approve
                      </button>
                      <!-- <button type="button" class="btn btn-danger btn-block px-3 mt-2 ms-2" data-bs-toggle="modal" @click="deleteInfo(li.id)" data-bs-target="#exampleVerticallycenteredModal">Delete</button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
            </table>
          </div>
          <div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
        </div>
      </div>
      <div class="card" v-else>
        <h3 class="text-info text-center">No Leave Approval Request</h3>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Leave Application Creation</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-primary">
              <div class="card-body p-5">
                <div class="card-title d-flex align-items-center">
                  <div>
                    <i class="bx bxs-user me-1 font-22 text-primary"></i>
                  </div>
                  <h5 class="mb-0 text-primary">Leave Mail Format Creation</h5>
                </div>
                <hr />
                <form class="row g-3">
                  <div class="col-md-6">
                    <label for="inputPhone" class="form-label"
                      >Template Title</label
                    >
                    <input
                      type="text"
                      v-model="template_title"
                      class="form-control"
                      id="inputPhone"
                      placeholder="Enter Template Title"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label">Subject</label>
                    <input
                      type="text"
                      v-model="subject"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Subject"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label">Mail Body</label>
                    <textarea
                      class="form-control"
                      v-model="mail_body"
                      id="inputEmail"
                      placeholder="Enter mail Body"
                    >
                    </textarea>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail" class="form-label">Status</label>
                    <input
                      type="text"
                      v-model="status"
                      class="form-control"
                      id="inputEmail"
                      placeholder="Enter Leave Status"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="createLeaveMailFormat()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- view Modal -->
    <div
      class="modal fade"
      id="exampleLargeModal1"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Employee Leave Application Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card border-top border-0 border-4 border-warning">
              <div class="card-body p-5">
                <ul class="nav nav-tabs nav-primary" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      data-bs-toggle="tab"
                      href="#primaryhome"
                      role="tab"
                      aria-selected="true"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i
                            class="
                              fadeIn
                              animated
                              bx bx-message-square-check
                              font-18
                              me-1
                            "
                          ></i>
                        </div>
                        <div class="tab-title">Leave Application</div>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      data-bs-toggle="tab"
                      href="#primaryprofile"
                      role="tab"
                      aria-selected="false"
                    >
                      <div class="d-flex align-items-center">
                        <div class="tab-icon">
                          <i class="bx bx-user-pin font-18 me-1"></i>
                        </div>
                        <div class="tab-title">Leave Approval History</div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="tab-content py-3">
                  <div
                    class="tab-pane fade show active"
                    id="primaryhome"
                    role="tabpanel"
                  >
                    <div class="card-title d-flex align-items-center">
                      <div>
                        <i class="bx bxs-user me-1 font-22 text-primary"></i>
                      </div>
                      <h5 class="mb-0 text-primary">Leave Application</h5>
                    </div>
                    <hr />
                    <form class="row g-3">
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Employee Code</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.employee_code"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Leave Policy</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.leave_policy_name"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPhone" class="form-label"
                          >Leave Start Date</label
                        >
                        <input
                          type="date"
                          v-model="leave_application_details.leave_start_date"
                          class="form-control"
                          id="inputPhone"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Leave End Date</label
                        >
                        <input
                          type="date"
                          v-model="leave_application_details.leave_end_date"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-4">
                        <label for="inputState" class="form-label"
                          >Total Leave Days</label
                        >
                        <input
                          type="text"
                          v-model="leave_application_details.total_leave_day"
                          class="form-control"
                          id="inputEmail"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputEmail" class="form-label"
                          >Half Day</label
                        >
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            v-model="leave_application_details.is_half_day"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            disabled
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                            >Yes/No</label
                          >
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label for="inputEmail" class="form-label"
                          >Reason</label
                        >
                        <textarea
                          class="form-control"
                          v-model="leave_application_details.leave_reason"
                          id="inputEmail" disabled
                          placeholder="Enter Reason"
                        >
                        </textarea>
                      </div>
                      <div class="col-md-12">
                        <label for="inputEmail" class="form-label"
                          >Remarks</label
                        >
                        <textarea
                          class="form-control"
                          v-model="delegated_employee_remarks"
                          id="inputEmail"
                          placeholder="Enter Remarks"
                        >
                        </textarea>
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="primaryprofile"
                    role="tabpanel"
                  >
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Employee Name</th>
                            <th>Delegated Employee Code</th>
                            <th>Delegated Employee Name</th>
                            <th>Step No</th>
                            <th>Delegated Employee Status</th>
                            <th>Delegated Employee Remarks</th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="
                            Object.keys(leave_approval_history_list).length > 0
                          "
                        >
                          <tr
                            v-for="(li, index) in leave_approval_history_list"
                            :key="index"
                          >
                            <td>{{ li.employee_name }}</td>
                            <td>{{ li.delegated_employee_code }}</td>
                            <td>{{ li.delegated_employee_name }}</td>
                            <td>{{ li.step_num }}</td>
                            <td>
                              {{
                                li.delegated_employee_status == ""
                                  ? "Pending"
                                  : li.delegated_employee_status
                              }}
                            </td>
                            <td>{{ li.delegated_employee_remarks }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-title d-flex align-items-center">
									<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
									</div>
									<h5 class="mb-0 text-primary">Leave Application</h5>
								</div>
								<hr>
								<form class="row g-3">
									<div class="col-md-4">
										<label for="inputState" class="form-label">Employee Code</label>
										<input type="text" v-model="leave_application_details.employee_code" class="form-control" id="inputEmail" disabled>
									</div>
									<div class="col-md-4">
										<label for="inputState" class="form-label">Leave Policy</label>
										<input type="text" v-model="leave_application_details.leave_policy_name" class="form-control" id="inputEmail" disabled>
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Leave Start Date</label>
										<input type="date" v-model="leave_application_details.leave_start_date" class="form-control" id="inputPhone" disabled>
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Leave End Date</label>
										<input type="date" v-model="leave_application_details.leave_end_date" class="form-control" id="inputEmail" disabled>
									</div>
                                    <div class="col-md-4">
										<label for="inputState" class="form-label">Total Leave Days</label>
										<input type="text" v-model="leave_application_details.total_leave_day" class="form-control" id="inputEmail" disabled>
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Half Day</label>
										<div class="form-check form-switch">
                                            <input class="form-check-input" v-model="leave_application_details.is_half_day" type="checkbox" id="flexSwitchCheckChecked" disabled>
                                        </div>
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Remarks</label>
                                        <textarea class="form-control" v-model="delegated_employee_remarks" id="inputEmail" placeholder="Enter Remarks">
                                        </textarea>
									</div>
								</form> -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger float-start"
              v-on:click="rejectLeaveApprovalHistory()"
              data-bs-dismiss="modal"
            >
              Reject
            </button>
            <button
              type="button"
              v-on:click="approveLeaveApprovalHistory()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div
      class="modal fade"
      id="exampleVerticallycenteredModal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title">Are You Sure?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              v-on:click="rejectLeaveApplication()"
              data-bs-dismiss="modal"
              class="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="../js/leave_request.js"></script>