<template>
    <ul>
        <li>
            <router-link to="#" class="has-arrow">
                <div class="parent-icon"><i class='fadeIn animated bx bx-paste'></i></div>
                <div class="menu-title">{{title}}</div>
            </router-link>
            <ul>
                <li><router-link to="/leave-policy"><i class="bx bx-right-arrow-alt"></i>Leave Policy</router-link></li>
                <li><router-link to="/employee-leave-balance"><i class="bx bx-right-arrow-alt"></i>Employee Leave Balance</router-link></li>
                <li><router-link to="/leave-mail-format"><i class="bx bx-right-arrow-alt"></i>Leave Mail Format</router-link></li>
                <li><router-link to="/leave-approval-steps"><i class="bx bx-right-arrow-alt"></i>Leave Approval Chain Steps</router-link></li>
                
            </ul>
        </li>
    </ul>
</template>
<script src="../js/leave_management.js"></script>
