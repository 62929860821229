<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div v-if="isLoginPage == false" class="wrapper">
      <Home></Home>
      <router-view></router-view>
    </div>
    <div class="wrapper">
      <router-view v-if="isLoginPage == true"></router-view>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Home from './components/home/template/Home.vue';

export default {
  name: "App",
  components: {
    Loading,
    Home,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      isLoading: false,
      fullPage: true,
      loader: "bars",
      isLoginPage: false,
    };
  },
  created() {
    if (
      this.$router.currentRoute.path == "/login" ||
      this.$router.currentRoute.path == "/signup" ||
      this.$router.currentRoute.path == "/forgot-password" || 
      this.$router.currentRoute.path == "/reset-password"
    ) {
      this.token= localStorage.getItem("token")
      if (this.token) {
        this.isLoginPage = false;
        this.$router.push("/");
      } else if (!this.token) {
        this.isLoginPage = true;
      }
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }
    if(this.token){
      this.onInactive(1800000, function () {
        localStorage.clear();
        alert("You have been logged out for 30 minutes of inactivity!");
        // this.$router.go("/login");
        window.location.href = '#/login';   
      });
    }
  },
  methods: {
    onInactive: function (ms, cb) {
      var wait = setTimeout(cb, ms);
      document.onmousemove =
        document.mousedown =
        document.mouseup =
        document.onkeydown =
        document.onkeyup =
        document.focus =
          function () {
            clearTimeout(wait);
            wait = setTimeout(cb, ms);
          };
    },
  },
  watch: {
    $route() {
      this.token= localStorage.getItem("token")
      if (this.$route.path == "/login" || this.$route.path == "/signup" || this.$route.path == "/forgot-password" || this.$route.path == "/reset-password") {
        if (this.token) {
          // console.log("this.token", this.token);
          this.isLoginPage = false;
          this.$router.push("/");
        } else if (!this.token) {
          this.isLoginPage = true;
        }
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
      }
    },
  },
};
</script>
