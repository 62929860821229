<template>
    <div class="page-wrapper">
        <div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
            <!--breadcrumb-->
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                
                <div class="ms-auto">
                    
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#exampleLargeModal">
                            Create Employee Attendance
                        </button>
                    </div>
                    
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <div class="btn-group">
                    <button type="button" class="btn btn-info" data-bs-toggle="modal"
                                data-bs-target="#exampleFilterModal">
                                Filter
                            </button>
                    <a href="/attendance.csv" class="btn btn-primary">Simple file</a>
                    <input class="form-control btn btn-success" id="fileUpload" type="file" @change="uploadFile" name="files" accept=".csv, .xlsx" ref="inputFile" uploader="uploader" single hidden>
                    <button type="button" class="btn btn-success" @click="chooseFile">
                        Bulk Upload
                    </button>
                </div>
            </div>
            <!--end breadcrumb-->
            <h6 class="mb-0 text-uppercase">Employee Attendance Log</h6>
            
            <hr class="mb-2" />
            <div class="card" v-if="Object.keys(allDataList).length > 0">
                <div class="card-body">
                    <div class="row mb-3 ms-2">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="example_length">
                                <label>Show
                                    <select v-model="limit" name="example_length" aria-controls="example"
                                        class="form-select form-select-sm">
                                        <option v-for="(option, key) in pageLimit" :key="key" :value="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div id="example_filter" class="dataTables_filter text-end">
                                <label class="text-start">Search:<input type="search" v-model="search"
                                        class="form-control form-control-sm" placeholder="" aria-controls="example" />
                                </label>
                                <button type="button" @click="searchData" class="btn btn-primary px-4 radius-30 ms-2">
                                    Go
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sl. No</th>
                                    <th>Employee Name</th>
                                    <th>Employee Code</th>
                                    <th>Date</th>
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                    <th>Late</th>
                                    <th>Early</th>
                                    <th>Total Work Time</th>
                                    <th>Extra Work Time</th>
                                </tr>
                            </thead>
                            <tbody v-if="Object.keys(allDataList).length > 0">
                                <tr v-for="(li, index) in allDataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ li.emp_name }}</td>
                                    <td>{{ li.emp_code }}</td>
                                    <td>{{ li.date }}</td>
                                    <td>{{ li.time_in }}</td>
                                    <td>{{ li.time_out }}</td>
                                    <td>{{ li.late }}</td>
                                    <td>{{ li.early }}</td>
                                    <td>{{ li.work_time }}</td>
                                    <td>{{ li.extra_work_time }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row float-end" v-if="allDataList">
                        <v-pagination v-model="page" :pages="total_page" :range-size="1" active-color="#037de2"
                            @update:modelValue="updateHandler" :prevText="'Prev'" :nextText="'Next'"
                            :active-class="myActiveBtn" :containerClass="'pagination'" class="pagination col-md-12" />
                    </div>
                </div>
            </div>
            <div class="card" v-else>
                <h3 class="text-info text-center">No Data Found</h3>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleFilterModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
            <div class="modal-dialog modal-lx">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title">Employee Creation</h5> -->
                        <button type="button" id="filterClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card border-top border-0 border-4 border-primary">
                            <div class="card-body p-5">
                                <div class="card-title d-flex align-items-center">
                                    <div>
                                        <i class="bx bxs-search me-1 font-22 text-primary"></i>
                                    </div>
                                    <h5 class="mb-0 text-primary">Search</h5>
                                </div>
                                <hr />
                                <form class="row g-3">
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">Employee Code</label>
                                        <select v-model="searchEmployee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_code }}
                                            </option>
                                        </select>
                                        
                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">Employee Name</label>
                                        <select v-model="searchEmployee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_name }}
                                            </option>
                                        </select>

                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">Start Date</label>
                                        <input
                                        type="date"
                                        v-model="searchStartDate"
                                        class="form-control"
                                        id="inputEmail"
                                        />

                                    </div>
                                    <div class="col-md-6">
                                        <label for="inputState" class="form-label">End Date</label>
                                        <input
                                        type="date"
                                        v-model="searchEndDate"
                                        class="form-control"
                                        id="inputEmail"
                                        />

                                    </div>

                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" v-on:click="searchEmpInfo()" 
                            class="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="exampleLargeModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title">Employee Creation</h5> -->
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card border-top border-0 border-4 border-primary">
                            <div class="card-body p-5">
                                <div class="card-title d-flex align-items-center">
                                    <div>
                                        <i class="bx bxs-user me-1 font-22 text-primary"></i>
                                    </div>
                                    <h5 class="mb-0 text-primary">Employee Shift Policy Creation</h5>
                                </div>
                                <hr />
                                <form class="row g-3">
                                    <div class="col-md-4" v-if="!applicable_to_all">
                                        <label for="inputState" class="form-label">Employee Code<span style="color: red; font-size: 18px"
                                            >*</span
                                        ></label>
                                        <select v-model="employee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_code }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4" v-if="!applicable_to_all">
                                        <label for="inputState" class="form-label">Employee Name<span style="color: red; font-size: 18px"
                                            >*</span
                                        ></label>
                                        <select v-model="employee" id="inputState" class="form-select single-select">
                                            <option v-for="(option, key) in employees" :key="key" :value="option">
                                                {{ option.emp_name }}
                                            </option>
                                        </select>

                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputState" class="form-label">Date<span style="color: red; font-size: 18px"
                                            >*</span
                                        ></label>
                                        <input
                                        type="date"
                                        v-model="date"
                                        class="form-control"
                                        id="inputEmail"
                                        />

                                    </div>

                                    <div class="col-md-3">
                                        <label for="inputPhone" class="form-label"
                                        >Time In<span style="color: red; font-size: 18px"
                                            >*</span
                                        ></label
                                        >
                                        <br>
                                        <vue-timepicker v-model="time_in" ></vue-timepicker>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="inputPhone" class="form-label"
                                        >Time Out<span style="color: red; font-size: 18px"
                                            >*</span
                                        ></label
                                        >
                                        <br>
                                        <vue-timepicker v-model="time_out" ></vue-timepicker>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" v-on:click="createEmployeeShiftPolicy()" data-bs-dismiss="modal"
                            class="btn btn-primary">
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Update Modal -->
        

        <!-- View Modal -->
        <div class="modal fade" id="exampleLargeModal3" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title">Employee Creation</h5> -->
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="card border-top border-0 border-4 border-warning">
                            <div class="card-body p-5">
                                <form class="row g-3">
                                    <!-- <div class="col-md-4">
                                        <label for="inputState" class="form-label">Employee Code</label>
                                        <input type="text" v-model="attendance_log_details.employee.emp_code"
                                            class="form-control" id="inputPhone" placeholder="Enter Grace Time"
                                            disabled />
                                    </div>
                                    <div class="col-md-4">
                                        <label for="inputState" class="form-label">Employee Name</label>
                                        <input type="text" v-model="attendance_log_details.employee.emp_name"
                                            class="form-control" id="inputPhone" placeholder="Enter Grace Time"
                                            disabled />

                                    </div> -->

                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <!-- Delete Modal -->
        <div class="modal fade" id="exampleVerticallycenteredModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Are You Sure?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="button" v-on:click="deleteEmployeeShiftPolicy()" data-bs-dismiss="modal"
                            class="btn btn-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="../js/employee_attendance.js"></script>