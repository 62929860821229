import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { authHeader } from "../../../../auth";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            section_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            name: '',
            departments: [],
            department: {},
            section_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.configurations.sectionAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if(this.search) {
            params += "&search=" + this.search;
        }
        let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint + params;
        this.getDataList(section_url);
        let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint;
        let config = {
            method: "GET",
            url: department_url,
            headers: authHeader()
        }
        this.$axios(config).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data.data)) {
                    this.departments.push({
                        id: response.data.data[key].id,
                        name: response.data.data[key].title
                    });
                }
                //console.log("All data list: ", this.departments);
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint + params;
            await this.getDataList(section_url);
        },
        deleteInfo: function(id) {
            this.section_id = id;
        },
        deleteSection: async function() {
            let section_details_url = this.$api + this.$config.configurations.sectionDetails + this.section_id;
            await this.$axios.delete(section_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(section_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateSection: async function() {
            //console.log("cmployee Details is: ", this.section_details);
            let section_details_url = this.$api + this.$config.configurations.sectionDetails + this.section_id;
            await this.$axios.put(section_details_url, {
                department_id: this.section_details.department.id,
                department_name: this.section_details.department.name,
                section_name: this.section_details.name,

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(section_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getDepartmentData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.section_id = id;
            let section_details_url = this.$api + this.$config.configurations.sectionDetails + this.section_id;
            //console.log(section_details_url);
            await this.$axios.get(section_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.section_details.name = response.data.section_name;
                    this.section_details.department = {
                        id: response.data.department_id,
                        name: response.data.department_name
                    }

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createSection: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.configurations.sectionAPIEndPoint
            await this.$axios.post(url, {
                department_id: this.department.id,
                department_name: this.department.name,
                section_name: this.name,
            }).then(async(response) => {
                if (response.status == 201) {
                    //console.log("department Created Successfully");
                    this.$toast.success(response.data.message);
                    let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(section_url)
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}