import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { authHeader } from "../../../../auth";
import Mixin from "../../../../mixin";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            allDataList: [],
            emp_id: null,
            page: 1,
            limit: 10,
            pageLimit: [5,10, 20, 50, 100],
            total_pages: 5,
            search: '',
            id: null,
            selectedCertificateFiles: [],
            selectedVerificationFiles: [],
            certificates: [],
            verifications: [],
            roles: [],
            countries: [],
            salary_issue_mediums: [],
            salary_grades: [],
            designations: [],
            blood_groups: [],
            sections: [],
            genders: [],
            marital_statuses: [],
            departments: [],
            companies: [],
            cities: [],
            branches: [],
            city: {},
            branch: {},
            section: {},
            company: {},
            role: {},
            country: '',
            department: {},
            marital_status: '',
            gender: '',
            emp_code: '',
            designation: {},
            blood_group: '',
            date_of_joining: '',
            setURL: '',
            region: '',
            phone: null,
            selected_image: '',
            firstname: '',
            lastname: '',
            email: '',
            image: '',
            salary_issue_medium: {},
            salary_grade: {
                id: null,
                name: ''
            },
            stuck_off_date: '',
            select_probation_month: '',
            date_of_probation: '',
            employee_type: '',
            selected_salary_grade: false,
            emp_details: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                emp_code: '',
                marital_status: {},
                company: {},
                country: {},
                city: {},
                blood_group: {},
                gender: {},
                branch: {},
                department: {},
                section: {},
                role: {},
                designation: {}
            },
            certificatesType:[],
            tableRows: [{
                certificatesType: '',
            }],
            currentIndex: 0,
            gross_salary: 0,
            basic_salary: 0,
            house_rent: 0,
            medical: 0,
            conveyance: 0,
            isActive: true,
            searchCompany:[],
        }
    },
    mixins: [Mixin],
    mounted() {
        

        this.marital_statuses = [{
                id: 1,
                name: 'Married'
            },
            {
                id: 2,
                name: 'Unmarried'
            },
        ];
        this.genders = [{
                id: 0,
                name: "Male"
            },
            {
                id: 1,
                name: "Female"
            },
            {
                id: 2,
                name: "Other"
            }

        ]
        this.blood_groups = [{
                id: 0,
                name: "O+"
            },
            {
                id: 1,
                name: "O-"
            },
            {
                id: 2,
                name: "A+"
            },
            {
                id: 3,
                name: "A-"
            },
            {
                id: 4,
                name: "B+"
            },
            {
                id: 5,
                name: "B-"
            },
            {
                id: 6,
                name: "AB+"
            },
            {
                id: 7,
                name: "AB-"
            }

        ]
        
        this.salary_issue_mediums = [{
                id: 0,
                name: "Cash"
            },
            {
                id: 1,
                name: "Cheque"
            },

            {
                id: 2,
                name: "Bank Account"
            }
        ];
   
    },
    async created() {
        document.title = "HrConnect - Employee";
        await this.getBranchInfo();
    },
    watch: {
        // salary_grade: function() {
        //     this.selected_salary_grade = true;
        // }.minDateProbation
    },
    methods: {
        async getBranchInfo(){
            let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint;
            let configBranch = {
                method: "GET",
                url: branch_url,
                headers: authHeader()
            }
            this.$axios(configBranch).then(async(response) => {
                await this.getCityInfo();
                await this.getSectionInfo();
                await this.getCompanyInfo();
                await this.getRoleInfo();
                await this.getDepartmentInfo();
                await this.getCountryInfo();
                await this.getDesignationInfo();

                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.branches.push({
                            id: response.data[key].id,
                            name: response.data[key].name
                        });
                    }

                } else {
                    this.isLoading = false;
                }
            }).catch(error => {
                //console.log(error);
                this.isLoading = false
                if (error.response.status == 401) {
                    this.redirectToLoginPage();
                } else{
                    this.$toast.error(error.response.data.message);
                }
            });
        },
        async getCityInfo(){
            let city_url = this.$api + this.$config.configurations.cityAPIEndPoint;
            let configCity = {
                method: "GET",
                url: city_url,
                headers: authHeader()
            }
            this.$axios(configCity, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.cities.push({
                            id: response.data[key].id,
                            name: response.data[key].city_name
                        });
                    }

                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getSectionInfo(){
            let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint;
            let configSection = {
                method: "GET",
                url: section_url,
                headers: authHeader()
            }
            this.$axios(configSection, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.sections.push({
                            id: response.data[key].id,
                            name: response.data[key].section_name
                        });
                    }

                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getCompanyInfo(){
            let company_url = this.$api + this.$config.configurations.companyAPIEndPoint
            let configCompany = {
                method: "GET",
                url: company_url,
                headers: authHeader()
            }
            this.$axios(configCompany, {}).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.companies.push({
                            id: response.data[key].id,
                            name: response.data[key].company_name
                        });
                    }
                    this.searchCompany = this.companies[0]
                    this.getEmployeeList(this.searchCompany.name);
                }

            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getRoleInfo(){
            let role_url = this.$api + this.$config.role.roleAPIEndPoint;
            let configRole = {
                method: "GET",
                url: role_url,
                headers: authHeader()
            }
            this.$axios(configRole, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.roles.push({
                            id: response.data[key].id,
                            name: response.data[key].title
                        });
                    }
    
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getDepartmentInfo(){
            let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint;
            let configDepartment = {
                method: "GET",
                url: department_url,
                headers: authHeader()
            }
            this.$axios(configDepartment, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.departments.push({
                            id: response.data[key].id,
                            name: response.data[key].title
                        });
                    }

                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getCountryInfo(){
            let country_url = this.$api + this.$config.configurations.countryAPIEndPoint;
            let configCountry = {
                method: "GET",
                url: country_url,
                headers: authHeader()
            }
            this.$axios(configCountry, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.countries.push({
                            id: response.data[key].id,
                            name: response.data[key].country_name
                        });
                    }

                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        async getDesignationInfo(){
            let designation_url = this.$api + this.$config.configurations.designationAPIEndPoint;
            let configDesignation = {
                method: "GET",
                url: designation_url,
                headers: authHeader()
            }
            this.$axios(configDesignation, {}).then((response) => {
                if (response.status == 200) {
                    for (const key in Object.keys(response.data)) {
                        this.designations.push({
                            id: response.data[key].id,
                            name: response.data[key].title
                        });
                    }

                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);

            });
        },

        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        downloadWithAxios(url, title) {
            this.$axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then(async (response) => {
                await this.forceFileDownload(response, title)
                })
                .catch(() => console.log('Failed to download'))
        },
        addNewRow() {
            this.tableRows.push({
                certificatesType: '',
            });
            this.currentIndex++
        },
        deleteRow(index) {
        this.tableRows.splice(index, 1);
        this.selectedCertificateFiles.splice(index, 1);
        this.currentIndex--
        },
        deleteInfo: function(id) {
            this.emp_id = id;
        },
        grossSalary(){
            let gross_salary = this.gross_salary;
            this.basic_salary = ((gross_salary * 60) / 100);
            this.house_rent = ((gross_salary * 30) / 100);
            this.medical = ((gross_salary * 5) / 100);
            this.conveyance = ((gross_salary * 5) / 100);
        },
        EditGrossSalary(grossSalary){
            let gross_salary = grossSalary;
            this.emp_details.salary_info.basic_salary = ((gross_salary * 60) / 100);
            this.emp_details.salary_info.house_rent = ((gross_salary * 30) / 100);
            this.emp_details.salary_info.medical = ((gross_salary * 5) / 100);
            this.emp_details.salary_info.conveyance = ((gross_salary * 5) / 100);
        },
        getEmployeeList: async function(comID) {
            
            let params = "?page=" + this.page + "&limit=" + this.limit+'&active='+this.isActive+'&company_name='+comID;
            if(this.search) {
                params += "&search=" + this.search;
            }
            let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint + params;
            let config = {
                method: "GET",
                url: emp_url,
                headers: authHeader()
            }
            // console.log("employee API request",emp_url);
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    this.dataList = [];
                    this.dataList = response.data.data;
                    this.total_pages = response.data.last_page;
                }
            }).catch(error => {
                this.isLoading = false
                console.log(error.response);
                if (error.response.status == 401) {
                    this.redirectToLoginPage();
                } else {
                    this.$toast.error(error.response.data.message);
                }
                

            });
        },
        selectedCompany(row){
            console.log('selectedCompany', row)
            this.getEmployeeList(this.searchCompany.name);
        },
        searchData: function() {
            this.updateHandler();
        },
        deleteEmployee: async function() {
            //console.log("Employee Details is: ", this.emp_details);
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            let configDeleteEmp = {
                method: "DELETE",
                url: emp_details_url,
                headers: authHeader()
            }
            await this.$axios(configDeleteEmp, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    
                    this.$toast.success(response.data.message);
                   await this.getEmployeeList(this.searchCompany.name);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error);
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        isActiveStatus: async function() {
            this.getEmployeeList(this.searchCompany.name);
        },
        updateEmployee: async function() {
            const emp_data = new FormData();
            if(this.emp_details.is_active==false){
                if(!this.emp_details.lastWorkingDate){
                    this.$toast.error("Please employee last working date!");
                    return false
                }
                // console.log("this.emp_details.lastWorkingDate",this.emp_details.lastWorkingDate?this.emp_details.lastWorkingDate:'');
            }
            emp_data.append("last_office_date", this.emp_details.lastWorkingDate?this.emp_details.lastWorkingDate:'');
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            emp_data.append("emp_code", this.emp_details.emp_code);
            emp_data.append("company_id", this.emp_details.company.id);
            emp_data.append("company_name", this.emp_details.company.name);
            emp_data.append("first_name", this.emp_details.first_name);
            emp_data.append("last_name", this.emp_details.last_name);
            emp_data.append("country_id", this.emp_details.country.id);
            emp_data.append("country_name", this.emp_details.country.name);
            emp_data.append("city_id", this.emp_details.city.id);
            emp_data.append("city_name", this.emp_details.city.name);
            emp_data.append("blood_group_id", this.emp_details.blood_group.id);
            emp_data.append("blood_group_title", this.emp_details.blood_group.name);
            emp_data.append("marital_status", this.emp_details.marital_status.name);
            emp_data.append("gender_id", this.emp_details.gender.id);
            emp_data.append("gender_title", this.emp_details.gender.name);
            emp_data.append("email", this.emp_details.email);
            emp_data.append("phone", this.emp_details.phone);
            emp_data.append("branch_id", this.emp_details.branch.id);
            emp_data.append("branch_name", this.emp_details.branch.name);
            emp_data.append("department_id", this.emp_details.department.id);
            emp_data.append("department_name", this.emp_details.department.name);
            emp_data.append("section_id", this.emp_details.section.id);
            emp_data.append("section_name", this.emp_details.section.name);
            emp_data.append("role_id", this.emp_details.role.id);
            emp_data.append("role_name", this.emp_details.role.name);
            emp_data.append("designation_id", this.emp_details.designation.id);
            emp_data.append("designation_name", this.emp_details.designation.name);
            emp_data.append("date_of_joining", this.emp_details.date_of_joining);
            // emp_data.append("image", this.emp_details.image);
            if(this.selected_image){
                emp_data.append("image", this.selected_image);
            }
            

            // console.log("this.emp_details: ", this.emp_details);
            if(this.emp_details.probation_date){
                emp_data.append("probation_date", this.emp_details.probation_date);
            }
            emp_data.append("employee_type", this.emp_details.employee_type);

            let employee_name = this.emp_details.first_name + " " + this.emp_details.last_name;
            emp_data.append("emp_name", employee_name);

            let certificateFile = [];

            for (let i = 0; i < this.selectedCertificateFiles.length; i++) {
                certificateFile.push({
                    name: this.tableRows[i]['certificatesType'].trim(),
                    files: this.selectedCertificateFiles[i]
                })
            }
            for (let i = 0; i < certificateFile.length; i++) {
                emp_data.append("certificate_file[]", certificateFile[i].files[0]);
                emp_data.append("certificate_name[]", certificateFile[i].name?certificateFile[i].name:'File');
            }

            emp_data.append("gross_salary", this.emp_details.salary_info.gross_salary?this.emp_details.salary_info.gross_salary:0);
            emp_data.append("basic_salary", this.emp_details.salary_info.basic_salary?this.emp_details.salary_info.basic_salary:0);
            emp_data.append("house_rent", this.emp_details.salary_info.house_rent?this.emp_details.salary_info.house_rent:0);
            emp_data.append("medical", this.emp_details.salary_info.medical?this.emp_details.salary_info.medical:0);
            emp_data.append("conveyance", this.emp_details.salary_info.conveyance?this.emp_details.salary_info.conveyance:0);

            emp_data.append("is_active", this.emp_details.is_active);
            emp_data.append("emergency_number", this.emp_details.emergency_number);
            

            let config = {
                method: "PUT",
                url: emp_details_url,
                data: emp_data,
                headers: authHeader()
                // headers: {
                //     "Content-Type": "multipart/form-data"
                // }
            }
            await this.$axios(config).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    this.$toast.success(response.data.message);
                } else {
                    this.isLoading = false
                }
                document.getElementById('closeEditEmployee').click();
                await this.getEmployeeList(this.searchCompany.name);
            }).catch(error => {
                let err = error.response.data.errors
                if(err){
                    for (const property in err) {
                        this.$toast.error(`${err[property]}`);
                      }
                } else {
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false
            });
        },
        getEmployeeData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            let configGetEmp = {
                method: "GET",
                url: emp_url,
                headers: authHeader()
            }
            await this.$axios(configGetEmp, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        downloadFile: async function(url) {
            //console.log("Url is: ", this.$backend + url);
            let new_url = this.$backend + url;
            let new_label = url.split('/')[5].split(".")[0];
            // let data_type = url.split('/')[5].split(".")[1];
            //console.log("data type: ", data_type);
            let configDownloadFile = {
                method: "GET",
                url: new_url,
                headers: authHeader()
            }
            await this.$axios(configDownloadFile, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        editForm: async function(id, emp_code) {
            this.emp_id = id;
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            //console.log(emp_details_url);
            let configEditEmp = {
                method: "GET",
                url: emp_details_url,
                headers: authHeader()
            }
            await this.$axios(configEditEmp, {}).then((response) => {
                if (response.status == 200) {
                    this.emp_details.first_name = response.data.data.first_name;
                    this.emp_details.last_name = response.data.data.last_name;
                    this.emp_details.email = response.data.data.email;
                    this.emp_details.phone = response.data.data.phone;
                    this.emp_details.emp_code = response.data.data.emp_code;
                    this.emp_details.date_of_joining = response.data.data.date_of_joining;
                    this.emp_details.probation_date = response.data.data.probation_date;
                    this.emp_details.employee_type = response.data.data.employee_type;
                    this.emp_details.image = this.$backend + response.data.data.image;
                    this.emp_details.salary_info =  response.data.salary_info;
                    this.emp_details.is_active =  response.data.data.is_active;
                    this.emp_details.lastWorkingDate =  response.data.data.last_office_date;
                    this.emp_details.emergency_number =  response.data.data.emergency_number;
                    // console.log("this.emp_details: ", response);
                    // console.log("this.emp_details: ", this.emp_details);
                    if (response.data.data.marital_status == "Married") {
                        this.emp_details.marital_status = {
                            id: 1,
                            name: "Married"
                        }
                    } else if (response.data.data.marital_status == "Unmarried") {
                        this.emp_details.marital_status = {
                            id: 2,
                            name: "Unmarried"
                        }
                    }
                    this.emp_details.country = {
                        id: response.data.data.country_id,
                        name: response.data.data.country_name
                    }
                    this.emp_details.company = {
                        id: response.data.data.company_id,
                        name: response.data.data.company_name
                    }
                    this.emp_details.city = {
                        id: response.data.data.city_id,
                        name: response.data.data.city_name
                    }
                    this.emp_details.blood_group = {
                        id: response.data.data.blood_group_id,
                        name: response.data.data.blood_group_title
                    }
                    this.emp_details.gender = {
                        id: response.data.data.gender_id,
                        name: response.data.data.gender_title
                    }
                    this.emp_details.branch = {
                        id: response.data.data.branch_id,
                        name: response.data.data.branch_name
                    }
                    this.emp_details.department = {
                        id: response.data.data.department_id,
                        name: response.data.data.department_name
                    }
                    this.emp_details.section = {
                        id: response.data.data.section_id,
                        name: response.data.data.section_name
                    }
                    this.emp_details.role = {
                        id: response.data.data.role_id,
                        name: response.data.data.role_name
                    }
                    this.emp_details.designation = {
                        id: response.data.data.designation_id,
                        name: response.data.data.designation_name
                    }
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
            let certificate_url = this.$api + this.$config.configurations.certificateList + "?emp_code=" + emp_code;
            //console.log(certificate_url)
            let configCertificateEmp = {
                method: "GET",
                url: certificate_url,
                headers: authHeader()
            }
            await this.$axios(configCertificateEmp).then((response) => {
                if (response.status == 200) {
                    this.certificates = [];
                    for (const key in Object.keys(response.data)) {
                        this.certificates.push({
                            id: response.data[key].id,
                            emp_code: response.data[key].emp_id,
                            emp_name: response.data[key].emp_name,
                            file: response.data[key].file,
                            name: response.data[key].name,
                            filename: response.data[key].file.split('/')[5]
                        });
                    }
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            //console.log("Certificates are: ", this.certificates);

        },
        uploadCertificates: function(event) {         
            this.selectedCertificateFiles.push( event.target.files);
        },
        uploadVerifications: function(event) {
            this.selectedVerificationFiles = event.target.files;
            //console.log("Selected Files: ", this.selectedVerificationFiles);
        },
        uploadImage: function(event) {
            this.selected_image = event.target.files[0];
        },
        updateImage: function(event) {
            this.selected_image = event.target.files[0];
        },
        closeAddEmployee(){
            this.emp_code = ''
            this.company = ''
            this.firstname = ''
            this.lastname = ''
            this.country = ''
            this.city = ''
            this.blood_group = ''
            this.gender = ''
            this.email = ''
            this.phone = ''
            this.branch = ''
            this.department = ''
            this.section = ''
            this.role = ''
            this.designation = ''
            this.date_of_joining = ''
            this.selected_image = ''
            this.employee_name = ''
            this.gross_salary = 0
            this.basic_salary = 0
            this.house_rent = 0
            this.medical = 0
            this.conveyance = 0
            // this.this.salary_grade = {
            //     id: null,
            //     name: ''
            // }
            // this.this.salary_issue_medium = ''
            // if(this.selectedCertificateFiles){
            //     this.$refs.uploadFileCertificates.reset();
            // }
            // if(this.selectedVerificationFiles){
            //     this.$refs.uploadFileVerifications.reset();
            // }
            if(this.selected_image){
                this.$refs.uploadEmpImg.reset();
            }
        },
        formValidation(){
            console.log(this.employee_type);
            if(!this.firstname){
                this.$toast.error("Please enter first name");
                return false;
            } else if(!this.lastname){
                this.$toast.error("Please enter last name");
                return false;
            } else if(!this.marital_status){
                this.$toast.error("Please select marital status");
                return false;
            } else if(Object.keys(this.company).length === 0){
                this.$toast.error("Please select a company");
                return false;
            } else if(!this.gender){
                this.$toast.error("Please select gender");
                return false;
            } else if(!this.email){
                this.$toast.error("Please enter email address");
                return false;
            } else if(!this.phone){
                this.$toast.error("Please enter phone number");
                return false;
            } else if(Object.keys(this.branch).length === 0){
                this.$toast.error("Please select branch");
                return false;
            } else if(Object.keys(this.department).length === 0){
                this.$toast.error("Please select department");
                return false;
            } else if(Object.keys(this.section).length === 0){
                this.$toast.error("Please select section");
                return false;
            } else if(Object.keys(this.role).length === 0){
                this.$toast.error("Please select role");
                return false;
            } else if(Object.keys(this.designation).length === 0){
                this.$toast.error("Please select designation");
                return false;
            } else if(!this.emp_code){
                this.$toast.error("Please enter a valid employee code");
                return false;
            } else if(!this.date_of_joining){
                this.$toast.error("Please select date of joining");
                return false;
            } else if(!this.employee_type){
                this.$toast.error("Please select employee type");
                return false;
            } else if(!this.gross_salary){
                this.$toast.error("Please enter gross salary");
                return false;
            } else if(!this.selected_image){
                this.$toast.error("Please select a employee photo");
                return false;
            }
            return true;
        },
        createEmployee: async function() {
            if(this.formValidation()){
                //console.log("Employee Creation Method");
                let url = this.$api + this.$config.employees.employeeAPIEndPoint;
                const emp_data = new FormData();
                emp_data.append("emp_code", this.emp_code);
                emp_data.append("company_id", this.company.id);
                emp_data.append("company_name", this.company.name);
                emp_data.append("first_name", this.firstname);
                emp_data.append("last_name", this.lastname);
                emp_data.append("country_id", this.country.id);
                emp_data.append("country_name", this.country.name);
                emp_data.append("city_id", this.city.id);
                emp_data.append("city_name", this.city.name);
                emp_data.append("blood_group_id", this.blood_group.id);
                emp_data.append("blood_group_title", this.blood_group.name);
                emp_data.append("marital_status", this.marital_status.name);
                emp_data.append("gender_id", this.gender.id);
                emp_data.append("gender_title", this.gender.name);
                emp_data.append("email", this.email);
                emp_data.append("phone", this.phone);
                emp_data.append("branch_id", this.branch.id);
                emp_data.append("branch_name", this.branch.name);
                emp_data.append("department_id", this.department.id);
                emp_data.append("department_name", this.department.name);
                emp_data.append("section_id", this.section.id);
                emp_data.append("section_name", this.section.name);
                emp_data.append("role_id", this.role.id);
                emp_data.append("role_name", this.role.name);
                emp_data.append("designation_id", this.designation.id);
                emp_data.append("designation_name", this.designation.name);
                emp_data.append("date_of_joining", this.date_of_joining);

                if(this.select_probation_month){
                    let probation_date = this.$date.addMonth(this.date_of_joining,this.select_probation_month);
                    emp_data.append("probation_date", probation_date);
                }
                emp_data.append("employee_type", this.employee_type);

                emp_data.append("is_stuck_off", false);
                emp_data.append("image", this.selected_image);
                let employee_name = this.firstname + " " + this.lastname;
                emp_data.append("emp_name", employee_name);

                // console.log("this.tableRows",this.tableRows);
                let certificateFile = [];

                for (let i = 0; i < this.selectedCertificateFiles.length; i++) {
                    certificateFile.push({
                        name: this.tableRows[i]['certificatesType'].trim(),
                        files: this.selectedCertificateFiles[i]
                    })
                }
                for (let i = 0; i < certificateFile.length; i++) {
                    emp_data.append("certificate_file[]", certificateFile[i].files[0]);
                    emp_data.append("certificate_name[]", certificateFile[i].name?certificateFile[i].name:'File');
                }
                //emp_data.append("certificate_file", (certificateFile));

                /*for (let i = 0; i < this.selectedVerificationFiles.length; i++) {
                    emp_data.append("verification_file", this.selectedVerificationFiles[i]);
                }*/

                // emp_data.append("salary_grade_id", this.salary_grade.id);
                // emp_data.append("salary_code", this.salary_grade.salary_code);
                // emp_data.append("salary_issue_medium", this.salary_issue_medium.id);
                emp_data.append("gross_salary", this.gross_salary);
                emp_data.append("basic_salary", this.basic_salary);
                emp_data.append("house_rent", this.house_rent);
                emp_data.append("medical", this.medical);
                emp_data.append("conveyance", this.conveyance);

                let emp_config = {
                    method: "POST",
                    data: emp_data,
                    url: url,
                    headers: authHeader()
                }
                // await this.postDataToBackend(emp_config);
                await this.$axios(emp_config).then((response) => {
                    this.isLoading = false
                    if (response.status == 201) {
                        this.$toast.success(response.data.message);
                    }
                    this.clearEmployeeData();
                    document.getElementById('closeAddEmployee').click();
                }).catch(error => {
                    let err = error.response.data.errors
                    if(err){
                        for (const property in err) {
                            this.$toast.error(`${err[property]}`);
                          }
                    } else {
                        this.$toast.error(error.response.data.message);
                    }
                    this.isLoading = false
                });
                await this.getEmployeeList(this.searchCompany.name);
                
            }

        },
        clearEmployeeData(){
            this.city = {};
            this.branch = {};
            this.section = {};
            this.company = {};
            this.role = {};
            this.country = '';
            this.department = {};
            this.marital_status = '';
            this.gender = '';
            this.emp_code = '';
            this.designation = {};
            this.blood_group = '';
            this.date_of_joining = '';
            this.setURL = '';
            this.region = '';
            this.phone = null;
            this.selected_image = '';
            this.firstname = '';
            this.lastname = '';
            this.email = '';
            this.image = '';
            this.gross_salary = 0;
            this.basic_salary = 0
            this.house_rent = 0
            this.medical = 0
            this.conveyance = 0
            // this.salary_issue_medium = {};
            // this.salary_grade = {
            //     id: null,
            //     name: ''

            // };
            // this.selected_salary_grade = false;
            this.emp_details = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                emp_code: '',
                marital_status: {},
                company: {},
                country: {},
                city: {},
                blood_group: {},
                gender: {},
                branch: {},
                department: {},
                section: {},
                role: {},
                designation: {}
            }
        },
        createStuckOff: async function() {
            let url = this.$api + this.$config.employees.stuckOffEndPoint;
            let data = {
                emp_code: this.emp_details.emp_code,
                stuck_off_date: this.stuck_off_date
            };
            let config = {
                url: url,
                method: "POST",
                data: data
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$toast.success(response.data.message);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            await this.getEmployeeList(this.searchCompany.name);
        },
        updateHandler: async function() {
            await this.getEmployeeList(this.searchCompany.name);
        },
        bulkUpload: async function() {
            let formData = new FormData();
            formData.append('file', this.selectedFile);
           
            let config = {
                method: "POST",
                url: this.$api + this.$config.employees.bulkUpload,
                data: formData
            }
            await this.postDataToBackend(config);
            
        },
        chooseFile: async function() {
          document.getElementById("fileUpload").click();
        },
        uploadFile: async function(event) {
          this.selectedFile = event.target.files[0];
          console.log("event.target.files",event.target.files);
          await this.bulkUpload();
        }
    }
}