import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";
import { authHeader } from "../../../../auth";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            leave_application_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            policy: {},
            emp_code: null,
            emp_id: null,
            employee: {},
            employees: [],
            policies: [],
            allDataList: [],
            leave_balance_data: [],
            leave_start_date: '',
            leave_end_date: '',
            total_leave: null,
            is_half_day: false,
            leave_status: 1,
            day_parts: [],
            day_part: {},
            leave_application_details: {},
            leave_approval_history_list: {},
            isEmployeeSelected: false,
            reason: null,
            delegated_employee_remarks: null
        }
    },

    async created() {
        document.title = "HrConnect - Employee";
        await this.getLeaveApplicationPendingData();
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function  () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_application_url = this.$api + this.$config.leave.leaveApplicationPendingList + params;
            await this.getDataList(leave_application_url);
        },


        getLeaveApplicationPendingData: async function() {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_application_url = this.$api + this.$config.leave.leaveApplicationPendingList + params;
            let configLeaveApplication = {
                method: "GET",
                url: leave_application_url,
                headers: authHeader()
            }
            await this.$axios(configLeaveApplication, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.allDataList = []
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.leave_application_id = id;
            let leave_application_details_url = this.$api + this.$config.leave.leaveApplicationDetails + this.leave_application_id;
            let configEditLeaveApplication = {
                method: "GET",
                url: leave_application_details_url,
                headers: authHeader()
            }
            await this.$axios(configEditLeaveApplication, {}).then((response) => {
                if (response.status == 200) {

                    this.leave_application_details.emp_id = response.data.employee_id;
                    this.leave_application_details.emp_code = response.data.employee_code;
                    this.leave_application_details.emp_name = response.data.employee_name;
                    this.leave_application_details.leave_policy_id = response.data.leave_policy_id;
                    this.leave_application_details.leave_policy_name = response.data.leave_policy_name;
                    this.leave_application_details.leave_start_date = response.data.leave_start_date;
                    this.leave_application_details.leave_end_date = response.data.leave_end_date;
                    this.leave_application_details.total_days = response.data.total_leave_day;
                    this.leave_application_details.is_half_day = response.data.is_half_day;
                    this.leave_application_details.delegated_employee_remarks = response.data.leave_reason,
                    this.leave_application_details.leave_status = response.data.leave_status == 0 ? "Draft" : response.data.leave_status == 1 ? "Pending" : response.data.leave_status == 2 ? "In Progress" : response.data.leave_status == 3 ? "Approved" : response.data.leave_status == 4 ? "Rejected" : response.data.leave_status == 5 ? "Cancelled" : "Draft";

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            let leave_approval_history = this.$api + this.$config.leave.leaveApplicationApprovalAPIEndPoint + "?leave_application_id=" + this.leave_application_id;
            let configApproveLeaveApplication = {
                method: "GET",
                url: leave_approval_history,
                headers: authHeader()
            }
            await this.$axios(configApproveLeaveApplication, {}).then((response) => {
                if (response.status == 200) {
                    // //console.log("Data is", response.data);
                    this.leave_approval_history_list = response.data.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },


    }
}