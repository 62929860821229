<template>
    <body class="bg-login">
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
        <div class="wrapper">
            <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                <div class="container-fluid">
                    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                        <div class="col mx-auto">
                            <div class="mb-4 text-center">
                                <img src="../../../../public/core/assets/images/hrconnect.jpg" width="180" alt=""/>
                                <h1>HRConnect</h1>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                        <!-- <router-link to="/login" class="btn btn-info">Back</router-link> -->
                                        <div class="login-separater text-center mb-1"><span>Password Reset</span>
                                            <hr/>
                                        </div>
                                        <div class="form-body">
                                            <form class="row g-3" @submit.prevent="submit">
                                                <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Email</label>
                                                    <input type="email" v-model="email" class="form-control" id="inputEmailAddress" placeholder="Enter email address" disabled>
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input :type="inputType" v-model="password" class="form-control border-end-0" id="inputChoosePassword" placeholder="Enter Password"><a @click="changeInputType()" href="javascript:void(0)" class="input-group-text bg-transparent"><i :class="iconFlag ? 'bx bx-hide' : 'bx bx-show'"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChooseConfPassword" class="form-label">Confirm Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input :type="inputTypeCon" v-model="conPassword" class="form-control border-end-0" id="inputChooseConfPassword" placeholder="Enter Password"><a @click="changeInputTypeCon()" href="javascript:void(0)" class="input-group-text bg-transparent"><i :class="iconFlagCon ? 'bx bx-hide' : 'bx bx-show'"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="btn btn-primary">Send Reset Link</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script src="../js/resetPassword.js"></script>
