<template>
    <div class="page-wrapper">
			<div class="page-content">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
				<!--breadcrumb-->
				<div class="page-breadcrumb d-sm-flex align-items-center mb-3">
					<div class="breadcrumb-title pe-3">Tables</div>
					<div class="ps-3">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb mb-0 p-0">
								<li class="breadcrumb-item"><a href="javascript:;"><i class="bx bx-home-alt"></i></a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">Employee Leave Approbal History</li>
							</ol>
						</nav>
					</div>
					<div class="ms-auto">
						<div class="btn-group">
							<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleLargeModal">Create Leave Balance</button>
						</div>
					</div>
				</div>
				<!--end breadcrumb-->
				<h6 class="mb-0 text-uppercase">Employee Leave Approval History List</h6>
				<hr/>
				<div class="card" v-if="Object.keys(allDataList).length > 0">
					<div class="card-body">
							<div class="row mb-3">
								<div class="col-sm-12 col-md-6">
									<div class="dataTables_length" id="example_length">
								<label>Show <select v-model="limit" name="example_length" aria-controls="example" class="form-select form-select-sm mt-1">
									<option v-for="(option, key) in pageLimit" :key="key" :value="option">{{option}}</option>
									</select>
									</label>
									</div>
									</div>
									<div class="col-sm-12 col-md-6">
										<div id="example_filter" class="dataTables_filter text-end">
											<label class="text-start ms-2">Search:<input type="search" v-model="search" class="form-control form-control-sm mt-1" placeholder="" aria-controls="example">
											</label>
											<button type="button" @click="searchData" class="btn btn-primary px-4 radius-30 ms-2">Go</button>
										</div>
										
									</div>
									
							</div>
							<div class="table-responsive">
							<table class="table table-striped table-bordered">
								<thead>
									<tr>
										<th>Sl. No</th>
                                        <th>Employee ID</th>
										<th>Employee Code</th>
										<th>Delegation DateTime</th>
										<th>Delegated Employee ID</th>
                                        <th>Delegated Employee Code</th>
                                        <th>Delegated Employee Remarks</th>
                                        <th>Delegated Employee Status</th>
                                        <th>Action</th>
									</tr>
								</thead>
								<tbody v-if="Object.keys(allDataList).length > 0">
									<tr v-for="(li,index) in allDataList" :key="index">
										<td>{{index + 1}}</td>
										<td>{{li.employee_id}}</td>
                                        <td>{{li.employee_code}}</td>
                                        <td>{{li.delegation_date_time}}</td>
                                        <td>{{li.delegated_employee_id}}</td>
                                        <td>{{li.delegated_employee_code}}</td>
                                        <td>{{li.delegated_employee_remarks}}</td>
                                        <td>{{li.delegated_employee_status}}</td>
										
										<td>
											<div class="col">
												<button type="button" class="btn btn-primary btn-block px-3 mt-2" data-bs-toggle="modal" @click="editForm(li.id)" data-bs-target="#exampleLargeModal1">Edit</button>
												<button type="button" class="btn btn-danger btn-block px-3 mt-2 ms-2" data-bs-toggle="modal" @click="deleteInfo(li.id)" data-bs-target="#exampleVerticallycenteredModal">Delete</button>
											</div>
										</td>
									</tr>
								</tbody>
								<!-- <tfoot>
									<tr>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th>Country</th>
										<th>Company</th>
									</tr>
								</tfoot> -->
							</table>
						</div>
						<div class="row float-end" v-if="allDataList">
							<v-pagination
							v-model="page"
							:pages="total_page"
							:range-size="1"
							active-color="#037de2"
							@update:modelValue="updateHandler"
							:prevText="'Prev'"
							:nextText="'Next'"
							:active-class="myActiveBtn"
							:containerClass="'pagination'"
							class="pagination col-md-12"
							/>
						</div>
					</div>
				</div>
				<div class="card" v-else>
					<h3 class="text-info text-center">No Data Found</h3>
				</div>
			</div>
            <!-- Modal -->
            <!-- <div class="modal fade" id="exampleLargeModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-primary">
							<div class="card-body p-5">
								<div class="card-title d-flex align-items-center">
									<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
									</div>
									<h5 class="mb-0 text-primary">Leave Balance Creation</h5>
								</div>
								<hr>
								<form class="row g-3">
									<div class="col-md-4">
										<label for="inputState" class="form-label">Employee Code</label>
										<select v-model="employee" id="inputState" class="form-select single-select">
											<option v-for="(option, key) in employees" :key="key" :value="option">{{option.emp_code}}</option>
										</select>
									</div>
									<div class="col-md-4">
										<label for="inputState" class="form-label">Leave Policy</label>
										<select v-model="leave_policy" id="inputState" class="form-select single-select">
											<option v-for="(option, key) in policies" :key="key" :value="option">{{option.name}}</option>
										</select>
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Leave Total Days</label>
										<input type="text" v-model="leave_total_days" class="form-control" id="inputPhone" placeholder="Enter Leave Total Days">
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Leave Avail Days</label>
										<input type="text" v-model="leave_avail_days" class="form-control" id="inputEmail" placeholder="Enter Avail Day">
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Leave Balance</label>
										<input type="text" v-model="leave_balance" class="form-control" id="inputEmail" placeholder="Enter Leave Balance">
									</div>
								</form>
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="createLeaveBalance()" data-bs-dismiss="modal" class="btn btn-primary">Create Leave Balance</button>
                        </div>
                    </div>
                </div>
            </div> -->
			<!-- Update Modal -->
			<div class="modal fade" id="exampleLargeModal1" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!-- <h5 class="modal-title">Employee Creation</h5> -->
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
							<div class="card border-top border-0 border-4 border-warning">
							<div class="card-body p-5">
								<div class="card-title d-flex align-items-center">
									<div><i class="bx bxs-user me-1 font-22 text-primary"></i>
									</div>
									<h5 class="mb-0 text-primary">Update Leave Balance</h5>
								</div>
								<hr>
								<!-- <form class="row g-3">
									<div class="col-md-4">
										<label for="inputState" class="form-label">Employee Code</label>
										<select v-model="leave_balance_details.employee" id="inputState" class="form-select single-select">
											<option v-for="(option, key) in employees" :key="key" :value="option">{{option.emp_code}}</option>
										</select>
									</div>
									<div class="col-md-4">
										<label for="inputState" class="form-label">Leave Policy</label>
										<select v-model="leave_balance_details.leave_policy" id="inputState" class="form-select single-select">
											<option v-for="(option, key) in policies" :key="key" :value="option">{{option.name}}</option>
										</select>
									</div>
                                    <div class="col-md-6">
										<label for="inputPhone" class="form-label">Leave Total Days</label>
										<input type="text" v-model="leave_balance_details.leave_total_days" class="form-control" id="inputPhone" placeholder="Enter Leave Total Days">
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Leave Avail Days</label>
										<input type="text" v-model="leave_balance_details.leave_avail_days" class="form-control" id="inputEmail" placeholder="Enter Avail Day">
									</div>
									<div class="col-md-6">
										<label for="inputEmail" class="form-label">Leave Balance</label>
										<input type="text" v-model="leave_balance_details.leave_balance" class="form-control" id="inputEmail" placeholder="Enter Leave Balance">
									</div>
								</form> -->
							</div>
						</div>
						</div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" v-on:click="updateLeaveBalance()" data-bs-dismiss="modal" class="btn btn-primary">Update Leave Balance</button>
                        </div>
                    </div>
                </div>
            </div>
			<!-- Delete Modal -->
			<div class="modal fade" id="exampleVerticallycenteredModal" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header bg-primary">
							<h5 class="modal-title">Are You Sure?</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" v-on:click="deleteLeaveBalance()" data-bs-dismiss="modal" class="btn btn-danger">Delete</button>
						</div>
					</div>
				</div>
			</div>
</div>
        
</template>
<script src="../js/leave_approval_history.js"></script>