import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            allDataList: [],
            emp_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            total_pages: 5,
            search: '',
            id: null,
            selectedCertificateFiles: [],
            selectedVerificationFiles: [],
            certificates: [],
            verifications: [],
            roles: [],
            countries: [],
            salary_issue_mediums: [],
            salary_grades: [],
            designations: [],
            blood_groups: [],
            sections: [],
            genders: [],
            marital_statuses: [],
            departments: [],
            companies: [],
            cities: [],
            branches: [],
            city: {},
            branch: {},
            section: {},
            company: {},
            role: {},
            country: '',
            department: {},
            marital_status: '',
            gender: '',
            emp_code: '',
            designation: {},
            blood_group: '',
            date_of_joining: '',
            setURL: '',
            region: '',
            phone: null,
            selected_image: '',
            firstname: '',
            lastname: '',
            email: '',
            image: '',
            salary_issue_medium: {},
            salary_grade: {
                id: null,
                name: ''
            },
            stuck_off_date: '',
            selected_salary_grade: false,
            emp_details: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                emp_code: '',
                marital_status: {},
                company: {},
                country: {},
                city: {},
                blood_group: {},
                gender: {},
                branch: {},
                department: {},
                section: {},
                role: {},
                designation: {}
            },

        }
    },
    
    mounted() {
        this.setURL = this.$config.employees.gutBulkUploadedData;
        this.getEmployeeList();

        this.marital_statuses = [{
                id: 1,
                name: 'Married'
            },
            {
                id: 2,
                name: 'Unmarried'
            },
        ];
        this.genders = [{
                id: 0,
                name: "Male"
            },
            {
                id: 1,
                name: "Female"
            },
            {
                id: 2,
                name: "Other"
            }

        ]
        this.blood_groups = [{
                id: 1,
                name: "A+"
            },
            {
                id: 2,
                name: "A-"
            },
            {
                id: 1,
                name: "B+"
            },
            {
                id: 1,
                name: "B-"
            },
            {
                id: 1,
                name: "AB+"
            },
            {
                id: 1,
                name: "AB-"
            },
            {
                id: 1,
                name: "O+"
            },
            {
                id: 1,
                name: "O-"
            },
        ]
        this.salary_issue_mediums = [{
                id: 0,
                name: "Cash"
            },
            {
                id: 1,
                name: "Cheque"
            },

            {
                id: 2,
                name: "Bank Account"
            }
        ];
        let branch_url = this.$api + this.$config.configurations.branchAPIEndPoint;
        this.$axios.get(branch_url).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.branches.push({
                        id: response.data[key].id,
                        name: response.data[key].name
                    });
                }

            } else {
                this.isLoading = false;
            }
        }).catch(error => {
            //console.log(error);
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let city_url = this.$api + this.$config.configurations.cityAPIEndPoint;
        this.$axios.get(city_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.cities.push({
                        id: response.data[key].id,
                        name: response.data[key].city_name
                    });
                }

            } else {
                this.isLoading = false

            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let section_url = this.$api + this.$config.configurations.sectionAPIEndPoint;
        this.$axios.get(section_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.sections.push({
                        id: response.data[key].id,
                        name: response.data[key].section_name
                    });
                }

            } else {
                this.isLoading = false

            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let company_url = this.$api + this.$config.configurations.companyAPIEndPoint
        this.$axios.get(company_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.companies.push({
                        id: response.data[key].id,
                        name: response.data[key].company_name
                    });
                }

            } else {
                this.isLoading = false

            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let role_url = this.$api + this.$config.role.roleAPIEndPoint;
        this.$axios.get(role_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.roles.push({
                        id: response.data[key].id,
                        name: response.data[key].title
                    });
                }

            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });

        let department_url = this.$api + this.$config.configurations.departmentAPIEndPoint;
        this.$axios.get(department_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.departments.push({
                        id: response.data[key].id,
                        name: response.data[key].title
                    });
                }

            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let country_url = this.$api + this.$config.configurations.countryAPIEndPoint;
        this.$axios.get(country_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.countries.push({
                        id: response.data[key].id,
                        name: response.data[key].country_name
                    });
                }

            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });
        let gender_url = this.$api + this.$config.configurations.genderAPIEndPoint
        this.$axios.get(gender_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    if (response.data[key].title == 0) {
                        this.genders.push({
                            id: response.data[key].id,
                            name: "Male"
                        });
                    } else if (response.data[key].title == 1) {
                        this.genders.push({
                            id: response.data[key].id,
                            name: "Female"
                        });
                    } else if (response.data[key].title == 2) {
                        this.genders.push({
                            id: response.data[key].id,
                            name: "Other"
                        });
                    }
                }

            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });


        let blood_group_url = this.$api + this.$config.configurations.bloodGroupAPIEndPoint;
        this.$axios.get(blood_group_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.blood_groups.push({
                        id: response.data[key].id,
                        name: response.data[key].title
                    });
                }

            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);

        });
        let designation_url = this.$api + this.$config.configurations.designationAPIEndPoint;
        this.$axios.get(designation_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.designations.push({
                        id: response.data[key].id,
                        name: response.data[key].title
                    });
                }

            } else {
                this.isLoading = false

            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);

        });
        let salary_grade_url = this.$api + this.$config.payroll.salaryGradeAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
        this.$axios.get(salary_grade_url, {}).then((response) => {
            if (response.status == 200) {
                this.salary_grades = response.data.data;
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false
            this.$toast.error(error.response.data.message);
        });


    },
    created() {
        document.title = "HrConnect - Bulk Employee";
    },
    watch: {
        salary_grade: function() {
            this.selected_salary_grade = true;
        }
    },

    methods: {
        deleteInfo: function(id) {
            this.emp_id = id;
        },

        getEmployeeList: async function() {
            // let params = "?page=" + this.page + "&limit=" + this.limit;
            // if(this.search) {
            //     params += "&search=" + this.search;
            // }
            let emp_url = this.$api + this.$config.employees.gutBulkUploadedData;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url).then((response) => {
                if (response.status == 200) {
                    // //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.total_pages = response.data.last_page;
                    //console.log("All data list: ", this.dataList);
                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);

            });

        },
        searchData: function() {
            this.updateHandler();
        },
        deleteEmployee: async function() {
            //console.log("Employee Details is: ", this.emp_details);
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            await this.$axios.delete(emp_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                   await this.getEmployeeList();
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error);
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateEmployee: async function() {
            //console.log("Employee Details is: ", this.emp_details);
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            const emp_data = new FormData();
            emp_data.append("emp_code", this.emp_details.emp_code);
            emp_data.append("company_id", this.emp_details.company.id);
            emp_data.append("company_name", this.emp_details.company.name);
            emp_data.append("first_name", this.emp_details.first_name);
            emp_data.append("last_name", this.emp_details.last_name);
            emp_data.append("country_id", this.emp_details.country.id);
            emp_data.append("country_name", this.emp_details.country.name);
            emp_data.append("city_id", this.emp_details.city.id);
            emp_data.append("city_name", this.emp_details.city.name);
            emp_data.append("blood_group_id", this.emp_details.blood_group.id);
            emp_data.append("blood_group_title", this.emp_details.blood_group.name);
            emp_data.append("marital_status", this.emp_details.marital_status.name);
            emp_data.append("gender_id", this.emp_details.gender.id);
            emp_data.append("gender_title", this.emp_details.gender.name);
            emp_data.append("email", this.emp_details.email);
            emp_data.append("phone", this.emp_details.phone);
            emp_data.append("branch_id", this.emp_details.branch.id);
            emp_data.append("branch_name", this.emp_details.branch.name);
            emp_data.append("department_id", this.emp_details.department.id);
            emp_data.append("department_name", this.emp_details.department.name);
            emp_data.append("section_id", this.emp_details.section.id);
            emp_data.append("section_name", this.emp_details.section.name);
            emp_data.append("role_id", this.emp_details.role.id);
            emp_data.append("role_name", this.emp_details.role.name);
            emp_data.append("designation_id", this.emp_details.designation.id);
            emp_data.append("designation_name", this.emp_details.designation.name);
            emp_data.append("date_of_joining", this.emp_details.date_of_joining);
            emp_data.append("image", this.emp_details.image);

            let config = {
                method: "PUT",
                url: emp_details_url,
                data: emp_data,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    this.getEmployeeList();
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getEmployeeData: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        downloadFile: async function(url) {
            //console.log("Url is: ", this.$backend + url);
            let new_url = this.$backend + url;
            let new_label = url.split('/')[5].split(".")[0];
            // let data_type = url.split('/')[5].split(".")[1];
            //console.log("data type: ", data_type);
            await this.$axios.get(new_url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        editForm: async function(id, emp_code) {
            this.emp_id = id;
            let emp_details_url = this.$api + this.$config.employees.employeeDetails + this.emp_id;
            //console.log(emp_details_url);
            await this.$axios.get(emp_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.emp_details.first_name = response.data.first_name;
                    this.emp_details.last_name = response.data.last_name;
                    this.emp_details.email = response.data.email;
                    this.emp_details.phone = response.data.phone;
                    this.emp_details.emp_code = response.data.emp_code;
                    this.emp_details.date_of_joining = response.data.date_of_joining;
                    this.emp_details.image = this.$backend + response.data.image;
                    //console.log("Selected Image: ", this.selected_image);
                    if (response.data.marital_status == "Married") {
                        this.emp_details.marital_status = {
                            id: 1,
                            name: "Married"
                        }
                    } else if (response.data.marital_status == "Unmarried") {
                        this.emp_details.marital_status = {
                            id: 2,
                            name: "Unmarried"
                        }
                    }
                    this.emp_details.country = {
                        id: response.data.country_id,
                        name: response.data.country_name
                    }
                    this.emp_details.company = {
                        id: response.data.company_id,
                        name: response.data.company_name
                    }
                    this.emp_details.city = {
                        id: response.data.city_id,
                        name: response.data.city_name
                    }
                    this.emp_details.blood_group = {
                        id: response.data.blood_group_id,
                        name: response.data.blood_group_title
                    }
                    this.emp_details.gender = {
                        id: response.data.gender_id,
                        name: response.data.gender_title
                    }
                    this.emp_details.branch = {
                        id: response.data.branch_id,
                        name: response.data.branch_name
                    }
                    this.emp_details.department = {
                        id: response.data.department_id,
                        name: response.data.department_name
                    }
                    this.emp_details.section = {
                        id: response.data.section_id,
                        name: response.data.section_name
                    }
                    this.emp_details.role = {
                        id: response.data.role_id,
                        name: response.data.role_name
                    }
                    this.emp_details.designation = {
                        id: response.data.designation_id,
                        name: response.data.designation_name
                    }
                } else {
                    //console.log("In else");
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                if(error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            });
            let certificate_url = this.$api + this.$config.configurations.certificateList + "?emp_code=" + emp_code;
            //console.log(certificate_url)
            await this.$axios.get(certificate_url).then((response) => {
                if (response.status == 200) {
                    this.certificates = [];
                    for (const key in Object.keys(response.data)) {
                        this.certificates.push({
                            id: response.data[key].id,
                            emp_code: response.data[key].emp_id,
                            emp_name: response.data[key].emp_name,
                            file: response.data[key].file,
                            filename: response.data[key].file.split('/')[5]
                        });
                    }
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            //console.log("Certificates are: ", this.certificates);

        },
        uploadCertificates: function(event) {
            this.selectedCertificateFiles = event.target.files;
            //console.log("Selected Files: ", this.selectedCertificateFiles);
        },
        uploadVerifications: function(event) {
            this.selectedVerificationFiles = event.target.files;
            //console.log("Selected Files: ", this.selectedVerificationFiles);
        },
        uploadImage: function(event) {
            this.selected_image = event.target.files[0];
            //console.log("Selected Files: ", this.selected_image);
        },
        updateImage: function(event) {
            this.emp_details.image = event.target.files[0];
            //console.log("Selected Files: ", this.emp_details.image);
        },
        closeAddEmployee(){
            this.emp_code = ''
            this.company = ''
            this.firstname = ''
            this.lastname = ''
            this.country = ''
            this.city = ''
            this.blood_group = ''
            this.gender = ''
            this.email = ''
            this.phone = ''
            this.branch = ''
            this.department = ''
            this.section = ''
            this.role = ''
            this.designation = ''
            this.date_of_joining = ''
            this.selected_image = ''
            this.employee_name = ''
            this.this.salary_grade = {
                id: null,
                name: ''
            }
            this.this.salary_issue_medium = ''
            if(this.selectedCertificateFiles){
                console.log("asdasd");
                this.$refs.uploadFileCertificates.reset();
            }
            if(this.selectedVerificationFiles){
                console.log("asdasd");
                this.$refs.uploadFileVerifications.reset();
            }
            if(this.selected_image){
                console.log("asdasd");
                this.$refs.uploadEmpImg.reset();
            }
        },
        createEmployee: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.employees.employeeAPIEndPoint;
            const emp_data = new FormData();
            emp_data.append("emp_code", this.emp_code);
            emp_data.append("company_id", this.company.id);
            emp_data.append("company_name", this.company.name);
            emp_data.append("first_name", this.firstname);
            emp_data.append("last_name", this.lastname);
            emp_data.append("country_id", this.country.id);
            emp_data.append("country_name", this.country.name);
            emp_data.append("city_id", this.city.id);
            emp_data.append("city_name", this.city.name);
            emp_data.append("blood_group_id", this.blood_group.id);
            emp_data.append("blood_group_title", this.blood_group.name);
            emp_data.append("marital_status", this.marital_status.name);
            emp_data.append("gender_id", this.gender.id);
            emp_data.append("gender_title", this.gender.name);
            emp_data.append("email", this.email);
            emp_data.append("phone", this.phone);
            emp_data.append("branch_id", this.branch.id);
            emp_data.append("branch_name", this.branch.name);
            emp_data.append("department_id", this.department.id);
            emp_data.append("department_name", this.department.name);
            emp_data.append("section_id", this.section.id);
            emp_data.append("section_name", this.section.name);
            emp_data.append("role_id", this.role.id);
            emp_data.append("role_name", this.role.name);
            emp_data.append("designation_id", this.designation.id);
            emp_data.append("designation_name", this.designation.name);
            emp_data.append("date_of_joining", this.date_of_joining);
            emp_data.append("is_stuck_off", false);
            emp_data.append("image", this.selected_image);
            let employee_name = this.firstname + " " + this.lastname;
            emp_data.append("emp_name", employee_name);

            for (let i = 0; i < this.selectedCertificateFiles.length; i++) {
                emp_data.append("certificate_file", this.selectedCertificateFiles[i]);
            }
            for (let i = 0; i < this.selectedVerificationFiles.length; i++) {
                emp_data.append("verification_file", this.selectedVerificationFiles[i]);
            }
            emp_data.append("salary_grade_id", this.salary_grade.id);
            emp_data.append("salary_code", this.salary_grade.salary_code);
            emp_data.append("salary_issue_medium", this.salary_issue_medium.id);

            let emp_config = {
                method: "POST",
                data: emp_data,
                url: url,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            await this.postDataToBackend(emp_config);
            await this.getEmployeeList();
            this.city = {};
            this.branch = {};
            this.section = {};
            this.company = {};
            this.role = {};
            this.country = '';
            this.department = {};
            this.marital_status = '';
            this.gender = '';
            this.emp_code = '';
            this.designation = {};
            this.blood_group = '';
            this.date_of_joining = '';
            this.setURL = '';
            this.region = '';
            this.phone = null;
            this.selected_image = '';
            this.firstname = '';
            this.lastname = '';
            this.email = '';
            this.image = '';
            this.salary_issue_medium = {};
            this.salary_grade = {
                id: null,
                name: ''
            };
            this.selected_salary_grade = false;
            this.emp_details = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                emp_code: '',
                marital_status: {},
                company: {},
                country: {},
                city: {},
                blood_group: {},
                gender: {},
                branch: {},
                department: {},
                section: {},
                role: {},
                designation: {}
            }

        },
        createStuckOff: async function() {
            let url = this.$api + this.$config.employees.stuckOffEndPoint;
            let data = {
                emp_code: this.emp_details.emp_code,
                stuck_off_date: this.stuck_off_date
            };
            let config = {
                url: url,
                method: "POST",
                data: data
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$toast.success(response.data.message);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            await this.getEmployeeList();
        },
        updateHandler: async function() {
            await this.getEmployeeList();
        },
        bulkUpload: async function() {
            let formData = new FormData();
            formData.append('file', this.selectedFile);
            let config = {
                method: "POST",
                url: this.$api + this.$config.employees.bulkUpload,
                data: formData
            }
            // await this.postDataToBackend(config);
            await this.$axios(config).then((response) => {
                this.isLoading = false
                if (response.status == 201) {
                    this.$toast.success(response.data.message);
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                let err = error.response.data.errors
                if(err){
                    err.forEach((item, index) => {
                        let entries = Object.entries(item, index)
                        entries.map( ([key, val]) => {
                            this.$toast.error(`${key}: ${val}`);
                        });
                    });
                    
                } else {
                    this.$toast.error(error.response.data.message);
                }
                this.isLoading = false
            });
        },
        chooseFile: async function() {
          document.getElementById("fileUpload").click();
        },
        uploadFile: async function(event) {
          this.selectedFile = event.target.files[0];
          console.log("event.target.files",event.target.files);
          await this.bulkUpload();
        }
    }
}