import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            role_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            role_name: '',
            role_details: {

            }
        }
    },
    mounted() {
        this.setURL = this.$config.role.roleAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let role_url = this.$api + this.$config.role.roleAPIEndPoint + params;
        this.getDataList(role_url);

    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        deleteInfo: function(id) {
            this.role_id = id;
        },
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let role_url = this.$api + this.$config.role.roleAPIEndPoint + params;
            await this.getDataList(role_url);
        },
        deleteRole: async function() {
            this.isLoading = true
            let role_details_url = this.$api + this.$config.role.roleDetails + this.role_id;
            await this.$axios.delete(role_details_url, {

            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let role_url = this.$api + this.$config.role.roleAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(role_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateRole: async function() {
            this.isLoading = true
            //console.log("employee Details is: ", this.role_details);
            let role_details_url = this.$api + this.$config.role.roleDetails + this.role_id;
            this.$axios.put(role_details_url, {
                title: this.role_details.role_name

            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let role_url = this.$api + this.$config.role.roleAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await  this.getDataList(role_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        getRoleData: async function(url) {
            this.isLoading = true
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                this.isLoading = false
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                    this.$swal.fire({
                        icon: "error",
                        text: "No Data Found!"
                    });
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.role_id = id;
            let role_details_url = this.$api + this.$config.role.roleDetails + this.role_id;
            //console.log(role_details_url);
            await this.$axios.get(role_details_url, {}).then((response) => {
                if (response.status == 200) {
                    this.role_details.role_name = response.data.title;

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        createRole: async function() {
            this.isLoading = true
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.role.roleAPIEndPoint
            await this.$axios.post(url, {
                title: this.role_name,
            }).then(async(response) => {
                this.isLoading = false
                if (response.status == 201) {
                    //console.log("Role Created Successfully");
                    this.$toast.success(response.data.message);
                    let role_url = this.$api + this.$config.role.roleAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(role_url)
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
            // this.$router.go();
        },

    }
}