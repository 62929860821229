import Loading from "vue-loading-overlay";
import { VueEditor } from "vue3-editor";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import Pagination from "@/components/pagination/Pagination";

export default {
    components: {
        Loading,
        VueEditor,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            mail_format_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            template_title: '',
            subject: '',
            mail_body: '',
            status: false,
            mail_details: {}
        }
    },
    mounted() {
        this.setURL = this.$config.leave.mailFormatAPIEndPoint;
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let leave_mail_url = this.$api + this.$config.leave.mailFormatAPIEndPoint + params;
        this.getDataList(leave_mail_url);
    },
    created() {
        document.title = "HrConnect - Employee";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let leave_mail_url = this.$api + this.$config.leave.mailFormatAPIEndPoint + params;
            await this.getDataList(leave_mail_url);
        },
        deleteInfo: function(id) {
            this.mail_format_id = id;
        },
        deleteLeaveMailFormat: async function() {
            let mail_details_url = this.$api + this.$config.leave.mailFormatDetails + this.mail_format_id;
            await this.$axios.delete(mail_details_url, {

            }).then((response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    this.$router.go();
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        updateLeaveMailFormat: async function() {
            //console.log("cmployee Details is: ", this.mailFormatDetails);
            let cmp_details_url = this.$api + this.$config.leave.mailFormatDetails + this.mail_format_id;
           await this.$axios.put(cmp_details_url, {
                template_title: this.mail_details.template_title,
                subject: this.mail_details.subject,
                mail_body: this.mail_details.mail_body,
                status: this.mail_details.status

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let leave_mail_url = this.$api + this.$config.leave.mailFormatAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(leave_mail_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        getLeaveMailFormat: async function(url) {
            let emp_url = this.$api + url;
            //console.log("Employee Url is: ", emp_url)
            await this.$axios.get(emp_url, {}).then((response) => {
                if (response.status == 200) {
                    //console.log("Data is", response.data);
                    this.dataList = response.data.data;
                    this.allDataList = response.data;
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });

        },
        editForm: async function(id) {
            this.mail_format_id = id;
            let mail_details_url = this.$api + this.$config.leave.mailFormatDetails + this.mail_format_id;
            //console.log(mail_details_url);
            await this.$axios.get(mail_details_url, {}).then((response) => {
                if (response.status == 200) {

                    this.mail_details.template_title = response.data.template_title
                    this.mail_details.subject = response.data.subject
                    this.mail_details.mail_body = response.data.mail_body
                    this.mail_details.status = response.data.status

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createLeaveMailFormat: async function() {
            //console.log("Employee Creation Method");
            let url = this.$api + this.$config.leave.mailFormatAPIEndPoint
           await this.$axios.post(url, {
                template_title: this.template_title,
                subject: this.subject,
                mail_body: this.mail_body,
                status: this.status

            }).then(async (response) => {
                if (response.status == 201) {
                    //console.log("Leave Mail Format Created Successfully");
                    this.$toast.success(response.data.message);
                    let leave_mail_url = this.$api + this.$config.leave.mailFormatAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(leave_mail_url);
                    this.template_title = ''
                    this.subject = ''
                    this.mail_body = ''
                    this.status = ''
                } else {
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log("Error is ", error.response)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

    }
}