import Loading from "vue-loading-overlay";
// import Pagination from "@/components/pagination/Pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    components: {
        Loading,
        VPagination
        // Pagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            dataList: [],
            account_id: null,
            page: 1,
            limit: 10,
            pageLimit: [10, 20, 50, 100],
            search: '',
            id: null,
            employees: [],
            account_types: [],
            bank_name: '',
            branch_name: '',
            employee: {},
            account_type: '',
            account_number: '',
            routing_number: '',
            swift_code: '',
            account_details: {
                employee: {
                    emp_id: null,
                    emp_code: null
                },
                account_type: {
                    id: null,
                    name: null
                }
            }

        }
    },
    mounted() {
        let params = "?page=" + this.page + "&limit=" + this.limit;
        if (this.search) {
            params += "&search=" + this.search;
        }
        let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + params;
        this.getDataList(account_details_url);
        let emp_url = this.$api + this.$config.employees.employeeAPIEndPoint;
        this.$axios.get(emp_url, {}).then((response) => {
            if (response.status == 200) {
                for (const key in Object.keys(response.data)) {
                    this.employees.push({
                        emp_id: response.data[key].id,
                        emp_code: response.data[key].emp_code,
                        emp_name: response.data[key].first_name + " " + response.data[key].last_name
                    });
                }
            } else {
                this.isLoading = false
            }
        }).catch(error => {
            //console.log(error)
            this.isLoading = false;
            this.$toast.error(error.response.data.message);
        });
        this.account_types = [{
                id: 0,
                name: "Savings"
            },
            {
                id: 1,
                name: "Salary"
            }
        ];

    },
    created() {
        document.title = "HrConnect - Payroll";
    },
    methods: {
        searchData: function() {
            this.updateHandler();
        },
        updateHandler: async function () {
            let params = "?page=" + this.page + "&limit=" + this.limit;
            if (this.search) {
                params += "&search=" + this.search;
            }
            let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + params;
           await this.getDataList(account_details_url);
        },
        deleteInfo: function(id) {
            this.account_id = id;
        },
        deleteAccountDetails: async function() {
            let account_details_url = this.$api + this.$config.payroll.accountDetails + this.account_id;
            await this.$axios.delete(account_details_url, {

            }).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Delete Successfully");
                    this.$toast.success(response.data.message);
                    let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                    await this.getDataList(account_details_url);
                } else {
                    //console.log("In else");
                    this.isLoading = false

                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },
        updateAccountDetails: async function() {
            // //console.log("cmployee Details is: ", this.$config.leave.AccountDetails);
            let account_details_url = this.$api + this.$config.payroll.accountDetails + this.account_id;
            let data = {
                emp_id: this.account_details.employee.emp_id,
                emp_code: this.account_details.employee.emp_code,
                emp_name: this.account_details.employee.emp_name,
                bank_name: this.account_details.bank_name,
                account_type: this.account_details.account_type.id,
                account_number: this.account_details.account_number,
                routing_number: this.account_details.routing_number,
                branch_name: this.account_details.branch_name,
                swift_code: this.account_details.swift_code,
            }
            let config = {
                url: account_details_url,
                method: "PUT",
                data: data
            };
           await this.$axios(config).then(async(response) => {
                if (response.status == 200) {
                    //console.log("Update Successfully");
                    this.$toast.success(response.data.message);
                    let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
                   await this.getDataList(account_details_url)
                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });
        },

        editForm: async function(id) {
            this.account_id = id;
            let account_details_url = this.$api + this.$config.payroll.accountDetails + this.account_id;
            let config = {
                method: "GET",
                url: account_details_url
            }
           await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.account_details.employee = {
                        emp_id: response.data.emp_id,
                        emp_code: response.data.emp_code,
                        emp_name: response.data.emp_name
                    };
                    this.account_details.bank_name = response.data.bank_name;
                    this.account_details.account_number = response.data.account_number;
                    this.account_details.routing_number = response.data.routing_number;
                    this.account_details.branch_name = response.data.branch_name;
                    this.account_details.swift_code = response.data.swift_code;
                    if (response.data.account_type == 0) {
                        this.account_details.account_type = {
                            id: 0,
                            name: "Savings"
                        }

                    } else if (response.data.account_type == 1) {
                        this.account_details.account_type = {
                            id: 1,
                            name: "Salary"
                        }

                    }

                } else {
                    //console.log("In else");
                    this.isLoading = false
                }
            }).catch(error => {
                //console.log(error)
                this.isLoading = false
                this.$toast.error(error.response.data.message);
            });


        },
        createAccountDetails: async function() {
            let url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint
            let data = {
                emp_id: this.employee.emp_id,
                emp_code: this.employee.emp_code,
                emp_name: this.employee.emp_name,
                bank_name: this.bank_name,
                account_type: this.account_type.id,
                account_number: this.account_number,
                routing_number: this.routing_number,
                branch_name: this.branch_name,
                swift_code: this.swift_code,
            };
            let config = {
                url: url,
                method: "POST",
                data: data
            }
            await this.postDataToBackend(config);
            this.bank_name = '';
            this.branch_name = '';
            this.employee = {};
            this.account_type = '';
            this.account_number = '';
            this.routing_number = '';
            this.swift_code = '';
            let account_details_url = this.$api + this.$config.payroll.accountDetailsAPIEndPoint + "?page=" + this.page + "&limit=" + this.limit + "&search=" + this.search;
            await this.getDataList(account_details_url);
        },

    }
}